package net.gorillagroove.track

import io.ktor.http.*
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.Instant
import kotlinx.datetime.plus
import kotlinx.serialization.Serializable
import net.gorillagroove.api.*
import net.gorillagroove.authentication.AuthService
import net.gorillagroove.db.*
import net.gorillagroove.db.Database.playlistTrackDao
import net.gorillagroove.db.Database.trackDao
import net.gorillagroove.hardware.PlatformDeviceUtil
import net.gorillagroove.playlist.PlaylistService
import net.gorillagroove.sync.OfflineAvailabilityType
import net.gorillagroove.user.UserService
import net.gorillagroove.util.*
import net.gorillagroove.util.GGLog.logCrit
import net.gorillagroove.util.GGLog.logDebug
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.GGLog.logInfo
import net.gorillagroove.util.GGLog.logWarn
import net.gorillagroove.util.Lock
import net.gorillagroove.util.TimeUtil.now
import net.gorillagroove.util.use

typealias TrackChangeHandler = (TrackChangeEvent) -> Unit

@Suppress("VARIABLE_IN_SINGLETON_WITHOUT_THREAD_LOCAL")
object TrackService {
    fun findById(id: TrackId): Track? = findDbById(id)?.toTrack()

    fun findByIds(ids: List<TrackId>): List<Track> = trackDao.findByIds(ids).many().toTracks()

    internal fun findDbById(id: TrackId): DbTrack? = trackDao.findById(id).oneOrNull()

    @Throws(Throwable::class)
    suspend fun findByApiId(id: TrackApiId): Track? {
        val localTrack = trackDao.findByApiId(id).oneOrNull()

        if (localTrack != null) {
            return localTrack.toTrack()
        }

        // I don't normally swallow exceptions in this library, but I think for this particular
        // function, having it return null when it fails to find something makes it consistent
        // with other "findBy" functions. It's just dependent on internet which they aren't.
        return try {
            UserTrackService.getTrack(id)
        } catch (e: Exception) {
            logError("Failed to fetch Track from API with ID ${id.value}!", e)
            null
        }
    }

    @Throws(Throwable::class)
    suspend fun searchBy(
        userId: UserId = UserService.requireCurrentUserId(),
        inReview: Boolean = false,
        isHidden: Boolean? = null,
        // artistFilter is a "contains", because we want to find all tracks by an artist, even if a track has multiple.
        artistFilter: String? = null,
        // albumFilter is an exact (case-insensitive) match, as it does not have the same limitation as artist.
        albumFilter: String? = null,
        // Searches by any reasonable option with a LIKE (name, artist, note, etc)
        genericFilter: String? = null,
        // Removes tracks from the search that belong to a set playlist.
        // It is not valid to provide this for users other than your own.
        excludedPlaylistId: PlaylistId? = null,
        offlineOnly: Boolean = OfflineModeService.offlineModeEnabled,
        sort: List<TrackSortItem> = listOf(TrackSortItem(TrackColumn.NAME, SortDirection.ASC)),
    ): List<Track> {
        // There are a couple of sorts that require additional sorts
        // be added to them implicitly so that they make more sense.
        val sortToUse = sort.augmentSort()

        val tracks = if (userId == UserService.requireCurrentUserId()) {
            val excludedTrackIds = if (excludedPlaylistId != null) {
                PlaylistService.getTracksForPlaylist(excludedPlaylistId).map { it.playlistTrack.trackId }
            } else emptyList()

            trackDao.findBy(
                userId = userId,
                inReview = inReview,
                isHidden = isHidden,
                artist = artistFilter,
                album = albumFilter,
                genericFilter = genericFilter.takeIf { it?.isNotBlank() == true },
                excludedTrackIds = excludedTrackIds,
                offlineOnly = offlineOnly.toLong(),
            ).many().toTracks()
        } else {
            // This is TECHNICALLY something I could support, but you can't see another user's playlists.
            // So you'd have to specifically be looking to see what tracks in someone else's library
            // are not on a shared playlist that you are on. This is so niche that I doubt anybody
            // will ever actually want to do this. If I'm wrong, I'll implement it later....
            if (excludedPlaylistId != null) {
                throw IllegalArgumentException("Playlist exclusion can only happen for your own user!")
            }

            // We fetch ALL tracks from the API, then apply our own filtering criteria for two reasons:
            // 1) Doing everything client-side keeps the logic consistent within the library regardless of
            //    whether you are searching your own stuff or someone else's stuff.
            // 2) We cache this track data to make it more responsive when you change search or filter criteria.
            //    This means that we do not need to keep hitting the API and cause a further delay.
            UserTrackService.getAllTracksForUser(userId).filter { track ->
                track.inReview == inReview
                        && (isHidden == null || track.isHidden == isHidden)
                        && (
                            artistFilter == null ||
                                    (artistFilter == "" && track.artist == "" && track.featuring == "") ||
                                    (artistFilter != "" && (track.artist.contains(artistFilter, ignoreCase = true) || track.featuring.contains(artistFilter, ignoreCase = true)))
                        )
                        && (
                            albumFilter == null ||
                                    (albumFilter == "" && track.album == "") ||
                                    (albumFilter != "" && track.album.equals(albumFilter, ignoreCase = true))
                        )
                        && (
                        genericFilter == null ||
                                (track.name.contains(genericFilter, ignoreCase = true)) ||
                                (track.artist.contains(genericFilter, ignoreCase = true)) ||
                                (track.featuring.contains(genericFilter, ignoreCase = true)) ||
                                (track.album.contains(genericFilter, ignoreCase = true)) ||
                                (track.genre.contains(genericFilter, ignoreCase = true)) ||
                                (track.note.contains(genericFilter, ignoreCase = true))
                        )
            }
        }

        return tracks
            .filter { it.matchesArtistFilter(artistFilter) }
            .let { TrackSort.sortTracks(it, sortToUse) }
    }

    private fun Track.matchesArtistFilter(filter: String?): Boolean {
        // These are already handled by the SQL query. Don't need to do anything else
        if (filter == null || filter == "") {
            return true
        }

        // The SQL query only does a %artist% search, which is too generous. It can cause things like
        // "Air" to match "Claire", which is not what we want. We want to do more or less whole-word matches.
        // Doing this in SQLite is a pain in the ass, so this final check is done in code. If we have an
        // artistFilter, then we should have a pretty trivially small number of songs. At most like a hundred.
        // So this bit of inefficiency is ok to do the filter in both places, imo anyway.
        // Because GG supports an artist such as 'Gareth Emery, Avicii' being found by searching for just 'Avicii',
        // we need to see a comma as a valid terminal separator as well as a valid starting separator. If we
        // treat spaces as valid terminal characters, then searching for 'Emery' would match 'Gareth Emery',
        // which we would like to avoid. I make an exception for the sequence ' x ' because a lot of EDM stuff
        // will have artists like 'Man Cub x Daye' for some reason. Just to be difficult. As well as an exception
        // for ' & ', as a less common form of delimiter than a comma.
        fun fieldMatches(field: String): Boolean {
            val startIndex = field.findIndex(filter, ignoreCase = true) ?: return false

            val endIndex = startIndex + filter.length

            val previousCharacter = if (startIndex == 0) null else field[startIndex - 1]
            val previousWord = if (startIndex == 0) null else field.substring(0, startIndex)
                .trim()
                .split(" ")
                .last()
                .trim()

            val startMatches = previousWord == null ||
                    previousWord.last() == ',' ||
                    (previousCharacter == ' ' && previousWord.equals("x", ignoreCase = true)) ||
                    (previousCharacter == ' ' && previousWord == "&")

            if (!startMatches) {
                return false
            }

            val nextChar = if (endIndex == field.length) null else field[endIndex]
            val nextWord = if (endIndex == field.length) null else field.subSequence(endIndex, field.length)
                .trim()
                .split(" ")
                .first()
                .trim()

            return nextWord == null ||
                    nextWord.first() == ',' ||
                    (nextChar == ' ' && nextWord.equals("x", ignoreCase = true)) ||
                    (nextChar == ' ' && nextWord == "&")
        }

        return fieldMatches(this.artist) || fieldMatches(this.featuring)
    }

    @Throws(Throwable::class)
    suspend fun getDistinctArtists(
        userId: UserId = UserService.requireCurrentUserId(),
        searchFilter: String? = null,
        isHidden: Boolean? = null,
        offlineOnly: Boolean = OfflineModeService.offlineModeEnabled,
    ): List<String> {
        return if (userId == UserService.requireCurrentUserId()) {
            trackDao.getDistinctArtists(userId, offlineOnly.toLong(), isHidden, searchFilter).many()
        } else {
            val tracks = UserTrackService.getAllTracksForUser(userId)
                .filter { isHidden == null || it.isHidden == isHidden }

            // If an actual "Artist" is missing, we want to return an empty string.
            // If a "Featuring" is missing, we do not (as most Tracks do not have one)
            val featuredArtists = tracks.mapNotNull { it.featuring.takeIfNotBlank() }.toSet()

            val uniqueArtists = tracks.map { it.artist }.toSet() + featuredArtists

            uniqueArtists
                .filter { searchFilter == null || it.contains(searchFilter, ignoreCase = true) }
                .sortedWith(compareBy(String.CASE_INSENSITIVE_ORDER) { it })
        }
    }

    @Throws(Throwable::class)
    suspend fun getDistinctAlbums(
        userId: UserId = UserService.requireCurrentUserId(),
        artistFilter: String? = null,
        searchFilter: String? = null,
        isHidden: Boolean? = null,
        offlineOnly: Boolean = OfflineModeService.offlineModeEnabled,
    ): List<Album> {
        return if (userId == UserService.requireCurrentUserId()) {
            // This is not the prettiest way to do this, in theory. But using SQLDelight makes it difficult.
            val uniques = trackDao.getDistinctAlbums(userId, isHidden, offlineOnly.toLong(), artistFilter, searchFilter)
                .many()
                .map { ProtoAlbum(it.album, TrackId(it.min!!)) }

            val tracks = findByIds(uniques.map { it.trackId }).associateBy { it.id }
            uniques.map { Album(it.name, tracks.getValue(it.trackId)) }
        } else {
            // Something tells me that I could do this a lot better. But the ol' brain ain't the best today, NGL.
            val tracks = UserTrackService.getAllTracksForUser(userId)
            val albumToTrack = mutableMapOf<String, Track>()

            tracks
                .filter { isHidden == null || it.isHidden == isHidden }
                .filter { track ->
                    artistFilter == null ||
                            (artistFilter.isEmpty() && track.artist.isEmpty() && track.featuring.isEmpty()) ||
                            (artistFilter.isNotEmpty() && (track.artist.contains(artistFilter) || track.featuring.contains(artistFilter)))
                }
                .forEach { track ->
                    albumToTrack[track.album] = albumToTrack[track.album] ?: track
                }

            albumToTrack
                .filter { (album, _) -> searchFilter == null || album.contains(searchFilter, ignoreCase = true) }
                .map { Album(it.key, it.value) }
                .sortedWith(compareBy(String.CASE_INSENSITIVE_ORDER) { it.name })
        }
    }

    @Throws(Throwable::class)
    suspend fun deleteTrack(tracks: List<Track>) {
        logInfo("Deleting tracks: ${tracks.map { it.id }}")

        val apiIds = tracks.mapNotNull { it.apiId }
        val allIds = tracks.map { it.id }

        val params = mapOf("trackIds" to apiIds.map { it.value })
        try {
            Api.delete<Unit>("track", params)
        } catch (e: Exception) {
            logError("Failed to delete track remotely!", e)

            // This really shouldn't happen under normal circumstances. But if you try to delete something that
            // is already deleted on the server, then you get back a 400 and the music will just stay on-device
            // forever with no way to remove it. I don't like that.
            // Again, should never happen, but if I ever intervene in weird ways in the database like I sometimes do
            // mostly for my own user, then it can happen and did happen to me one time.
            if (e is BadRequestException && e.response.status == 400) {
                logInfo("Failed track delete was from a 400. Deleting locally")
            } else {
                throw e
            }
        }

        logInfo("Deleting local track state")

        playlistTrackDao.deleteByTracks(allIds)
        trackDao.deleteByIds(allIds)

        // I don't know exactly how this should work with local-only tracks.
        // Do they ever have a cache? I'm guessing no, so this would never do anything for them.
        allIds.forEach { TrackCacheService.deleteAllCacheOnDisk(it) }

        broadcastTrackChange(tracks, ChangeType.DELETED)
    }

    fun getTrackCount(
        offlineAvailabilityType: OfflineAvailabilityType?,
        isCached: Boolean?
    ): Int {
        val isCachedLong = isCached?.let { if (it) 1 else 0 }?.toLong()
        return trackDao.getTrackCount(offlineAvailabilityType?.toRawType(), isCachedLong).one().toInt()
    }

    // I can't currently think of a reason for a client to force-request live links. So internal it goes.
    internal suspend fun getTrackLinksLive(
        trackId: TrackApiId,
        linkTypes: Set<TrackLinkType> = TrackLinkType.standardLinkTypes
    ): TrackLinkResponse {
        return Api.get("file/link/${trackId.value}", mapOf(
            "trackLinkTypes" to linkTypes,
        ))
    }

    // Returns a string that is a URL to a track. e.g. /track-link/$trackId?anonymousAccessToken=<uuid>"
    @Throws(Throwable::class)
    suspend fun forceLinkRegeneration(trackId: TrackApiId): String {
        return Api.post<TrackLinkRegenerationResponse>("file/link/regenerate/${trackId.value}").pageUrl
    }

    /**
     * Returns the ByteArray of the requested track link if it is cached, the link to the resource
     * if it is not cached but exists, or null otherwise (such as when no album art exists at all)
     */
    @Throws(Throwable::class)
    suspend fun getTrackData(
        trackId: TrackId,
        linkTypes: Set<TrackLinkType> = TrackLinkType.standardLinkTypes,
        forceLinkGeneration: Boolean = false,
    ): TrackDataResponse {
        val track = findById(trackId) ?: throw IllegalArgumentException("No Track found with ID: ${trackId.value}")
        return getTrackData(track, linkTypes, forceLinkGeneration)
    }

    /**
     * Returns the ByteArray of the requested track link if it is cached, the link to the resource
     * if it is not cached but exists, or null otherwise (such as when no album art exists at all)
     */
    @Throws(Throwable::class)
    suspend fun getTrackData(
        track: Track,
        linkTypes: Set<TrackLinkType> = TrackLinkType.standardLinkTypes,
        forceLinkGeneration: Boolean = false,
    ): TrackDataResponse {
        val existingData = linkTypes.associateWith { linkType ->
            TrackCacheService.getCacheItemIfAvailable(track.id, linkType)
        }
        val missingTypes = linkTypes.filter { type ->
            (existingData[type] == null || forceLinkGeneration) && track.hasDataForType(type)
        }

        val trackLinkResponse = if (track.apiId != null) {
            if (missingTypes.isNotEmpty()) {
                getTrackLinksLive(track.apiId, missingTypes.toSet())
            } else {
                TrackLinkResponse()
            }
        } else if (missingTypes.isNotEmpty()) {
            throw IllegalStateException("A Track with no API ID did not have data to read!")
        } else {
            return TrackDataResponse(track)
        }

        return TrackDataResponse(
            track = track,
            audioOgg = existingData[TrackLinkType.AUDIO_OGG],
            audioMp3 = existingData[TrackLinkType.AUDIO_MP3],
            albumArtPng = existingData[TrackLinkType.ART_PNG],
            thumbnailArtPng = existingData[TrackLinkType.THUMBNAIL_PNG],
            audioLinkOgg = trackLinkResponse.audioLinkOgg,
            audioLinkMp3 = trackLinkResponse.audioLinkMp3,
            albumArtLinkPng = trackLinkResponse.albumArtLinkPng,
            thumbnailArtLinkPng = trackLinkResponse.thumbnailArtLinkPng,
        )
    }

    @Throws(Throwable::class)
    suspend fun getTrackPreview(trackId: TrackApiId, anonymousAccessToken: String): PublicTrackInfo {
        val url = if (AuthService.isAuthenticated()) {
            "track/preview/${trackId.value}"
        } else {
            "track/preview/public/${trackId.value}/token/$anonymousAccessToken"
        }

        return Api.get<PublicTrackInfo>(url)
    }

    // https://gorilla-tracks.s3.us-west-2.amazonaws.com/music/3104.ogg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20230923T221730Z&X-Amz-SignedHeaders=host&X-Amz-Expires=14400&X-Amz-Credential=AKIAJIAKDDLEY424ICFA%2F20230923%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Signature=ba1e5deb3d7b4f15008bf8cb7e9090c8bb9f6d97ae119344e14274f1a43e5eb9
    fun getSecondsUntilLinkExpiration(trackLink: String): Int {
        val url = Url(trackLink)
        val date = url.getQueryParam("X-Amz-Date") ?: run {
            logCrit("TrackLink is missing X-Amz-Date query param and cannot be parsed!")
            return 10000
        }

        val expiration = url.getQueryParam("X-Amz-Expires") ?: run {
            logCrit("TrackLink is missing X-Amz-Expires query param and expiration cannot be parsed!")
            return 10000
        }

        // "date" is an ISO date but with all the formatting removed. So we just need to put it all back I guess.
        val reconstructedIsoDate = date.substring(0, 4) + "-" + date.substring(4, 6) + "-" +
                date.substring(6, 11) + ":" + date.substring(11, 13) + ":" + date.substring(13, 16)

        val createdInstant = try {
            Instant.parse(reconstructedIsoDate)
        } catch (e: Exception) {
            logCrit("Could not parse ISO date from X-Amz-Date header! '$reconstructedIsoDate'", e)
            return 10000
        }

        val expirationInstant = createdInstant.plus(expiration.toInt(), DateTimeUnit.SECOND)

        return (expirationInstant - now()).inWholeSeconds.toInt()
    }

    /**
     * Returns the ByteArray of the requested track data. If it is cached, the cache will first
     * be looked at. Otherwise, the entire resource will be downloaded and returned as a ByteArray.
     */
    @Throws(Throwable::class)
    suspend fun getTrackByteData(
        track: Track,
        linkType: TrackLinkType,
        saveToCache: Boolean = track.offlineAvailability != OfflineAvailabilityType.ONLINE_ONLY,
    ): ByteArray? {
        val data = try {
            getTrackData(track, setOf(linkType))
        } catch (e: Exception) {
            if (e.isBenignException()) {
                logError("Could not get track links because of no internet", e)
            } else {
                logCrit("Could not get track links for an unknown reason!", e)
            }
            return null
        }
        data.getCachedResource(linkType)?.let { return it }

        val url = data.getResourceLink(linkType) ?: return null

        val liveData = try {
            Api.download(url).data
        } catch (e: Exception) {
            logError(e)
            return null
        }

        if (saveToCache) {
            TrackCacheService.saveTrackByteData(track.id, liveData, linkType)
        }

        return liveData
    }

    fun findLocalIdForApiId(apiIds: Collection<TrackApiId>): Map<TrackApiId, TrackId> {
        return trackDao.findLocalIdForApiId(apiIds).many().associate { it.apiId!! to it.id }
    }

    fun findApiIdForLocalId(localIds: Collection<TrackId>): Map<TrackId, TrackApiId?> {
        return trackDao.findApiIdForLocalId(localIds).many().associate { it.id to it.apiId }
    }

    fun save(track: Track): TrackId {
        return save(track.toDbTrack())
    }

    internal fun save(track: DbTrack, useTransaction: Boolean = false): TrackId {
        return if (track.id.value == 0L) {
            logDebug("Inserting new track with API ID: ${track.apiId?.value}")
            // This is kind of jank because lastInsertRowId() only works if you're inside
            // of an explicit transaction. But some code, like notably the sync engine,
            // already has its own transaction. I don't want to mess with transactions in
            // transactions, especially because I've seen wonky errors with some of this
            // database stuff in the past. So I am just doing the cop-out thing of making
            // the transaction be a parameter. I don't love it. But I am lazy.
            if (useTransaction) {
                Database.transactionWithReturn {
                    trackDao.insert(track)
                    TrackId(trackDao.lastInsertRowId().one())
                }
            } else {
                trackDao.insert(track)
                TrackId(trackDao.lastInsertRowId().one())
            }
        } else {
            logDebug("Updating track with API ID: ${track.apiId?.value} and Local ID: ${track.id.value}")
            trackDao.upsert(track)
            track.id
        }
    }

    private val handlers = mutableMapOf<Int, TrackChangeHandler>()
    private var handlerId: Int = 0
    private val lock: Lock = Lock()

    fun registerEventHandler(handler: TrackChangeHandler): Int {
        // Friendly reminder that ++ is not atomic. We don't want to give out 2 of the same ID
        val id = lock.use { ++handlerId }
        handlers[handlerId] = handler
        return id
    }

    fun unregisterEventHandler(handlerId: Int) {
        handlers.remove(handlerId)
    }

    internal fun broadcastTrackChange(tracks: Collection<Track>, changeType: ChangeType) {
        if (handlers.isEmpty()) {
            logWarn("No TrackChangeHandler registered!")
        }

        handlers.values.forEach { it(TrackChangeEvent(tracks, changeType)) }

        Database.forceSave()
    }

    internal fun updateTrackLastStarted(track: Track) {
        trackDao.updateLastStartedOnDevice(track.startedOnDevice, track.id)
    }

    // TODO figure out how this should handle local-only tracks
    @Throws(Throwable::class)
    suspend fun downloadTracks(ids: List<Track>, audioFormat: AudioFormat): DownloadResponse {
        val params = mutableMapOf("audioFormat" to audioFormat.name)

        // I did the API for this kind of weird. I think to not break backwards compatibility with something.
        // But now that this weirdness is abstracted away in this library idk that I care that much to fix it.
        // It is perhaps justified since one response is the file and the other is a zip file.
        return if (ids.size == 1) {
            Api.download(Api.BASE_URL + "file/download/${ids.first().apiId!!.value}", params)
        } else {
            params["trackIds"] = ids.map { it.apiId!!.value }.joinToString(",")
            Api.download(Api.BASE_URL + "file/multi-download", params)
        }
    }

    // 'startTime' and 'duration' are expected to be Strings in the format:
    // <optional-hours>:<minutes>:<seconds>.<optional-milliseconds>
    // e.g. 0:15, 1:10:52, 1:10:52.250, 0:01.625
    @Throws(Throwable::class)
    suspend fun trimTrack(track: Track, startTime: String? = null, duration: String? = null): Track {
        require(startTime != null || duration != null) {
            "Either 'minimum' or 'maximum' must not be null!"
        }

        fun String.formatString(): String {
            val (nonDecimal, decimal) = if (this.contains('.')) {
                val parts = this.split(".")
                parts[0] to parts[1]
            } else {
                this to "000"
            }

            val parts = nonDecimal.split(":").map { it.padStart(2, '0') }

            val (hours, minutes, seconds) = if (parts.size == 3) {
                Triple(parts[0], parts[1], parts[2])
            } else {
                Triple("00", parts[0], parts[1])
            }

            return "$hours:$minutes:$seconds.$decimal"
        }

        val request = TrackTrimRequest(track.apiId!!, startTime?.formatString(), duration?.formatString())

        val newLength = Api.post<TrackTrimResponse>("track/trim", request).newLength

        TrackCacheService.deleteCacheOnDisk(track.id, TrackLinkType.standardAudioType)

        val refreshedTrack = trackDao.findById(track.id).one()
        val newTrack = refreshedTrack.copy(length = newLength, audioCachedAt = null)
        trackDao.upsert(newTrack)

        val externalTrack = newTrack.toTrack()

        broadcastTrackChange(listOf(externalTrack), ChangeType.UPDATED)

        return externalTrack
    }

    // 'startTime' and 'maximum' for this overload are, instead, seconds as a decimal.
    // Some clients have a UI that is better suited to providing this type of argument.
    @Throws(Throwable::class)
    suspend fun trimTrack(track: Track, startTime: Double? = null, duration: Double? = null): Track {
        fun Double.formatNumber(): String {
            val hours = (this / 3600).toInt()
            val minutes = (this / 60 % 60).toInt()
            val seconds = (this % 60).toInt()
            val fraction = ((this - this.toInt()) * 1000)

            // This string will be correctly zero-padded by the overload we call into anyway,
            // so don't bother doing os here.
            return "$hours:$minutes:$seconds.$fraction"
        }

        return trimTrack(track, startTime?.formatNumber(), duration?.formatNumber())
    }

    @Throws(Throwable::class)
    suspend fun adjustVolume(tracks: Collection<Track>, percentageChange: Double) {
        val localIds = tracks.map { it.id }
        val apiIds = tracks.map { it.apiId!! }

        logInfo("Adjusting volume of tracks: ${localIds.map { it.value }} by percentage: $percentageChange")

        val request = VolumeAdjustRequest(apiIds, percentageChange)

        Api.post<Unit>("track/volume-adjust", request)

        tracks.forEach { track ->
            TrackCacheService.deleteCacheOnDisk(track.id, TrackLinkType.standardAudioType)
        }

        val updatedTracks = trackDao.findByIds(localIds).many().map { track ->
            val newTrack = track.copy(audioCachedAt = null)
            trackDao.upsert(newTrack)
            newTrack
        }

        broadcastTrackChange(updatedTracks.toTracks(), ChangeType.UPDATED)
    }
}

enum class AudioFormat { OGG, MP3 }

fun AudioFormat.toTrackLinkType() = when (this) {
    AudioFormat.OGG -> TrackLinkType.AUDIO_OGG
    AudioFormat.MP3 -> TrackLinkType.AUDIO_MP3
}

@Serializable
internal data class TrackLinkResponse(
    val audioLinkOgg: String? = null,
    val audioLinkMp3: String? = null,
    val albumArtLinkPng: String? = null,
    val thumbnailArtLinkPng: String? = null,
) {
    val audioLink get() = when (PlatformDeviceUtil.getDefaultAudioFormat()) {
        AudioFormat.OGG -> audioLinkOgg
        AudioFormat.MP3 -> audioLinkMp3
    }

    val audioLinkType get() = when {
        audioLinkOgg != null -> TrackLinkType.AUDIO_OGG
        audioLinkMp3 != null -> TrackLinkType.AUDIO_MP3
        else -> TrackLinkType.AUDIO_OGG
    }
}

class TrackDataResponse(
    private val track: Track,
    val audioOgg: ByteArray? = null,
    val audioMp3: ByteArray? = null,
    val albumArtPng: ByteArray? = null,
    val thumbnailArtPng: ByteArray? = null,
    val audioLinkOgg: String? = null,
    val audioLinkMp3: String? = null,
    val albumArtLinkPng: String? = null,
    val thumbnailArtLinkPng: String? = null,
) {
    @Suppress("unused")
    val audio: ByteArray? = audioOgg ?: audioMp3
    @Suppress("unused")
    val audioLink: String? = audioLinkOgg ?: audioLinkMp3

    fun getCachedResource(linkType: TrackLinkType): ByteArray? = when (linkType) {
        TrackLinkType.AUDIO_MP3 -> audioMp3
        TrackLinkType.AUDIO_OGG -> audioOgg
        TrackLinkType.ART_PNG -> albumArtPng
        TrackLinkType.THUMBNAIL_PNG -> thumbnailArtPng
    }

    fun getResourceLink(linkType: TrackLinkType): String? = when (linkType) {
        TrackLinkType.AUDIO_MP3 -> audioLinkMp3
        TrackLinkType.AUDIO_OGG -> audioLinkOgg
        TrackLinkType.ART_PNG -> albumArtLinkPng
        TrackLinkType.THUMBNAIL_PNG -> thumbnailArtLinkPng
    }

    suspend fun getAsBytes(
        linkType: TrackLinkType,
        saveToCache: Boolean = track.offlineAvailability != OfflineAvailabilityType.ONLINE_ONLY,
    ): ByteArray? {
        val cachedBytes = getCachedResource(linkType)
        if (cachedBytes != null) {
            return cachedBytes
        }

        val link = getResourceLink(linkType)
        if (link != null) {
            val liveBytes = Api.download(link).data
            if (saveToCache) {
                TrackCacheService.saveTrackByteData(track.id, liveBytes, linkType)
            }
            return liveBytes
        }

        return null
    }
}

enum class TrackLinkType(internal val extension: String) {
    AUDIO_MP3("mp3"),
    AUDIO_OGG("ogg"),
    ART_PNG("png"),
    THUMBNAIL_PNG("png");

    companion object {
        val standardAudioType = when (PlatformDeviceUtil.getDefaultAudioFormat()) {
            AudioFormat.OGG -> AUDIO_OGG
            AudioFormat.MP3 -> AUDIO_MP3
        }
        val standardLinkTypes: Set<TrackLinkType> by lazy {
            setOf(ART_PNG, THUMBNAIL_PNG) + standardAudioType
        }
    }
}

class TrackChangeEvent(val tracks: Collection<Track>, val changeType: ChangeType)

enum class ChangeType {
    DELETED, UPDATED, ADDED
}

// This used to be a Pair<String, TrackId>, but I ran into a compiler error where mapping
// over a value class in a pair in Javascript would corrupt the value class. So instead of
// it being TrackId(value=5) it was TrackId(value=TrackId(5)), which is clearly not right.
// Maybe this can be removed after bumping Kotlin to 1.9? But it's not a big deal I guess.
private class ProtoAlbum(val name: String, val trackId: TrackId)

@Serializable
internal data class TrackLinkRegenerationResponse(val pageUrl: String)

@Serializable
internal data class TrackTrimRequest(
    val trackId: TrackApiId,
    val startTime: String?,
    val duration: String?,
)

@Serializable
internal data class TrackTrimResponse(val newLength: Int)

@Serializable
internal data class VolumeAdjustRequest(val trackIds: Collection<TrackApiId>, val volumeAdjustAmount: Double)

@Serializable
data class PublicTrackInfo(
    val trackLink: String,
    val albumArtLink: String?,
    val name: String,
    val artist: String,
    val album: String,
    val releaseYear: Int?,
    val length: Int,
    val ownerId: UserId,
)
