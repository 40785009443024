package components

import Dialog
import PageRouter
import ViewMode
import components.contextmenu.frontendApproveTrack
import components.contextmenu.frontendRejectTrack
import getVolumeIconClasses
import hide
import kotlinx.browser.document
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.html.*
import kotlinx.html.div
import kotlinx.html.dom.append
import kotlinx.html.dom.create
import kotlinx.html.js.*
import mainScope
import net.gorillagroove.api.ApiSocket
import net.gorillagroove.authentication.VersionService
import net.gorillagroove.discovery.ImportService
import net.gorillagroove.review.ReviewQueueService
import net.gorillagroove.sync.SyncCoordinator
import net.gorillagroove.track.*
import net.gorillagroove.track.NowPlayingEventType.*
import net.gorillagroove.util.Formatter
import net.gorillagroove.util.GGLog
import net.gorillagroove.util.GGLog.logCrit
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.takeIfNotBlank
import onClickSuspend
import org.w3c.dom.*
import org.w3c.dom.events.Event
import org.w3c.dom.events.MouseEvent
import org.w3c.dom.url.URL
import queryId
import removeChildren
import setHidden
import show
import util.ByteUtil.displayOnElement
import util.ByteUtil.toBlob
import kotlin.time.Duration.Companion.seconds

private val repeatButton: Element get() = document.getElementById("repeat-button")!!
private val shuffleButton: Element get() = document.getElementById("shuffle-button")!!
private val shuffleGear: Element get() = document.getElementById("shuffle-gear")!!

private val reviewControls get() = document.getElementById("footer-review-controls") as HTMLElement?
private val reviewInfoSection get() = document.getElementById("review-info-section") as HTMLElement?

private val importToLibrarySection get() = document.getElementById("import-to-library-footer") as HTMLElement?

private val albumArtElement get() = document.getElementById("album-art") as? HTMLDivElement
private val audioElement get() = document.getElementById("main-audio-player") as? HTMLAudioElement
private val volumeSlider get() = document.getElementById("volume-slider") as HTMLInputElement
private val volumeIcon: Element get() = document.querySelector("#volume-indicator i")!!

private var nowPlayingHandlerId: Int? = null
private var trackFormatChangedHandlerId: Int? = null

private val syncLoadingSpinner get() = document.querySelector("#bottom-right-content .loading-spinner") as HTMLElement?
private val socketConnectionWarning get() = document.querySelector("#socket-connection-warning") as HTMLElement?

@Suppress("FunctionName")
fun DIV.Footer() = div {
    id = "footer"

    div("album-art") {
        id = "album-art"
        style = """background-image: url("/assets/unknown-art.jpg");"""

        onClickFunction = {
            Dialog.show(document.create.div("album-art") {
                id = "album-art-dialog"

                style = "background-image: ${albumArtElement!!.style.backgroundImage}"
            })
        }
    }

    div {
        id = "music-controls"

        div {
            id = "now-playing"
            +""
        }
        div {
            id = "button-box"

            div(classes = "d-flex") {
                button(classes = "icon") {
                    i(classes = "fas fa-step-backward")

                    onClickFunction = {
                        NowPlayingService.playPrevious()
                    }
                }
                button(classes = "icon") {
                    id = "play-button"

                    i(classes = "fas fa-play")

                    onClickFunction = {
                        NowPlayingService.resume()
                        renderPlayPauseButton()
                    }
                }
                button(classes = "icon d-none") {
                    id = "pause-button"

                    i(classes = "fas fa-pause")

                    onClickFunction = {
                        NowPlayingService.pause()
                        renderPlayPauseButton()
                    }
                }
                button(classes = "icon") {
                    i(classes = "fas fa-step-forward")

                    onClickFunction = {
                        NowPlayingService.playNext()
                    }
                }
            }
            div {
                id = "footer-secondary-section"

                button(classes = "p-relative icon ${if (NowPlayingService.isShuffling) "active" else ""}") {
                    id = "shuffle-button"

                    i(classes = "fa-solid fa-shuffle")

                    onClickFunction = {
                        NowPlayingService.isShuffling = !NowPlayingService.isShuffling
                        if (NowPlayingService.isShuffling) {
                            shuffleButton.classList.add("active")
                        } else {
                            shuffleButton.classList.remove("active")
                        }

                        if (PageRouter.currentViewMode == ViewMode.NOW_PLAYING) {
                            TrackTable.fullRender()
                        }
                    }

                    onContextMenuFunction = { event ->
                        event.preventDefault()

                        Dialog.show(ShuffleLeanEdit())
                    }

                    i("classes fa-solid fa-gear") {
                        id = "shuffle-gear"
                    }
                }
                button(classes = "icon ${if (NowPlayingService.isRepeating) "active" else ""}") {
                    id = "repeat-button"

                    i(classes = "fa-solid fa-rotate")

                    onClickFunction = {
                        NowPlayingService.isRepeating = !NowPlayingService.isRepeating
                        if (NowPlayingService.isRepeating) {
                            repeatButton.classList.add("active")
                        } else {
                            repeatButton.classList.remove("active")
                        }
                    }
                }
            }

            div("d-none") {
                id = "footer-review-controls"

                span {
                    + "Review Controls"
                }

                button(classes = "icon") {
                    tooltip = "Approve review track"

                    i("fa-solid fa-thumbs-up")

                    onClickSuspend = {
                        ReviewQueueService.frontendApproveTrack(listOf(NowPlayingService.currentTrack!!))
                    }
                }

                button(classes = "icon") {
                    tooltip = "Reject review track"

                    i("fa-solid fa-thumbs-down")

                    onClickSuspend = {
                        ReviewQueueService.frontendRejectTrack(listOf(NowPlayingService.currentTrack!!))
                    }
                }
            }

            div("d-none") {
                id = "import-to-library-footer"

                ActionButton("import-to-library-button", "Import to Library") {
                    val track = NowPlayingService.currentTrack ?: run {
                        logCrit("No Track found when clicking 'Import to Library'?")
                        return@ActionButton
                    }

                    try {
                        ImportService.importUserTracks(listOf(track.apiId!!))

                        importToLibrarySection?.hide()
                        Toast.success("'${track.name}' imported successfully")
                    } catch (e: Exception) {
                        Toast.error("Failed to import track")
                    }
                }
            }
        }

        div("d-flex") {
            div {
                div {
                    id = "seeker"

                    div {
                        id = "time-display"
                        +"0:00 / 0:00"
                    }
                    progress {
                        id = "main-audio-progress"
                        max = "1"
                        value = "0"
                        onClickFunction = ::handleProgressClick
                    }
                }

                div {
                    id = "volume"

                    button(classes = "icon volume-indicator") {
                        id = "volume-indicator"

                        i(classes = getVolumeIconClassesInternal())

                        onClickFunction = {
                            NowPlayingService.muted = !NowPlayingService.muted
                            audioElement!!.muted = NowPlayingService.muted
                            volumeIcon.className = getVolumeIconClassesInternal()
                        }
                    }

                    input {
                        id = "volume-slider"
                        type = InputType.range
                        min = "0"
                        max = "1"
                        step = "0.001"
                        onInputFunction = { event ->
                            val input = event.currentTarget!! as HTMLInputElement
                            val value = input.value

                            NowPlayingService.volume = value.toDouble()

                            volumeIcon.className = getVolumeIconClassesInternal()
                            audioElement!!.volume = NowPlayingService.volume
                        }
                    }
                }
            }

            ul {
                id = "review-info-section"
            }
        }
    }

    audio {
        id = "main-audio-player"

        onTimeUpdateFunction = ::handleTimeUpdate
        onEndedFunction = { NowPlayingService.playNext() }
        onCanPlayFunction = {
            if (NowPlayingService.isPlaying) {
                (it.currentTarget as HTMLAudioElement).play()
            }
        }
    }

    BackgroundTaskProgress()

    span {
        id = "bottom-right-content"

        span("mr-6 d-none") {
            id = "socket-connection-warning"

            tooltip = "Unable to connect to Gorilla Groove. Offline music may still work"
            tooltipDelay = 0

            i("fa-solid fa-triangle-exclamation")
        }

        LoadingSpinner(classes = "nav-text-color")

        span {
            id = "version-marker"

            a {
                + VersionService.currentDeviceVersion

                onClickFunction = {
                    Dialog.show(ChangelogModal())
                }
            }
        }
    }

    mainScope.launch {
        // So, if you try to use the "value" property of the input {} element's lambda, it doesn't actually
        // get properly set. I think this is calling setAttribute("value", "0.5"), which inputs ignore as
        // you must use the hidden value property instead. So this is basically a library bug. We have to
        // set the value property after it is created in order for it to properly get set.
        volumeSlider.value = NowPlayingService.volume.toString()

        audioElement!!.volume = NowPlayingService.volume
        audioElement!!.muted = NowPlayingService.muted

        // I am (currently anyway) never cleaning up this handler. Just creating it once and the
        // handler checks to make sure the elements exist on the page before doing anything.
        if (nowPlayingHandlerId == null) {
            nowPlayingHandlerId = NowPlayingService.registerEventHandler(::handleNowPlayingChangeEvent)
        }
        if (trackFormatChangedHandlerId == null) {
            trackFormatChangedHandlerId = Formatter.registerEventHandler {
                renderNowPlayingTrack()
            }
        }

        Footer.renderShuffleGear()
        Footer.renderSocketConnectionIcon()
        Tooltip.registerAll(document.queryId("footer"))
    }
}

private var currentBlobUrl: String? = null

private fun handleNowPlayingChangeEvent(event: NowPlayingEvent) {
    val audioPlayer = audioElement ?: return
    val albumArtElement = albumArtElement ?: return

    when (event.type) {
        ALL_TRACKS_CHANGED, CURRENT_TRACK_CHANGED -> {
            renderNowPlayingTrack()

            currentBlobUrl?.let { URL.revokeObjectURL(it) }

            mainScope.launch {
                val nowPlayingTrack = NowPlayingService.currentNowPlayingTrack ?: return@launch
                val track = nowPlayingTrack.track

                val data = TrackService.getTrackData(track, setOf(TrackLinkType.standardAudioType, TrackLinkType.ART_PNG))

                val url = if (data.audio != null) {
                    URL.createObjectURL(data.audio!!.toBlob()).also { currentBlobUrl = it }
                } else {
                    data.audioLink ?: ""
                }

                audioPlayer.src = url

                audioPlayer.setAttribute("trackId", nowPlayingTrack.nowPlayingTrackId.value.toString())
                // Call .load() so that any previous media gets discarded. If we don't do this, then the previous
                // track can continue playing until the network fetches the next track, which is particularly
                // undesirable if the previous track was paused. It will resume playing before the change finalizes.
                audioPlayer.load()
                NowPlayingService.resume()

                val bytes = try {
                    data.getAsBytes(TrackLinkType.ART_PNG)
                } catch (e: Exception) {
                    GGLog.logError("Failed to download album art!")
                    null
                }

                if (bytes == null) {
                    albumArtElement.style.backgroundImage = """url("/assets/unknown-art.jpg")"""
                } else {
                    bytes.displayOnElement(track, albumArtElement)
                }
            }

            renderPlayPauseButton()
            renderReviewControls()
            renderImportSection()
        }
        PLAYBACK_STOPPED -> {
            audioPlayer.pause()
            renderPlayPauseButton()

            renderNowPlayingTrack()
        }
        PLAYBACK_PAUSED -> {
            audioPlayer.pause()
            renderPlayPauseButton()
        }
        PLAYBACK_RESUMED, PLAYBACK_STARTED -> {
            // There is a race condition that can occur when changing tracks that can cause the previous
            // track to start momentarily playing before the tracks switch. So this is checking to make
            // sure that the audio element has the correct track ID before we attempt to resume, lest we
            // resume playback on a paused track that we just switched from.
            val trackId = audioPlayer.getAttribute("trackId")?.toInt()
            if (trackId == NowPlayingService.currentNowPlayingTrack?.nowPlayingTrackId?.value) {
                audioPlayer.play()
            }
            renderPlayPauseButton()
        }
        else -> {}
    }
}

private fun renderNowPlayingTrack() {
    val nowPlayingText = document.getElementById("now-playing")!!
    val timeDisplayText = document.getElementById("time-display")!!

    val track = NowPlayingService.currentTrack
    if (track == null) {
        nowPlayingText.textContent = ""
        timeDisplayText.textContent = "0:00 / 0:00"
        document.title = SiteLayout.documentOriginalTitle
        return
    }

    nowPlayingText.textContent = Formatter.getPlayingTrackDisplayString(track)
    document.title = nowPlayingText.textContent ?: SiteLayout.documentOriginalTitle

    timeDisplayText.textContent = "${Formatter.getDurationDisplayFromSeconds(NowPlayingService.currentTime.toInt())} / ${Formatter.getDurationDisplay(track)}"

    document.getElementById("main-audio-progress")?.let { progressBar ->
        progressBar as HTMLProgressElement

        val percentDone = NowPlayingService.currentTime / track.length
        progressBar.value = percentDone
    }
}

private fun renderPlayPauseButton() {
    val playButton = document.getElementById("play-button")!!
    val pauseButton = document.getElementById("pause-button")!!

    if (NowPlayingService.isPlaying) {
        playButton.classList.add("d-none")
        pauseButton.classList.remove("d-none")
    } else {
        playButton.classList.remove("d-none")
        pauseButton.classList.add("d-none")
    }
}

private fun renderReviewControls() {
    val track = NowPlayingService.currentTrack
    if (track == null || !track.inReview) {
        reviewControls?.hide()
        reviewInfoSection?.hide()
    } else {
        reviewControls?.show()

        reviewInfoSection?.removeChildren()

        reviewInfoSection?.append {
            ReviewQueueService.startOrGetSession().getReviewOwnershipNote(track)?.let { ownershipNote ->
                li { + ownershipNote }
            }

            track.note.takeIfNotBlank()?.let { note ->
                li { + note }
            }
        }

        reviewInfoSection?.show()
    }
}

private fun renderImportSection() {
    val track = NowPlayingService.currentTrack
    if (track == null || track.isOwnTrack()) {
        importToLibrarySection?.hide()
    } else {
        importToLibrarySection?.show()
    }
}

private fun handleTimeUpdate(event: Event) {
    val currentTime = (event.currentTarget as HTMLAudioElement).currentTime
    NowPlayingService.currentTime = currentTime
    val progressBar = document.getElementById("main-audio-progress")!! as HTMLProgressElement

    val track = NowPlayingService.currentTrack ?: run {
        progressBar.value = 0.0
        return
    }

    val percentDone = NowPlayingService.currentTime / track.length
    progressBar.value = percentDone

    val timeDisplayText = document.getElementById("time-display")!!
    val timeDisplay = "${Formatter.getDurationDisplayFromSeconds(currentTime.toInt())} / ${Formatter.getDurationDisplay(track)}"
    timeDisplayText.textContent = timeDisplay
}

private fun getVolumeIconClassesInternal(): String {
    val volume = NowPlayingService.volume
    val muted = NowPlayingService.muted
    return getVolumeIconClasses(volume, muted)
}

private fun handleProgressClick(event: Event) {
    event as MouseEvent

    val progress = event.currentTarget!! as HTMLProgressElement
    val track = NowPlayingService.currentTrack ?: return
    val percentage = event.offsetX / progress.offsetWidth

    progress.value = percentage
    audioElement!!.currentTime = (percentage * track.length)
}

object Footer {
    init {
        SyncCoordinator.registerSyncLifecycleChangeHandler { syncing ->
            if (syncing) syncLoadingSpinner?.show() else syncLoadingSpinner?.hide()
        }

        TrackService.registerEventHandler { event ->
            if (event.changeType != ChangeType.UPDATED) {
                return@registerEventHandler
            }

            val playingTrackUpdated = event.tracks.any { it.id == NowPlayingService.currentTrack?.id }
            if (playingTrackUpdated) {
                renderNowPlayingTrack()
            }
        }
    }

    fun renderShuffleGear() {
        if (NowPlayingService.isShuffleCustomized) {
            shuffleGear.show()
        } else {
            shuffleGear.hide()
        }
    }

    private var refreshIconJob: Job? = null
    fun renderSocketConnectionIcon() {
        refreshIconJob?.cancel()

        if (ApiSocket.isConnected) {
            socketConnectionWarning?.setHidden(true)
            return
        }

        // If we are going to show the socket as disconnected, add a delay first.
        // This can reduce flicker on sign-in / refresh as we wait for the socket to open.
        refreshIconJob = mainScope.launch {
            delay(3.seconds)

            if (!ApiSocket.isConnected) {
                socketConnectionWarning?.setHidden(false)
            }
        }
    }

    suspend fun refreshDisplayedArt() {
        val track = NowPlayingService.currentTrack ?: return

        val data = TrackService.getTrackByteData(track, TrackLinkType.ART_PNG)

        val artElement = albumArtElement ?: return

        data?.displayOnElement(track, artElement)
    }
}
