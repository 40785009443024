package net.gorillagroove.api

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.engine.*
import io.ktor.client.network.sockets.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.plugins.websocket.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.client.statement.*
import io.ktor.client.utils.*
import io.ktor.content.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import io.ktor.util.network.*
import io.ktor.util.reflect.*
import io.ktor.utils.io.*
import kotlinx.datetime.Clock.System.now
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonBuilder
import net.gorillagroove.authentication.AuthService
import net.gorillagroove.track.OfflineModeService
import net.gorillagroove.util.GGLog.logDebug
import net.gorillagroove.util.PlatformUtil
import net.gorillagroove.util.SettingType
import net.gorillagroove.util.Settings
import net.gorillagroove.util.takeIfNotBlank

@Suppress("VARIABLE_IN_SINGLETON_WITHOUT_THREAD_LOCAL")
object Api {
    private const val MAX_RESPONSE_LOGGING_SIZE = 750

    internal var hostOverride: String? = Settings.getString(SettingType.OVERRIDDEN_HOST)
    internal const val PROD_HOST = "gorillagroove.net"

    internal var useHttps: Boolean = Settings.getBoolean(SettingType.OVERRIDDEN_HOST_USE_HTTPS, true)
    internal var BASE_URL = ""

    init {
        setBaseUrl()
    }

    fun overrideHost(host: String?, useHttps: Boolean = true) {
        val newHost = host?.takeIfNotBlank()

        hostOverride = newHost
        this.useHttps = useHttps

        setBaseUrl()

        if (newHost == null) {
            Settings.deleteKey(SettingType.OVERRIDDEN_HOST)
        } else {
            Settings.setString(SettingType.OVERRIDDEN_HOST, host)
        }
        Settings.setBoolean(SettingType.OVERRIDDEN_HOST_USE_HTTPS, useHttps)
    }

    fun overrideUrl(url: String?) {
        if (url == null) {
            overrideHost(null, useHttps = true)
            return
        }

        val parsedUrl = Url(url)
        val port = parsedUrl.port
        val portString = if (port == 80 || port == 443) "" else ":" + parsedUrl.port
        overrideHost(
            host = parsedUrl.host + portString,
            useHttps = parsedUrl.protocol == URLProtocol.HTTPS,
        )
    }

    private fun setBaseUrl() {
        BASE_URL = (if (useHttps) "https://" else "http://") + (hostOverride ?: PROD_HOST) + "/api/"
    }

    fun getOverriddenHost(): String? {
        return hostOverride
    }

    fun getOverriddenUrl(): String? {
        return (if (useHttps) "https://" else "http://") + (hostOverride ?: PROD_HOST)
    }

    fun getOverriddenHostDisplayString(): String {
        return hostOverride ?: PROD_HOST
    }

    fun isUsingHttps(): Boolean {
        return useHttps
    }

    internal fun JsonBuilder.defaultConfiguration() {
        prettyPrint = true
        isLenient = true
        ignoreUnknownKeys = true
    }

    private val clientConfig = { config: HttpClientConfig<*> ->
        config.install(ContentNegotiation) {
            json(Json { this.defaultConfiguration() })
        }
        config.install(WebSockets)

        // There is a separate ktor logging module you can add here (after you add it to Gradle)
        // but it is kind of lousy. Everything logs on individual lines, and it's not very customizable.
        config.install("RequestLogging") {
            sendPipeline.intercept(HttpSendPipeline.Monitoring) {
                if (OfflineModeService.offlineModeEnabled) {
                    throw OfflineModeEnabledException()
                }

                val url = Url(context.url)

                val logBody = if (url.isSensitive()) {
                    "--SENSITIVE REQUEST REDACTED--"
                } else {
                    when (val body = context.body) {
                        is TextContent -> ": ${body.text}"
                        is EmptyContent -> ""
                        // Not sure what this would be. Maybe if we upload a file we'll find out
                        else -> ": $body"
                    }
                }

                logDebug(
                    "--> ${context.method.value} ${Url(context.url)} $logBody",
                    customTag = "Network"
                )
            }

            // There has to be a better way to write a custom response body logger. But the best I
            // have found is this: https://youtrack.jetbrains.com/issue/KTOR-1459#focus=Comments-27-4586975.0-0
            // Ktor client documentation is not great in early 2022.
            responsePipeline.intercept(HttpResponsePipeline.Receive) { (type, content) ->
                if (content !is ByteReadChannel) return@intercept

                val url = context.request.url

                val (body, originalData) = if (url.isSensitive()) {
                    "--SENSITIVE RESPONSE REDACTED--" to content
                } else {
                    val bodyByteArray = ByteArray(content.availableForRead)
                    content.readAvailable(bodyByteArray)

                    val original = ByteReadChannel(bodyByteArray)

                    val endIndex = minOf(bodyByteArray.size, MAX_RESPONSE_LOGGING_SIZE)
                    val body = bodyByteArray.decodeToString(0, endIndex)
                        .replace("\\s+".toRegex(), " ")

                    // Yeah, it's wasteful to read the byte channel when we don't actually do anything with it.
                    // But I think stuff gets weird if you don't. Probably not a big deal.
                    val finalBody = if (isBinaryData(context, url)) {
                        // No reason to log binary data. Just takes up space and is not useful.
                        "<binary data>"
                    } else if (bodyByteArray.size > MAX_RESPONSE_LOGGING_SIZE) {
                        "$body ..."
                    } else {
                        body
                    }

                    finalBody to original
                }

                // The amazon S3 links have a HUGE query string because that is where the authorization is baked in.
                // You could argue that we don't want to log authorization query parameters anyway (though it's not
                // a big deal because they expire within hours). But I don't really want to log them because they're
                // big and take up disk space in the database for no real benefit anyway.
                val urlToLog = if (url.host.contains("gorilla-tracks.s3")) {
                    url.toString().split("?").first()
                } else {
                    url.toString()
                }

                logDebug(
                    "<-- ${context.response.status.value} ${context.request.method.value} $urlToLog $body",
                    customTag = "Network"
                )

                val response = HttpResponseContainer(type, originalData)
                proceedWith(response)
//                return@intercept response
            }
        }
    }

    private var testClient: HttpClient? = null
    private val realClient by lazy {
        HttpClient(clientConfig)
    }

    internal val client get() = testClient ?: realClient

    // For testing. Not thrilled about this
    internal fun setTestEngine(engine: HttpClientEngine) {
        testClient = HttpClient(engine, clientConfig)
    }
    internal fun clearTestEngine() {
        testClient = null
    }

    // Again, there's gotta be a better way to do this. I can't infer anything useful
    // from the KType without reflection, so I can't add an interface or annotation
    // to mark something as sensitive. This will work for now I suppose.
    private fun Url.isSensitive(): Boolean {
        val url = this.toString()
        return url.contains("/authentication") || url.contains("/password-reset") || url.contains("/user/public")
    }

    @Throws(Throwable::class)
    internal suspend inline fun<reified T> get(url: String, params: Map<String, Any?> = emptyMap()): T {
        return fetch {
            client.get(BASE_URL + url.urlEncodedUrl() + params.toQueryString()) {
                addHeaders()
            }
        }
    }

    @Throws(Throwable::class)
    internal suspend inline fun<reified T> delete(url: String, params: Map<String, Any?> = emptyMap()): T {
        return fetch {
            client.delete(BASE_URL + url.urlEncodedUrl() + params.toQueryString()) {
                addHeaders()
            }
        }
    }

    // --- I'm not 100% sure if this comment is still true after upgrading to KTor 2.0. But w/e.
    // This will download the entire response into memory. If, for some reason, we are
    // downloading something very large, this will probably cause the user to OOM and
    // a different approach should be used. Because this is multiplatform code, it isn't
    // as easy as just dumping the response to a File because this could be running in
    // a browser where "Files" do not exist. Hence, why I'm punting on this potential issue.
    @Throws(Throwable::class)
    internal suspend inline fun download(fullUrl: String, params: Map<String, Any?> = emptyMap()): DownloadResponse {
        val response = fetchRaw {
            client.get(fullUrl + params.toQueryString())
        }

        // Header looks like "attachment; filename="Artist - Filename.mp3"
        val header = response.headers["Content-disposition"]
        val filename = header?.split(";")
            ?.find { it.trim().startsWith("filename") }
            ?.split("=")
            ?.last()
            ?.trim('"')

        return DownloadResponse(
            data = response.parse(),
            filename = filename ?: "unknown-filename"
        )
    }

    @Throws(Throwable::class)
    internal suspend inline fun<reified T> post(
        url: String,
        body: Any? = null,
		authToken: String? = null,
    ): T = fetch {
        client.post(BASE_URL + url.urlEncodedUrl()) {
            // Why do I have to tell a POST that its method is a POST? If you don't set this, it thinks it's a GET??
            method = HttpMethod.Post
            setBody(body ?: EmptyContent)
            addHeaders(authToken)
        }
    }

    @Throws(Throwable::class)
    internal suspend inline fun<reified T> patch(
        url: String,
        body: Any? = null,
        authToken: String? = null,
    ): T = fetch {
        client.patch(BASE_URL + url.urlEncodedUrl()) {
            // Why do I have to tell a POST that its method is a POST? If you don't set this, it thinks it's a GET??
            method = HttpMethod.Patch
            setBody(body ?: EmptyContent)
            addHeaders(authToken)
        }
    }

    @Throws(Throwable::class)
    internal suspend inline fun<reified T> put(
        url: String,
        body: Any? = null,
    ): T = fetch {
        client.put(BASE_URL + url.urlEncodedUrl()) {
            method = HttpMethod.Put
            setBody(body ?: EmptyContent)
            addHeaders()
        }
    }

    // TODO pretty sure this could now just call into complexUpload(), but I don't feel like adjusting it right now.
    @Throws(Throwable::class)
    internal suspend inline fun<reified T> upload(
        url: String,
        dataKey: String,
        filename: String,
        data: ByteArray,
    ): T {
        require(filename.contains('.')) { "'filename' must include the file extension" }

        return fetch {
            client.submitFormWithBinaryData(
                url = BASE_URL + url.urlEncodedUrl(),
                formData = formData {
                    append(dataKey, data, Headers.build {
                        append(HttpHeaders.ContentType, "multipart/form-data")
                        append(HttpHeaders.ContentDisposition, """filename="$filename"""")
                    })
                }
            ) {
                addAuthorization()
            }
        }
    }

    class BinaryUploadObject(
        val dataKey: String,
        val filename: String,
        val data: ByteArray,
    ) : UploadObject

    class JsonUploadObject(
        val dataKey: String,
        val data: String,
    ) : UploadObject

    interface UploadObject

    @Throws(Throwable::class)
    internal suspend inline fun<reified T> complexUpload(
        url: String,
        method: HttpMethod,
        uploadObjects: List<UploadObject>,
    ): T {
        val setup = { builder: HttpRequestBuilder ->
            with (builder) {
                this.method = method
                val body = MultiPartFormDataContent(formData {

                    uploadObjects.forEach { uploadItem ->
                        when (uploadItem) {
                            is BinaryUploadObject -> {
                                append(uploadItem.dataKey, uploadItem.data, Headers.build {
                                    append(HttpHeaders.ContentType, "multipart/form-data")
                                    append(HttpHeaders.ContentDisposition, """filename="${uploadItem.filename}"""")
                                })
                            }
                            is JsonUploadObject -> {
                                append(uploadItem.dataKey, uploadItem.data)
                            }
                        }
                    }
                })

                setBody(body)
                addAuthorization()
            }
        }

        return fetch {
            when (method) {
                HttpMethod.Put -> client.put(BASE_URL + url, setup)
                HttpMethod.Post -> client.post(BASE_URL + url, setup)
                else -> throw IllegalArgumentException("HttpMethod $method not supported")
            }
        }
    }

    internal fun HttpRequestBuilder.addHeaders(authToken: String? = null) {
        if (method != HttpMethod.Get) {
            headers.append(HttpHeaders.ContentType, "application/json")
        }
        addAuthorization(authToken)
    }

    @Throws(Throwable::class)
    internal suspend inline fun<reified T> fetch(request: () -> HttpResponse): T {
        return fetchRaw(request).parse<T>()
    }

    @Throws(Throwable::class)
    internal inline fun fetchRaw(request: () -> HttpResponse): HttpResponse {
        return try {
            request()
        } catch (e: Throwable) {
            // JS KTor treats certain network exceptions as "Error", which do not extend Exception.
            // This trips up a lot of my error handling code that checks for Exception, and is honestly
            // very weird for it to not be Exception. Non-exception Throwables are usually reserved
            // for things that will end a program and are very much not ok. Not having network access
            // is mundane should not generate a non-Exception throwable. This fixes that stupidity.
            if (e is Exception) {
                throw e
            } else {
                throw GenericNetworkException(e)
            }
        }
    }

    // FIXME is this comment horribly out of date? I seem to be sending the auth token to the socket request
    //  via headers. In theory it's working fine?
    fun HttpRequestBuilder.addAuthorization(overrideToken: String? = null) {
        // This shouldn't be necessary on the web where we have set an authorization cookie.
        // However, the ktor JS client does not allow for you to set the `credentials: 'include'`
        // property of a fetch request yet. https://youtrack.jetbrains.com/issue/KTOR-539
        // I don't think this is going to cause issues, but it is unnecessary.
        // FIXME this is now officially a problem because you can't pass headers with a WebSocket open.
        // So we now either need to do the hack mentioned in that YouTrack issue so that Fetch will
        // include cookies with the request, OR I need to add t=${AuthService.authToken} to the query
        // string of the socket request and then exclude it from the nginx logs. I truly hope that by
        // the time I ship this, ktor will have been patched up and I won't need to.
        // NGINX log filtering: https://serverfault.com/questions/1046249/exclude-a-specific-query-parameter-from-being-logged-in-nginx
        (overrideToken ?: AuthService.authToken)?.let { token ->
            headers.append("Authorization", token)
        }
    }
}

// This will comma-separate collections. I think I had to do parameter-repeating parameters when
// sending sorts to Spring... but it's been like 3 years, and maybe I did it wrong. But it's
// possible this will need to be updated to allow for different methods of serializing collections.
fun Map<String, Any?>.toQueryString(): String {
    return if (this.isEmpty()) {
        ""
    } else {
        val queryString = this.mapNotNull { (key, value) ->
            // Spring seems to really want stuff to just be comma separated lists, and not anything
            // fancy with angle brackets or w/e.
            val parsedValue = if (value is Collection<*>) {
                value.joinToString(",")
            } else {
                // I don't think there is a reason right now to pass a "null" parameter explicitly.
                // If I ever decide to change this, we will just have to pass a flag into this
                // in order to preserve nulls. It makes client code a bit easier to be able to
                // just provide "null" a function and have it not be sent rather than have to
                // conditionally add or remove null params from the query params map.
                value ?: return@mapNotNull null
            }

            return@mapNotNull "$key=$parsedValue"
        }.joinToString("&")

        "?$queryString"
    }
}

// Despite the fact that the internet seems to say that ktor will do this automatically, I am not
// seeing it url encode urls. This is relevant because we have some functions that let you search for
// artist and song names, and those can included non-ascii characters that require encoding.
fun String.urlEncodedUrl(): String {
    return this.split("/").joinToString("/") { it.encodeURLParameter() }
}

class OfflineModeEnabledException : RuntimeException("Offline mode is enabled and no request will be sent!")

suspend inline fun <reified T> HttpResponse.parse(): T {
    if (status.value in 400..599) {
        val serverResponse = try {
            call.bodyNullable(typeInfo<BadServerResponse>()) as BadServerResponse
        } catch (e: NoTransformationFoundException) {
            // If the server returns no response data, as it does when entering the incorrect login credentials,
            // there will be no response body, and we will get a NoTransformationFoundException.
            // So if we hit this scenario, build our own fake response because why not.
            // I probably should update the server, but at least now the Http client will transform consistently
            // no matter what the server does now, or in the future.
            BadServerResponse(
                timestamp = now(),
                status = status.value,
                // I have no idea why "status.description" is an empty string. It seems like
                // it shouldn't do that? Whatever Ktor. Seems like a bug on their end.
                // At least we can do this very easy workaround.
                error = HttpStatusCode.fromValue(status.value).description,
                message = "",
                path = this.request.url.fullPath
            )
        }

        if (status.value == 403) {
            throw ForbiddenRequestException(serverResponse)
        } else {
            throw BadRequestException(serverResponse)
        }
    } else {
        return body()
    }
}

// If you ask me, non 2xx status codes should throw exceptions. They are not successful. They are exceptional.
open class BadRequestException(val response: BadServerResponse) : RuntimeException()
// We aren't able to see specific error codes for exceptions thrown from Kotlin in iOS land.
// This ordinarily isn't a problem as I don't use it for anything meaningful. But one noteworthy
// "exception" to this is I need to detect 403 status code on login to indicate bad credentials.
class ForbiddenRequestException(response: BadServerResponse) : BadRequestException(response)

// When we get an error from the API, it will look something like this:
//{"timestamp":"2023-07-30T20:54:30.456+00:00","status":400,"error":"Bad Request","message":"PlaylistTrack ID: 5392 not found","path":"/api/playlist/track"}
@Serializable
data class BadServerResponse(
    val timestamp: Instant,
    val status: Int,
    val error: String,
    val message: String,
    val path: String,
)

internal fun Throwable.isNoInternetException(): Boolean {
    return this is ConnectTimeoutException ||
            this is SocketTimeoutException ||
            this is UnresolvedAddressException ||
            this is OfflineModeEnabledException ||
            this is GenericNetworkException ||
            PlatformUtil.isNoInternetException(this)
}

internal fun Throwable.isBenignException(): Boolean {
    return isNoInternetException() ||
            this is CancellationException ||
            // This is what is thrown if GG is offline for a deploy
            (this is BadRequestException && this.response.status == HttpStatusCode.BadGateway.value)
}

internal fun Url.getQueryParam(key: String): String? {
    return this.encodedQuery
        .split("&")
        .find { it.contains(key, ignoreCase = true) }
        ?.split("=")
        ?.last()
}

internal fun Url.stripQueryParams(vararg queryParams: String): String {
    val queryParts = this.encodedQuery.split("&").map {
        val parts = it.split("=")
        parts[0] to parts[1]
    }

    val validParts = queryParts.filterNot { (queryKey, _) ->
        queryParams.any { strippedKey ->
            queryKey.equals(strippedKey, ignoreCase = true)
        }
    }

    val fullPath = this.withoutQueryParams() + "?" + (validParts.joinToString { it.first + "=" + it.second })

    return fullPath.trimEnd('?')
}

internal fun Url.withoutQueryParams(): String {
    return this.toString().split("?").first()
}

private fun isBinaryData(context: HttpClientCall, url: Url): Boolean {
    if (context.response.status.value != 200) {
        return false
    }

    if (url.host.contains("gorilla-tracks.s3")) {
        return true
    }

    if (!url.host.contains("localhost")) {
        return false
    }

    return url.fullPath.contains("/album-art/") || url.fullPath.contains("/music/")
}

class DownloadResponse(val data: ByteArray, val filename: String)

class GenericNetworkException(cause: Throwable) : RuntimeException(cause)
