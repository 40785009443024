package net.gorillagroove.discovery

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import net.gorillagroove.api.Api

object YoutubeService {
    @Throws(Throwable::class)
    suspend fun findVideoForArtistWithLength(
        artist: String,
        trackName: String,
        length: Int
    ): FindVideoResponse {
        require(artist.isNotBlank()) { "Artist must not be blank!" }
        require(trackName.isNotBlank()) { "Track Name must not be blank!" }

        return Api.get("search/youtube/artist/${artist.trim()}/track-name/${trackName.trim()}/length/$length")
    }

    @Throws(Throwable::class)
    suspend fun searchYoutubeForTerm(term: String): YoutubeApiResponse {
        return Api.get("search/youtube/term/${term.trim()}")
    }

    @Throws(Throwable::class)
    suspend fun autocompleteYoutubeChannelName(partialName: String): AutocompleteResult {
        return Api.get("search/autocomplete/youtube/channel-name/${partialName.trim()}")
    }
}

@Serializable
data class VideoProperties(
    val id: String,
    val videoUrl: String,
    val duration: Int,
    val title: String,
    val channelName: String,
    val embedUrl: String,
)

@Serializable
data class FindVideoResponse(val videoProperties: VideoProperties?)

@Serializable
data class YoutubeApiResponse(
    val videos: List<YoutubeVideo>
)

@Serializable
data class YoutubeVideo(
    val title: String,
    val description: String,
    val channelTitle: String,
    val thumbnails: ItemThumbnails,
    val duration: Int,
    val viewCount: Long,
    val likes: Long,
    val dislikes: Long,
    val videoUrl: String,
    val embedUrl: String,
    val publishedAt: Instant,
    val id: String
)

@Serializable
data class ItemThumbnails(
    val default: ItemThumbnail,
    val medium: ItemThumbnail,
    val high: ItemThumbnail,
    val maxres: ItemThumbnail?
)

@Serializable
data class ItemThumbnail(
    val url: String,
    val width: Int,
    val height: Int
)
