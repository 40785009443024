package net.gorillagroove.db.shared

import com.squareup.sqldelight.Query
import com.squareup.sqldelight.TransacterImpl
import com.squareup.sqldelight.`internal`.copyOnWriteList
import com.squareup.sqldelight.db.SqlCursor
import com.squareup.sqldelight.db.SqlDriver
import kotlin.Any
import kotlin.Boolean
import kotlin.Double
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.Collection
import kotlin.collections.MutableList
import kotlin.reflect.KClass
import kotlinx.datetime.Instant
import net.gorillagroove.api.FailedListenId
import net.gorillagroove.api.LogLineId
import net.gorillagroove.api.PlaylistId
import net.gorillagroove.api.PlaylistTrackId
import net.gorillagroove.api.PlaylistUserId
import net.gorillagroove.api.ReviewSourceId
import net.gorillagroove.api.SyncStatusId
import net.gorillagroove.api.TrackApiId
import net.gorillagroove.api.TrackId
import net.gorillagroove.api.UserFavoriteId
import net.gorillagroove.api.UserId
import net.gorillagroove.api.UserPermissionId
import net.gorillagroove.api.UserSettingApiId
import net.gorillagroove.api.UserSettingId
import net.gorillagroove.db.DbFailedListen
import net.gorillagroove.db.DbFailedListenQueries
import net.gorillagroove.db.DbLogLine
import net.gorillagroove.db.DbLogLineQueries
import net.gorillagroove.db.DbPlaylist
import net.gorillagroove.db.DbPlaylistQueries
import net.gorillagroove.db.DbPlaylistTrack
import net.gorillagroove.db.DbPlaylistTrackQueries
import net.gorillagroove.db.DbPlaylistUser
import net.gorillagroove.db.DbPlaylistUserQueries
import net.gorillagroove.db.DbReviewSource
import net.gorillagroove.db.DbReviewSourceQueries
import net.gorillagroove.db.DbSyncStatus
import net.gorillagroove.db.DbSyncStatusQueries
import net.gorillagroove.db.DbTrack
import net.gorillagroove.db.DbTrackQueries
import net.gorillagroove.db.DbUser
import net.gorillagroove.db.DbUserFavorite
import net.gorillagroove.db.DbUserFavoriteQueries
import net.gorillagroove.db.DbUserPermission
import net.gorillagroove.db.DbUserPermissionQueries
import net.gorillagroove.db.DbUserQueries
import net.gorillagroove.db.DbUserSetting
import net.gorillagroove.db.DbUserSettingQueries
import net.gorillagroove.db.FindApiIdForLocalId
import net.gorillagroove.db.FindLocalIdForApiId
import net.gorillagroove.db.GGDatabase
import net.gorillagroove.db.GetCachedTrackSizeBytes
import net.gorillagroove.db.GetDistinctAlbums
import net.gorillagroove.db.GetEstimatedByteUsage
import net.gorillagroove.db.GetNeedingReviewTrackCountByQueue
import net.gorillagroove.db.GetTotalBytesRequiredForFullCache
import net.gorillagroove.playlist.RawPlaylistOwnershipType
import net.gorillagroove.review.RawReviewSourceType
import net.gorillagroove.sync.RawOfflineAvailabilityType
import net.gorillagroove.sync.RawUserFavoriteType
import net.gorillagroove.user.permission.RawUserPermissionType

internal val KClass<GGDatabase>.schema: SqlDriver.Schema
  get() = GGDatabaseImpl.Schema

internal fun KClass<GGDatabase>.newInstance(
  driver: SqlDriver,
  DbFailedListenAdapter: DbFailedListen.Adapter,
  DbLogLineAdapter: DbLogLine.Adapter,
  DbPlaylistAdapter: DbPlaylist.Adapter,
  DbPlaylistTrackAdapter: DbPlaylistTrack.Adapter,
  DbPlaylistUserAdapter: DbPlaylistUser.Adapter,
  DbReviewSourceAdapter: DbReviewSource.Adapter,
  DbSyncStatusAdapter: DbSyncStatus.Adapter,
  DbTrackAdapter: DbTrack.Adapter,
  DbUserAdapter: DbUser.Adapter,
  DbUserFavoriteAdapter: DbUserFavorite.Adapter,
  DbUserPermissionAdapter: DbUserPermission.Adapter,
  DbUserSettingAdapter: DbUserSetting.Adapter
): GGDatabase = GGDatabaseImpl(driver, DbFailedListenAdapter, DbLogLineAdapter, DbPlaylistAdapter,
    DbPlaylistTrackAdapter, DbPlaylistUserAdapter, DbReviewSourceAdapter, DbSyncStatusAdapter,
    DbTrackAdapter, DbUserAdapter, DbUserFavoriteAdapter, DbUserPermissionAdapter,
    DbUserSettingAdapter)

private class GGDatabaseImpl(
  driver: SqlDriver,
  internal val DbFailedListenAdapter: DbFailedListen.Adapter,
  internal val DbLogLineAdapter: DbLogLine.Adapter,
  internal val DbPlaylistAdapter: DbPlaylist.Adapter,
  internal val DbPlaylistTrackAdapter: DbPlaylistTrack.Adapter,
  internal val DbPlaylistUserAdapter: DbPlaylistUser.Adapter,
  internal val DbReviewSourceAdapter: DbReviewSource.Adapter,
  internal val DbSyncStatusAdapter: DbSyncStatus.Adapter,
  internal val DbTrackAdapter: DbTrack.Adapter,
  internal val DbUserAdapter: DbUser.Adapter,
  internal val DbUserFavoriteAdapter: DbUserFavorite.Adapter,
  internal val DbUserPermissionAdapter: DbUserPermission.Adapter,
  internal val DbUserSettingAdapter: DbUserSetting.Adapter
) : TransacterImpl(driver), GGDatabase {
  public override val dbFailedListenQueries: DbFailedListenQueriesImpl =
      DbFailedListenQueriesImpl(this, driver)

  public override val dbLogLineQueries: DbLogLineQueriesImpl = DbLogLineQueriesImpl(this, driver)

  public override val dbPlaylistQueries: DbPlaylistQueriesImpl = DbPlaylistQueriesImpl(this, driver)

  public override val dbPlaylistTrackQueries: DbPlaylistTrackQueriesImpl =
      DbPlaylistTrackQueriesImpl(this, driver)

  public override val dbPlaylistUserQueries: DbPlaylistUserQueriesImpl =
      DbPlaylistUserQueriesImpl(this, driver)

  public override val dbReviewSourceQueries: DbReviewSourceQueriesImpl =
      DbReviewSourceQueriesImpl(this, driver)

  public override val dbSyncStatusQueries: DbSyncStatusQueriesImpl = DbSyncStatusQueriesImpl(this,
      driver)

  public override val dbTrackQueries: DbTrackQueriesImpl = DbTrackQueriesImpl(this, driver)

  public override val dbUserQueries: DbUserQueriesImpl = DbUserQueriesImpl(this, driver)

  public override val dbUserFavoriteQueries: DbUserFavoriteQueriesImpl =
      DbUserFavoriteQueriesImpl(this, driver)

  public override val dbUserPermissionQueries: DbUserPermissionQueriesImpl =
      DbUserPermissionQueriesImpl(this, driver)

  public override val dbUserSettingQueries: DbUserSettingQueriesImpl =
      DbUserSettingQueriesImpl(this, driver)

  public object Schema : SqlDriver.Schema {
    public override val version: Int
      get() = 3

    public override fun create(driver: SqlDriver): Unit {
      driver.execute(null, """
          |CREATE TABLE DbPlaylistUser (
          |	id INTEGER NOT NULL PRIMARY KEY,
          |	playlistId INTEGER NOT NULL REFERENCES DbPlaylist(id),
          |	userId INTEGER NOT NULL REFERENCES DbUser(id),
          |	ownershipType TEXT NOT NULL,
          |	createdAt INTEGER NOT NULL,
          |	updatedAt INTEGER NOT NULL
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE DbFailedListen (
          |	id INTEGER NOT NULL PRIMARY KEY,
          |	trackId INTEGER NOT NULL,
          |	timeListenedAt INTEGER NOT NULL,
          |	ianaTimezone TEXT NOT NULL,
          |	latitude REAL,
          |	longitude REAL
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE DbLogLine (
          |	id INTEGER NOT NULL PRIMARY KEY,
          |	timestamp INTEGER NOT NULL,
          |	tag TEXT NOT NULL,
          |	severity INTEGER NOT NULL,
          |	message TEXT NOT NULL
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE DbReviewSource (
          |	id INTEGER NOT NULL PRIMARY KEY,
          |	sourceType TEXT NOT NULL,
          |	displayName TEXT NOT NULL,
          |	offlineAvailability TEXT NOT NULL,
          |	updatedAt INTEGER NOT NULL,
          |	sourceUserId INTEGER REFERENCES DbUser(id)
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE DbPlaylist (
          |	id INTEGER NOT NULL PRIMARY KEY,
          |	name TEXT NOT NULL,
          |	createdAt INTEGER NOT NULL,
          |	updatedAt INTEGER NOT NULL
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE DbUserSetting (
          |	id INTEGER NOT NULL PRIMARY KEY,
          |	apiId INTEGER UNIQUE,
          |	key TEXT NOT NULL UNIQUE,
          |	value TEXT NOT NULL,
          |	synchronized INTEGER NOT NULL,
          |	updatedAt INTEGER NOT NULL
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE DbUser (
          |	id INTEGER NOT NULL PRIMARY KEY,
          |	name TEXT NOT NULL,
          |	lastActivity INTEGER,
          |	createdAt INTEGER NOT NULL
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE DbSyncStatus (
          |	id INTEGER NOT NULL PRIMARY KEY,
          |	syncType TEXT NOT NULL,
          |	lastSuccessfulFullSync INTEGER NOT NULL,
          |	lastSyncedDown INTEGER NOT NULL
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE DbTrack (
          |	id INTEGER NOT NULL PRIMARY KEY,
          |	apiId INTEGER UNIQUE,
          |	userId INTEGER NOT NULL REFERENCES DbUser(id),
          |
          |	-- Uncomment if I fix the server issue in TrackSyncStrategy
          |-- 	reviewSourceId INTEGER AS ReviewSourceId REFERENCES DbReviewSource(id) ON DELETE SET NULL,
          |	reviewSourceId INTEGER,
          |
          |	name TEXT NOT NULL,
          |	artist TEXT NOT NULL,
          |	featuring TEXT NOT NULL,
          |	album TEXT NOT NULL,
          |	trackNumber INTEGER,
          |	length INTEGER NOT NULL,
          |	releaseYear INTEGER,
          |	genre TEXT NOT NULL,
          |	playCount INTEGER NOT NULL,
          |	isPrivate INTEGER NOT NULL,
          |	isHidden INTEGER NOT NULL,
          |	addedToLibrary INTEGER,
          |	lastPlayed INTEGER,
          |	inReview INTEGER NOT NULL,
          |	note TEXT NOT NULL,
          |	audioCachedAt INTEGER,
          |	artCachedAt INTEGER,
          |	thumbnailCachedAt INTEGER,
          |	offlineAvailability TEXT DEFAULT 'NORMAL' NOT NULL,
          |	filesizeAudioOgg INTEGER DEFAULT 0 NOT NULL,
          |	filesizeAudioMp3 INTEGER DEFAULT 0 NOT NULL,
          |	filesizeArtPng INTEGER DEFAULT 0 NOT NULL,
          |	filesizeThumbnailPng INTEGER DEFAULT 0 NOT NULL,
          |	startedOnDevice INTEGER,
          |	lastReviewed INTEGER,
          |	recommendedBy INTEGER REFERENCES DbUser(id)
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE DbUserPermission (
          |	id INTEGER NOT NULL PRIMARY KEY,
          |	type TEXT NOT NULL UNIQUE,
          |	createdAt INTEGER NOT NULL
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE DbUserFavorite (
          |	id INTEGER NOT NULL PRIMARY KEY,
          |	type TEXT NOT NULL,
          |	value TEXT NOT NULL,
          |	createdAt INTEGER NOT NULL,
          |	updatedAt INTEGER NOT NULL
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE DbPlaylistTrack (
          |	id INTEGER NOT NULL PRIMARY KEY,
          |	playlistId INTEGER NOT NULL REFERENCES DbPlaylist(id),
          |	trackId INTEGER NOT NULL REFERENCES DbTrack(id),
          |	sortOrder INTEGER NOT NULL,
          |	createdAt INTEGER NOT NULL
          |)
          """.trimMargin(), 0)
    }

    public override fun migrate(
      driver: SqlDriver,
      oldVersion: Int,
      newVersion: Int
    ): Unit {
      if (oldVersion <= 1 && newVersion > 1) {
        driver.execute(null, """
            |CREATE TABLE DbUserPermission (
            |	id INTEGER NOT NULL PRIMARY KEY,
            |	type TEXT NOT NULL UNIQUE,
            |	createdAt INTEGER NOT NULL
            |)
            """.trimMargin(), 0)
      }
      if (oldVersion <= 2 && newVersion > 2) {
        driver.execute(null, """
            |ALTER TABLE DbTrack
            |  ADD `recommendedBy` INTEGER REFERENCES DbUser(id)
            """.trimMargin(), 0)
        driver.execute(null, """
            |ALTER TABLE DbReviewSource
            |  ADD `sourceUserId` INTEGER REFERENCES DbUser(id)
            """.trimMargin(), 0)
      }
    }
  }
}

private class DbPlaylistUserQueriesImpl(
  private val database: GGDatabaseImpl,
  private val driver: SqlDriver
) : TransacterImpl(driver), DbPlaylistUserQueries {
  internal val findAll: MutableList<Query<*>> = copyOnWriteList()

  internal val findById: MutableList<Query<*>> = copyOnWriteList()

  internal val findByUser: MutableList<Query<*>> = copyOnWriteList()

  internal val findByUserAndPlaylist: MutableList<Query<*>> = copyOnWriteList()

  internal val findUsersOnPlaylist: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> findAll(mapper: (
    id: PlaylistUserId,
    playlistId: PlaylistId,
    userId: UserId,
    ownershipType: RawPlaylistOwnershipType,
    createdAt: Instant,
    updatedAt: Instant
  ) -> T): Query<T> = Query(2086209830, findAll, driver, "DbPlaylistUser.sq", "findAll", """
  |SELECT *
  |FROM DbPlaylistUser
  """.trimMargin()) { cursor ->
    mapper(
      database.DbPlaylistUserAdapter.idAdapter.decode(cursor.getLong(0)!!),
      database.DbPlaylistUserAdapter.playlistIdAdapter.decode(cursor.getLong(1)!!),
      database.DbPlaylistUserAdapter.userIdAdapter.decode(cursor.getLong(2)!!),
      database.DbPlaylistUserAdapter.ownershipTypeAdapter.decode(cursor.getString(3)!!),
      database.DbPlaylistUserAdapter.createdAtAdapter.decode(cursor.getLong(4)!!),
      database.DbPlaylistUserAdapter.updatedAtAdapter.decode(cursor.getLong(5)!!)
    )
  }

  public override fun findAll(): Query<DbPlaylistUser> = findAll { id, playlistId, userId,
      ownershipType, createdAt, updatedAt ->
    DbPlaylistUser(
      id,
      playlistId,
      userId,
      ownershipType,
      createdAt,
      updatedAt
    )
  }

  public override fun <T : Any> findById(id: PlaylistUserId, mapper: (
    id: PlaylistUserId,
    playlistId: PlaylistId,
    userId: UserId,
    ownershipType: RawPlaylistOwnershipType,
    createdAt: Instant,
    updatedAt: Instant
  ) -> T): Query<T> = FindByIdQuery(id) { cursor ->
    mapper(
      database.DbPlaylistUserAdapter.idAdapter.decode(cursor.getLong(0)!!),
      database.DbPlaylistUserAdapter.playlistIdAdapter.decode(cursor.getLong(1)!!),
      database.DbPlaylistUserAdapter.userIdAdapter.decode(cursor.getLong(2)!!),
      database.DbPlaylistUserAdapter.ownershipTypeAdapter.decode(cursor.getString(3)!!),
      database.DbPlaylistUserAdapter.createdAtAdapter.decode(cursor.getLong(4)!!),
      database.DbPlaylistUserAdapter.updatedAtAdapter.decode(cursor.getLong(5)!!)
    )
  }

  public override fun findById(id: PlaylistUserId): Query<DbPlaylistUser> = findById(id) { id_,
      playlistId, userId, ownershipType, createdAt, updatedAt ->
    DbPlaylistUser(
      id_,
      playlistId,
      userId,
      ownershipType,
      createdAt,
      updatedAt
    )
  }

  public override fun <T : Any> findByUser(userId: UserId, mapper: (
    id: PlaylistUserId,
    playlistId: PlaylistId,
    userId: UserId,
    ownershipType: RawPlaylistOwnershipType,
    createdAt: Instant,
    updatedAt: Instant
  ) -> T): Query<T> = FindByUserQuery(userId) { cursor ->
    mapper(
      database.DbPlaylistUserAdapter.idAdapter.decode(cursor.getLong(0)!!),
      database.DbPlaylistUserAdapter.playlistIdAdapter.decode(cursor.getLong(1)!!),
      database.DbPlaylistUserAdapter.userIdAdapter.decode(cursor.getLong(2)!!),
      database.DbPlaylistUserAdapter.ownershipTypeAdapter.decode(cursor.getString(3)!!),
      database.DbPlaylistUserAdapter.createdAtAdapter.decode(cursor.getLong(4)!!),
      database.DbPlaylistUserAdapter.updatedAtAdapter.decode(cursor.getLong(5)!!)
    )
  }

  public override fun findByUser(userId: UserId): Query<DbPlaylistUser> = findByUser(userId) { id,
      playlistId, userId_, ownershipType, createdAt, updatedAt ->
    DbPlaylistUser(
      id,
      playlistId,
      userId_,
      ownershipType,
      createdAt,
      updatedAt
    )
  }

  public override fun <T : Any> findByUserAndPlaylist(
    userId: UserId,
    playlistId: PlaylistId,
    mapper: (
      id: PlaylistUserId,
      playlistId: PlaylistId,
      userId: UserId,
      ownershipType: RawPlaylistOwnershipType,
      createdAt: Instant,
      updatedAt: Instant
    ) -> T
  ): Query<T> = FindByUserAndPlaylistQuery(userId, playlistId) { cursor ->
    mapper(
      database.DbPlaylistUserAdapter.idAdapter.decode(cursor.getLong(0)!!),
      database.DbPlaylistUserAdapter.playlistIdAdapter.decode(cursor.getLong(1)!!),
      database.DbPlaylistUserAdapter.userIdAdapter.decode(cursor.getLong(2)!!),
      database.DbPlaylistUserAdapter.ownershipTypeAdapter.decode(cursor.getString(3)!!),
      database.DbPlaylistUserAdapter.createdAtAdapter.decode(cursor.getLong(4)!!),
      database.DbPlaylistUserAdapter.updatedAtAdapter.decode(cursor.getLong(5)!!)
    )
  }

  public override fun findByUserAndPlaylist(userId: UserId, playlistId: PlaylistId):
      Query<DbPlaylistUser> = findByUserAndPlaylist(userId, playlistId) { id, playlistId_, userId_,
      ownershipType, createdAt, updatedAt ->
    DbPlaylistUser(
      id,
      playlistId_,
      userId_,
      ownershipType,
      createdAt,
      updatedAt
    )
  }

  public override fun <T : Any> findUsersOnPlaylist(playlistId: PlaylistId, mapper: (
    id: PlaylistUserId,
    playlistId: PlaylistId,
    userId: UserId,
    ownershipType: RawPlaylistOwnershipType,
    createdAt: Instant,
    updatedAt: Instant
  ) -> T): Query<T> = FindUsersOnPlaylistQuery(playlistId) { cursor ->
    mapper(
      database.DbPlaylistUserAdapter.idAdapter.decode(cursor.getLong(0)!!),
      database.DbPlaylistUserAdapter.playlistIdAdapter.decode(cursor.getLong(1)!!),
      database.DbPlaylistUserAdapter.userIdAdapter.decode(cursor.getLong(2)!!),
      database.DbPlaylistUserAdapter.ownershipTypeAdapter.decode(cursor.getString(3)!!),
      database.DbPlaylistUserAdapter.createdAtAdapter.decode(cursor.getLong(4)!!),
      database.DbPlaylistUserAdapter.updatedAtAdapter.decode(cursor.getLong(5)!!)
    )
  }

  public override fun findUsersOnPlaylist(playlistId: PlaylistId): Query<DbPlaylistUser> =
      findUsersOnPlaylist(playlistId) { id, playlistId_, userId, ownershipType, createdAt,
      updatedAt ->
    DbPlaylistUser(
      id,
      playlistId_,
      userId,
      ownershipType,
      createdAt,
      updatedAt
    )
  }

  public override fun upsert(DbPlaylistUser: DbPlaylistUser): Unit {
    driver.execute(-1436312207, """
    |INSERT OR REPLACE INTO DbPlaylistUser (id, playlistId, userId, ownershipType, createdAt, updatedAt)
    |VALUES (?, ?, ?, ?, ?, ?)
    """.trimMargin(), 6) {
      bindLong(1, database.DbPlaylistUserAdapter.idAdapter.encode(DbPlaylistUser.id))
      bindLong(2,
          database.DbPlaylistUserAdapter.playlistIdAdapter.encode(DbPlaylistUser.playlistId))
      bindLong(3, database.DbPlaylistUserAdapter.userIdAdapter.encode(DbPlaylistUser.userId))
      bindString(4,
          database.DbPlaylistUserAdapter.ownershipTypeAdapter.encode(DbPlaylistUser.ownershipType))
      bindLong(5, database.DbPlaylistUserAdapter.createdAtAdapter.encode(DbPlaylistUser.createdAt))
      bindLong(6, database.DbPlaylistUserAdapter.updatedAtAdapter.encode(DbPlaylistUser.updatedAt))
    }
    notifyQueries(-1436312207, {database.dbPlaylistUserQueries.findById +
        database.dbPlaylistUserQueries.findUsersOnPlaylist +
        database.dbPlaylistUserQueries.findByUserAndPlaylist +
        database.dbPlaylistUserQueries.findAll + database.dbPlaylistUserQueries.findByUser})
  }

  public override fun delete(id: PlaylistUserId): Unit {
    driver.execute(-1933374995, """DELETE FROM DbPlaylistUser WHERE id = ?""", 1) {
      bindLong(1, database.DbPlaylistUserAdapter.idAdapter.encode(id))
    }
    notifyQueries(-1933374995, {database.dbPlaylistUserQueries.findById +
        database.dbPlaylistUserQueries.findUsersOnPlaylist +
        database.dbPlaylistUserQueries.findByUserAndPlaylist +
        database.dbPlaylistUserQueries.findAll + database.dbPlaylistUserQueries.findByUser})
  }

  public override fun deleteAll(): Unit {
    driver.execute(-1662970764, """DELETE FROM DbPlaylistUser""", 0)
    notifyQueries(-1662970764, {database.dbPlaylistUserQueries.findById +
        database.dbPlaylistUserQueries.findUsersOnPlaylist +
        database.dbPlaylistUserQueries.findByUserAndPlaylist +
        database.dbPlaylistUserQueries.findAll + database.dbPlaylistUserQueries.findByUser})
  }

  public override fun deleteFromPlaylist(playlistId: PlaylistId): Unit {
    driver.execute(-737044951, """DELETE FROM DbPlaylistUser WHERE playlistId = ?""", 1) {
      bindLong(1, database.DbPlaylistUserAdapter.playlistIdAdapter.encode(playlistId))
    }
    notifyQueries(-737044951, {database.dbPlaylistUserQueries.findById +
        database.dbPlaylistUserQueries.findUsersOnPlaylist +
        database.dbPlaylistUserQueries.findByUserAndPlaylist +
        database.dbPlaylistUserQueries.findAll + database.dbPlaylistUserQueries.findByUser})
  }

  private inner class FindByIdQuery<out T : Any>(
    public val id: PlaylistUserId,
    mapper: (SqlCursor) -> T
  ) : Query<T>(findById, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(248036589, """
    |SELECT *
    |FROM DbPlaylistUser
    |WHERE id = ?
    """.trimMargin(), 1) {
      bindLong(1, database.DbPlaylistUserAdapter.idAdapter.encode(id))
    }

    public override fun toString(): String = "DbPlaylistUser.sq:findById"
  }

  private inner class FindByUserQuery<out T : Any>(
    public val userId: UserId,
    mapper: (SqlCursor) -> T
  ) : Query<T>(findByUser, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(2140335901, """
    |SELECT *
    |FROM DbPlaylistUser
    |WHERE userId = ?
    """.trimMargin(), 1) {
      bindLong(1, database.DbPlaylistUserAdapter.userIdAdapter.encode(userId))
    }

    public override fun toString(): String = "DbPlaylistUser.sq:findByUser"
  }

  private inner class FindByUserAndPlaylistQuery<out T : Any>(
    public val userId: UserId,
    public val playlistId: PlaylistId,
    mapper: (SqlCursor) -> T
  ) : Query<T>(findByUserAndPlaylist, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(1307422060, """
    |SELECT *
    |FROM DbPlaylistUser
    |WHERE userId = ?
    |AND playlistId = ?
    """.trimMargin(), 2) {
      bindLong(1, database.DbPlaylistUserAdapter.userIdAdapter.encode(userId))
      bindLong(2, database.DbPlaylistUserAdapter.playlistIdAdapter.encode(playlistId))
    }

    public override fun toString(): String = "DbPlaylistUser.sq:findByUserAndPlaylist"
  }

  private inner class FindUsersOnPlaylistQuery<out T : Any>(
    public val playlistId: PlaylistId,
    mapper: (SqlCursor) -> T
  ) : Query<T>(findUsersOnPlaylist, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(1240503550, """
    |SELECT *
    |FROM DbPlaylistUser
    |WHERE playlistId = ?
    """.trimMargin(), 1) {
      bindLong(1, database.DbPlaylistUserAdapter.playlistIdAdapter.encode(playlistId))
    }

    public override fun toString(): String = "DbPlaylistUser.sq:findUsersOnPlaylist"
  }
}

private class DbFailedListenQueriesImpl(
  private val database: GGDatabaseImpl,
  private val driver: SqlDriver
) : TransacterImpl(driver), DbFailedListenQueries {
  internal val findAll: MutableList<Query<*>> = copyOnWriteList()

  internal val findById: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> findAll(mapper: (
    id: FailedListenId,
    trackId: TrackApiId,
    timeListenedAt: Instant,
    ianaTimezone: String,
    latitude: Double?,
    longitude: Double?
  ) -> T): Query<T> = Query(1231030047, findAll, driver, "DbFailedListen.sq", "findAll", """
  |SELECT *
  |FROM DbFailedListen
  """.trimMargin()) { cursor ->
    mapper(
      database.DbFailedListenAdapter.idAdapter.decode(cursor.getLong(0)!!),
      database.DbFailedListenAdapter.trackIdAdapter.decode(cursor.getLong(1)!!),
      database.DbFailedListenAdapter.timeListenedAtAdapter.decode(cursor.getLong(2)!!),
      cursor.getString(3)!!,
      cursor.getDouble(4),
      cursor.getDouble(5)
    )
  }

  public override fun findAll(): Query<DbFailedListen> = findAll { id, trackId, timeListenedAt,
      ianaTimezone, latitude, longitude ->
    DbFailedListen(
      id,
      trackId,
      timeListenedAt,
      ianaTimezone,
      latitude,
      longitude
    )
  }

  public override fun <T : Any> findById(id: FailedListenId, mapper: (
    id: FailedListenId,
    trackId: TrackApiId,
    timeListenedAt: Instant,
    ianaTimezone: String,
    latitude: Double?,
    longitude: Double?
  ) -> T): Query<T> = FindByIdQuery(id) { cursor ->
    mapper(
      database.DbFailedListenAdapter.idAdapter.decode(cursor.getLong(0)!!),
      database.DbFailedListenAdapter.trackIdAdapter.decode(cursor.getLong(1)!!),
      database.DbFailedListenAdapter.timeListenedAtAdapter.decode(cursor.getLong(2)!!),
      cursor.getString(3)!!,
      cursor.getDouble(4),
      cursor.getDouble(5)
    )
  }

  public override fun findById(id: FailedListenId): Query<DbFailedListen> = findById(id) { id_,
      trackId, timeListenedAt, ianaTimezone, latitude, longitude ->
    DbFailedListen(
      id_,
      trackId,
      timeListenedAt,
      ianaTimezone,
      latitude,
      longitude
    )
  }

  public override fun insert(DbFailedListen: DbFailedListen): Unit {
    driver.execute(2070029794, """
    |INSERT INTO DbFailedListen(trackId, timeListenedAt, ianaTimezone, latitude, longitude)
    |VALUES (?, ?, ?, ?, ?)
    """.trimMargin(), 5) {
      bindLong(1, database.DbFailedListenAdapter.trackIdAdapter.encode(DbFailedListen.trackId))
      bindLong(2,
          database.DbFailedListenAdapter.timeListenedAtAdapter.encode(DbFailedListen.timeListenedAt))
      bindString(3, DbFailedListen.ianaTimezone)
      bindDouble(4, DbFailedListen.latitude)
      bindDouble(5, DbFailedListen.longitude)
    }
    notifyQueries(2070029794, {database.dbFailedListenQueries.findById +
        database.dbFailedListenQueries.findAll})
  }

  public override fun delete(id: FailedListenId): Unit {
    driver.execute(1918363860, """DELETE FROM DbFailedListen WHERE id = ?""", 1) {
      bindLong(1, database.DbFailedListenAdapter.idAdapter.encode(id))
    }
    notifyQueries(1918363860, {database.dbFailedListenQueries.findById +
        database.dbFailedListenQueries.findAll})
  }

  public override fun deleteAll(): Unit {
    driver.execute(1142978605, """DELETE FROM DbFailedListen""", 0)
    notifyQueries(1142978605, {database.dbFailedListenQueries.findById +
        database.dbFailedListenQueries.findAll})
  }

  private inner class FindByIdQuery<out T : Any>(
    public val id: FailedListenId,
    mapper: (SqlCursor) -> T
  ) : Query<T>(findById, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-492732908, """
    |SELECT *
    |FROM DbFailedListen
    |WHERE id = ?
    """.trimMargin(), 1) {
      bindLong(1, database.DbFailedListenAdapter.idAdapter.encode(id))
    }

    public override fun toString(): String = "DbFailedListen.sq:findById"
  }
}

private class DbLogLineQueriesImpl(
  private val database: GGDatabaseImpl,
  private val driver: SqlDriver
) : TransacterImpl(driver), DbLogLineQueries {
  internal val findAll: MutableList<Query<*>> = copyOnWriteList()

  internal val getEstimatedByteUsage: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> findAll(mapper: (
    id: LogLineId,
    timestamp: Instant,
    tag: String,
    severity: Long,
    message: String
  ) -> T): Query<T> = Query(123284857, findAll, driver, "DbLogLine.sq", "findAll", """
  |SELECT *
  |FROM DbLogLine
  """.trimMargin()) { cursor ->
    mapper(
      database.DbLogLineAdapter.idAdapter.decode(cursor.getLong(0)!!),
      database.DbLogLineAdapter.timestampAdapter.decode(cursor.getLong(1)!!),
      cursor.getString(2)!!,
      cursor.getLong(3)!!,
      cursor.getString(4)!!
    )
  }

  public override fun findAll(): Query<DbLogLine> = findAll { id, timestamp, tag, severity,
      message ->
    DbLogLine(
      id,
      timestamp,
      tag,
      severity,
      message
    )
  }

  public override fun <T : Any> getEstimatedByteUsage(mapper: (expr: Double?) -> T): Query<T> =
      Query(598465828, getEstimatedByteUsage, driver, "DbLogLine.sq", "getEstimatedByteUsage", """
  |SELECT sum(length(message) * 1.1)
  |  + sum(length(tag) * 1.1)
  |  + (count(id) * 4)
  |  + (count(timestamp) * 6)
  |  + (count(severity))
  |FROM DbLogLine
  """.trimMargin()) { cursor ->
    mapper(
      cursor.getDouble(0)
    )
  }

  public override fun getEstimatedByteUsage(): Query<GetEstimatedByteUsage> =
      getEstimatedByteUsage { expr ->
    GetEstimatedByteUsage(
      expr
    )
  }

  public override fun insert(DbLogLine: DbLogLine): Unit {
    driver.execute(-321008568, """
    |INSERT INTO DbLogLine(timestamp, tag, severity, message)
    |VALUES (?, ?, ?, ?)
    """.trimMargin(), 4) {
      bindLong(1, database.DbLogLineAdapter.timestampAdapter.encode(DbLogLine.timestamp))
      bindString(2, DbLogLine.tag)
      bindLong(3, DbLogLine.severity)
      bindString(4, DbLogLine.message)
    }
    notifyQueries(-321008568, {database.dbLogLineQueries.findAll +
        database.dbLogLineQueries.getEstimatedByteUsage})
  }

  public override fun deleteAll(): Unit {
    driver.execute(1751740423, """DELETE FROM DbLogLine""", 0)
    notifyQueries(1751740423, {database.dbLogLineQueries.findAll +
        database.dbLogLineQueries.getEstimatedByteUsage})
  }

  public override fun deleteOldestNLogs(`value`: Long): Unit {
    driver.execute(-103045916, """
    |DELETE FROM DbLogLine
    |WHERE id IN (
    |  SELECT l2.id FROM DbLogLine l2
    |  ORDER BY l2.id ASC
    |  LIMIT ?
    |)
    """.trimMargin(), 1) {
      bindLong(1, value)
    }
    notifyQueries(-103045916, {database.dbLogLineQueries.findAll +
        database.dbLogLineQueries.getEstimatedByteUsage})
  }

  public override fun vacuum(): Unit {
    driver.execute(38703428, """VACUUM""", 0)
  }
}

private class DbReviewSourceQueriesImpl(
  private val database: GGDatabaseImpl,
  private val driver: SqlDriver
) : TransacterImpl(driver), DbReviewSourceQueries {
  internal val findAll: MutableList<Query<*>> = copyOnWriteList()

  internal val findById: MutableList<Query<*>> = copyOnWriteList()

  internal val getNeedingReviewTrackCountByQueue: MutableList<Query<*>> = copyOnWriteList()

  internal val getEditableSources: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> findAll(mapper: (
    id: ReviewSourceId,
    sourceType: RawReviewSourceType,
    displayName: String,
    offlineAvailability: RawOfflineAvailabilityType,
    updatedAt: Instant,
    sourceUserId: UserId?
  ) -> T): Query<T> = Query(-1388083568, findAll, driver, "DbReviewSource.sq", "findAll", """
  |SELECT *
  |FROM DbReviewSource
  """.trimMargin()) { cursor ->
    mapper(
      database.DbReviewSourceAdapter.idAdapter.decode(cursor.getLong(0)!!),
      database.DbReviewSourceAdapter.sourceTypeAdapter.decode(cursor.getString(1)!!),
      cursor.getString(2)!!,
      database.DbReviewSourceAdapter.offlineAvailabilityAdapter.decode(cursor.getString(3)!!),
      database.DbReviewSourceAdapter.updatedAtAdapter.decode(cursor.getLong(4)!!),
      cursor.getLong(5)?.let { database.DbReviewSourceAdapter.sourceUserIdAdapter.decode(it) }
    )
  }

  public override fun findAll(): Query<DbReviewSource> = findAll { id, sourceType, displayName,
      offlineAvailability, updatedAt, sourceUserId ->
    DbReviewSource(
      id,
      sourceType,
      displayName,
      offlineAvailability,
      updatedAt,
      sourceUserId
    )
  }

  public override fun <T : Any> findById(id: ReviewSourceId, mapper: (
    id: ReviewSourceId,
    sourceType: RawReviewSourceType,
    displayName: String,
    offlineAvailability: RawOfflineAvailabilityType,
    updatedAt: Instant,
    sourceUserId: UserId?
  ) -> T): Query<T> = FindByIdQuery(id) { cursor ->
    mapper(
      database.DbReviewSourceAdapter.idAdapter.decode(cursor.getLong(0)!!),
      database.DbReviewSourceAdapter.sourceTypeAdapter.decode(cursor.getString(1)!!),
      cursor.getString(2)!!,
      database.DbReviewSourceAdapter.offlineAvailabilityAdapter.decode(cursor.getString(3)!!),
      database.DbReviewSourceAdapter.updatedAtAdapter.decode(cursor.getLong(4)!!),
      cursor.getLong(5)?.let { database.DbReviewSourceAdapter.sourceUserIdAdapter.decode(it) }
    )
  }

  public override fun findById(id: ReviewSourceId): Query<DbReviewSource> = findById(id) { id_,
      sourceType, displayName, offlineAvailability, updatedAt, sourceUserId ->
    DbReviewSource(
      id_,
      sourceType,
      displayName,
      offlineAvailability,
      updatedAt,
      sourceUserId
    )
  }

  public override fun <T : Any> getNeedingReviewTrackCountByQueue(mapper: (id: ReviewSourceId,
      count: Long) -> T): Query<T> = Query(1261411984, getNeedingReviewTrackCountByQueue, driver,
      "DbReviewSource.sq", "getNeedingReviewTrackCountByQueue", """
  |SELECT rs.id AS id, count(*) AS count
  |FROM DbReviewSource rs
  |LEFT JOIN DbTrack t
  |  ON t.reviewSourceId = rs.id
  |WHERE t.inReview = TRUE
  |GROUP BY rs.id
  """.trimMargin()) { cursor ->
    mapper(
      database.DbReviewSourceAdapter.idAdapter.decode(cursor.getLong(0)!!),
      cursor.getLong(1)!!
    )
  }

  public override fun getNeedingReviewTrackCountByQueue(): Query<GetNeedingReviewTrackCountByQueue>
      = getNeedingReviewTrackCountByQueue { id, count ->
    GetNeedingReviewTrackCountByQueue(
      id,
      count
    )
  }

  public override fun <T : Any> getEditableSources(mapper: (
    id: ReviewSourceId,
    sourceType: RawReviewSourceType,
    displayName: String,
    offlineAvailability: RawOfflineAvailabilityType,
    updatedAt: Instant,
    sourceUserId: UserId?
  ) -> T): Query<T> = Query(1789127702, getEditableSources, driver, "DbReviewSource.sq",
      "getEditableSources", """
  |SELECT *
  |FROM DbReviewSource rs
  |WHERE rs.sourceType <> 'USER_RECOMMEND'
  |ORDER BY rs.displayName COLLATE NOCASE ASC
  """.trimMargin()) { cursor ->
    mapper(
      database.DbReviewSourceAdapter.idAdapter.decode(cursor.getLong(0)!!),
      database.DbReviewSourceAdapter.sourceTypeAdapter.decode(cursor.getString(1)!!),
      cursor.getString(2)!!,
      database.DbReviewSourceAdapter.offlineAvailabilityAdapter.decode(cursor.getString(3)!!),
      database.DbReviewSourceAdapter.updatedAtAdapter.decode(cursor.getLong(4)!!),
      cursor.getLong(5)?.let { database.DbReviewSourceAdapter.sourceUserIdAdapter.decode(it) }
    )
  }

  public override fun getEditableSources(): Query<DbReviewSource> = getEditableSources { id,
      sourceType, displayName, offlineAvailability, updatedAt, sourceUserId ->
    DbReviewSource(
      id,
      sourceType,
      displayName,
      offlineAvailability,
      updatedAt,
      sourceUserId
    )
  }

  public override fun upsert(DbReviewSource: DbReviewSource): Unit {
    driver.execute(1222560455, """
    |INSERT OR REPLACE INTO DbReviewSource (id, sourceType, displayName, offlineAvailability, updatedAt, sourceUserId)
    |VALUES (?, ?, ?, ?, ?, ?)
    """.trimMargin(), 6) {
      bindLong(1, database.DbReviewSourceAdapter.idAdapter.encode(DbReviewSource.id))
      bindString(2,
          database.DbReviewSourceAdapter.sourceTypeAdapter.encode(DbReviewSource.sourceType))
      bindString(3, DbReviewSource.displayName)
      bindString(4,
          database.DbReviewSourceAdapter.offlineAvailabilityAdapter.encode(DbReviewSource.offlineAvailability))
      bindLong(5, database.DbReviewSourceAdapter.updatedAtAdapter.encode(DbReviewSource.updatedAt))
      bindLong(6, DbReviewSource.sourceUserId?.let {
          database.DbReviewSourceAdapter.sourceUserIdAdapter.encode(it) })
    }
    notifyQueries(1222560455, {database.dbReviewSourceQueries.findAll +
        database.dbReviewSourceQueries.findById +
        database.dbReviewSourceQueries.getNeedingReviewTrackCountByQueue +
        database.dbReviewSourceQueries.getEditableSources})
  }

  public override fun delete(id: ReviewSourceId): Unit {
    driver.execute(725497667, """DELETE FROM DbReviewSource WHERE id = ?""", 1) {
      bindLong(1, database.DbReviewSourceAdapter.idAdapter.encode(id))
    }
    notifyQueries(725497667, {database.dbReviewSourceQueries.findAll +
        database.dbReviewSourceQueries.findById +
        database.dbReviewSourceQueries.getNeedingReviewTrackCountByQueue +
        database.dbReviewSourceQueries.getEditableSources})
  }

  public override fun deleteAll(): Unit {
    driver.execute(1025630046, """DELETE FROM DbReviewSource""", 0)
    notifyQueries(1025630046, {database.dbReviewSourceQueries.findAll +
        database.dbReviewSourceQueries.findById +
        database.dbReviewSourceQueries.getNeedingReviewTrackCountByQueue +
        database.dbReviewSourceQueries.getEditableSources})
  }

  private inner class FindByIdQuery<out T : Any>(
    public val id: ReviewSourceId,
    mapper: (SqlCursor) -> T
  ) : Query<T>(findById, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-80876349, """
    |SELECT *
    |FROM DbReviewSource
    |WHERE id = ?
    """.trimMargin(), 1) {
      bindLong(1, database.DbReviewSourceAdapter.idAdapter.encode(id))
    }

    public override fun toString(): String = "DbReviewSource.sq:findById"
  }
}

private class DbPlaylistQueriesImpl(
  private val database: GGDatabaseImpl,
  private val driver: SqlDriver
) : TransacterImpl(driver), DbPlaylistQueries {
  internal val findAll: MutableList<Query<*>> = copyOnWriteList()

  internal val findById: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> findAll(mapper: (
    id: PlaylistId,
    name: String,
    createdAt: Instant,
    updatedAt: Instant
  ) -> T): Query<T> = Query(2041851249, findAll, driver, "DbPlaylist.sq", "findAll", """
  |SELECT *
  |FROM DbPlaylist
  """.trimMargin()) { cursor ->
    mapper(
      database.DbPlaylistAdapter.idAdapter.decode(cursor.getLong(0)!!),
      cursor.getString(1)!!,
      database.DbPlaylistAdapter.createdAtAdapter.decode(cursor.getLong(2)!!),
      database.DbPlaylistAdapter.updatedAtAdapter.decode(cursor.getLong(3)!!)
    )
  }

  public override fun findAll(): Query<DbPlaylist> = findAll { id, name, createdAt, updatedAt ->
    DbPlaylist(
      id,
      name,
      createdAt,
      updatedAt
    )
  }

  public override fun <T : Any> findById(id: PlaylistId, mapper: (
    id: PlaylistId,
    name: String,
    createdAt: Instant,
    updatedAt: Instant
  ) -> T): Query<T> = FindByIdQuery(id) { cursor ->
    mapper(
      database.DbPlaylistAdapter.idAdapter.decode(cursor.getLong(0)!!),
      cursor.getString(1)!!,
      database.DbPlaylistAdapter.createdAtAdapter.decode(cursor.getLong(2)!!),
      database.DbPlaylistAdapter.updatedAtAdapter.decode(cursor.getLong(3)!!)
    )
  }

  public override fun findById(id: PlaylistId): Query<DbPlaylist> = findById(id) { id_, name,
      createdAt, updatedAt ->
    DbPlaylist(
      id_,
      name,
      createdAt,
      updatedAt
    )
  }

  public override fun upsert(DbPlaylist: DbPlaylist): Unit {
    driver.execute(1748845510, """
    |INSERT OR REPLACE INTO DbPlaylist (id, name, createdAt, updatedAt)
    |VALUES (?, ?, ?, ?)
    """.trimMargin(), 4) {
      bindLong(1, database.DbPlaylistAdapter.idAdapter.encode(DbPlaylist.id))
      bindString(2, DbPlaylist.name)
      bindLong(3, database.DbPlaylistAdapter.createdAtAdapter.encode(DbPlaylist.createdAt))
      bindLong(4, database.DbPlaylistAdapter.updatedAtAdapter.encode(DbPlaylist.updatedAt))
    }
    notifyQueries(1748845510, {database.dbPlaylistQueries.findById +
        database.dbPlaylistQueries.findAll})
  }

  public override fun delete(id: PlaylistId): Unit {
    driver.execute(1251782722, """DELETE FROM DbPlaylist WHERE id = ?""", 1) {
      bindLong(1, database.DbPlaylistAdapter.idAdapter.encode(id))
    }
    notifyQueries(1251782722, {database.dbPlaylistQueries.findById +
        database.dbPlaylistQueries.findAll})
  }

  public override fun deleteAll(): Unit {
    driver.execute(-1341894145, """DELETE FROM DbPlaylist""", 0)
    notifyQueries(-1341894145, {database.dbPlaylistQueries.findById +
        database.dbPlaylistQueries.findAll})
  }

  private inner class FindByIdQuery<out T : Any>(
    public val id: PlaylistId,
    mapper: (SqlCursor) -> T
  ) : Query<T>(findById, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-1127079422, """
    |SELECT *
    |FROM DbPlaylist
    |WHERE id = ?
    """.trimMargin(), 1) {
      bindLong(1, database.DbPlaylistAdapter.idAdapter.encode(id))
    }

    public override fun toString(): String = "DbPlaylist.sq:findById"
  }
}

private class DbUserSettingQueriesImpl(
  private val database: GGDatabaseImpl,
  private val driver: SqlDriver
) : TransacterImpl(driver), DbUserSettingQueries {
  internal val findAll: MutableList<Query<*>> = copyOnWriteList()

  internal val findById: MutableList<Query<*>> = copyOnWriteList()

  internal val findByApiId: MutableList<Query<*>> = copyOnWriteList()

  internal val findByKey: MutableList<Query<*>> = copyOnWriteList()

  internal val getAllUnsynchronized: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> findAll(mapper: (
    id: UserSettingId,
    apiId: UserSettingApiId?,
    key: String,
    value_: String,
    synchronized: Boolean,
    updatedAt: Instant
  ) -> T): Query<T> = Query(-285744852, findAll, driver, "DbUserSetting.sq", "findAll", """
  |SELECT *
  |FROM DbUserSetting
  """.trimMargin()) { cursor ->
    mapper(
      database.DbUserSettingAdapter.idAdapter.decode(cursor.getLong(0)!!),
      cursor.getLong(1)?.let { database.DbUserSettingAdapter.apiIdAdapter.decode(it) },
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getLong(4)!! == 1L,
      database.DbUserSettingAdapter.updatedAtAdapter.decode(cursor.getLong(5)!!)
    )
  }

  public override fun findAll(): Query<DbUserSetting> = findAll { id, apiId, key, value_,
      synchronized, updatedAt ->
    DbUserSetting(
      id,
      apiId,
      key,
      value_,
      synchronized,
      updatedAt
    )
  }

  public override fun <T : Any> findById(id: UserSettingId, mapper: (
    id: UserSettingId,
    apiId: UserSettingApiId?,
    key: String,
    value_: String,
    synchronized: Boolean,
    updatedAt: Instant
  ) -> T): Query<T> = FindByIdQuery(id) { cursor ->
    mapper(
      database.DbUserSettingAdapter.idAdapter.decode(cursor.getLong(0)!!),
      cursor.getLong(1)?.let { database.DbUserSettingAdapter.apiIdAdapter.decode(it) },
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getLong(4)!! == 1L,
      database.DbUserSettingAdapter.updatedAtAdapter.decode(cursor.getLong(5)!!)
    )
  }

  public override fun findById(id: UserSettingId): Query<DbUserSetting> = findById(id) { id_, apiId,
      key, value_, synchronized, updatedAt ->
    DbUserSetting(
      id_,
      apiId,
      key,
      value_,
      synchronized,
      updatedAt
    )
  }

  public override fun <T : Any> findByApiId(apiId: UserSettingApiId?, mapper: (
    id: UserSettingId,
    apiId: UserSettingApiId?,
    key: String,
    value_: String,
    synchronized: Boolean,
    updatedAt: Instant
  ) -> T): Query<T> = FindByApiIdQuery(apiId) { cursor ->
    mapper(
      database.DbUserSettingAdapter.idAdapter.decode(cursor.getLong(0)!!),
      cursor.getLong(1)?.let { database.DbUserSettingAdapter.apiIdAdapter.decode(it) },
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getLong(4)!! == 1L,
      database.DbUserSettingAdapter.updatedAtAdapter.decode(cursor.getLong(5)!!)
    )
  }

  public override fun findByApiId(apiId: UserSettingApiId?): Query<DbUserSetting> =
      findByApiId(apiId) { id, apiId_, key, value_, synchronized, updatedAt ->
    DbUserSetting(
      id,
      apiId_,
      key,
      value_,
      synchronized,
      updatedAt
    )
  }

  public override fun <T : Any> findByKey(key: String, mapper: (
    id: UserSettingId,
    apiId: UserSettingApiId?,
    key: String,
    value_: String,
    synchronized: Boolean,
    updatedAt: Instant
  ) -> T): Query<T> = FindByKeyQuery(key) { cursor ->
    mapper(
      database.DbUserSettingAdapter.idAdapter.decode(cursor.getLong(0)!!),
      cursor.getLong(1)?.let { database.DbUserSettingAdapter.apiIdAdapter.decode(it) },
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getLong(4)!! == 1L,
      database.DbUserSettingAdapter.updatedAtAdapter.decode(cursor.getLong(5)!!)
    )
  }

  public override fun findByKey(key: String): Query<DbUserSetting> = findByKey(key) { id, apiId,
      key_, value_, synchronized, updatedAt ->
    DbUserSetting(
      id,
      apiId,
      key_,
      value_,
      synchronized,
      updatedAt
    )
  }

  public override fun <T : Any> getAllUnsynchronized(mapper: (
    id: UserSettingId,
    apiId: UserSettingApiId?,
    key: String,
    value_: String,
    synchronized: Boolean,
    updatedAt: Instant
  ) -> T): Query<T> = Query(492576756, getAllUnsynchronized, driver, "DbUserSetting.sq",
      "getAllUnsynchronized", "SELECT * FROM DbUserSetting WHERE synchronized = FALSE") { cursor ->
    mapper(
      database.DbUserSettingAdapter.idAdapter.decode(cursor.getLong(0)!!),
      cursor.getLong(1)?.let { database.DbUserSettingAdapter.apiIdAdapter.decode(it) },
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getLong(4)!! == 1L,
      database.DbUserSettingAdapter.updatedAtAdapter.decode(cursor.getLong(5)!!)
    )
  }

  public override fun getAllUnsynchronized(): Query<DbUserSetting> = getAllUnsynchronized { id,
      apiId, key, value_, synchronized, updatedAt ->
    DbUserSetting(
      id,
      apiId,
      key,
      value_,
      synchronized,
      updatedAt
    )
  }

  public override fun insert(DbUserSetting: DbUserSetting): Unit {
    driver.execute(-611297739, """
    |INSERT INTO DbUserSetting (
    |    apiId,
    |	key,
    |	value,
    |	synchronized,
    |	updatedAt
    |)
    |VALUES (?, ?, ?, ?, ?)
    """.trimMargin(), 5) {
      bindLong(1, DbUserSetting.apiId?.let { database.DbUserSettingAdapter.apiIdAdapter.encode(it)
          })
      bindString(2, DbUserSetting.key)
      bindString(3, DbUserSetting.value_)
      bindLong(4, if (DbUserSetting.synchronized) 1L else 0L)
      bindLong(5, database.DbUserSettingAdapter.updatedAtAdapter.encode(DbUserSetting.updatedAt))
    }
    notifyQueries(-611297739, {database.dbUserSettingQueries.findAll +
        database.dbUserSettingQueries.findById + database.dbUserSettingQueries.findByKey +
        database.dbUserSettingQueries.getAllUnsynchronized +
        database.dbUserSettingQueries.findByApiId})
  }

  public override fun upsert(DbUserSetting: DbUserSetting): Unit {
    driver.execute(-265900885, """
    |INSERT OR REPLACE INTO DbUserSetting (
    |    id,
    |    apiId,
    |	key,
    |	value,
    |	synchronized,
    |	updatedAt
    |)
    |VALUES (?, ?, ?, ?, ?, ?)
    """.trimMargin(), 6) {
      bindLong(1, database.DbUserSettingAdapter.idAdapter.encode(DbUserSetting.id))
      bindLong(2, DbUserSetting.apiId?.let { database.DbUserSettingAdapter.apiIdAdapter.encode(it)
          })
      bindString(3, DbUserSetting.key)
      bindString(4, DbUserSetting.value_)
      bindLong(5, if (DbUserSetting.synchronized) 1L else 0L)
      bindLong(6, database.DbUserSettingAdapter.updatedAtAdapter.encode(DbUserSetting.updatedAt))
    }
    notifyQueries(-265900885, {database.dbUserSettingQueries.findAll +
        database.dbUserSettingQueries.findById + database.dbUserSettingQueries.findByKey +
        database.dbUserSettingQueries.getAllUnsynchronized +
        database.dbUserSettingQueries.findByApiId})
  }

  public override fun updateByKey(
    apiId: UserSettingApiId?,
    value_: String,
    updatedAt: Instant,
    synchronized: Boolean,
    key: String
  ): Unit {
    driver.execute(-512209597, """
    |UPDATE DbUserSetting
    |SET apiId = ?, value = ?, updatedAt = ?, synchronized = ?
    |WHERE key = ?
    """.trimMargin(), 5) {
      bindLong(1, apiId?.let { database.DbUserSettingAdapter.apiIdAdapter.encode(it) })
      bindString(2, value_)
      bindLong(3, database.DbUserSettingAdapter.updatedAtAdapter.encode(updatedAt))
      bindLong(4, if (synchronized) 1L else 0L)
      bindString(5, key)
    }
    notifyQueries(-512209597, {database.dbUserSettingQueries.findAll +
        database.dbUserSettingQueries.findById + database.dbUserSettingQueries.findByKey +
        database.dbUserSettingQueries.getAllUnsynchronized +
        database.dbUserSettingQueries.findByApiId})
  }

  public override fun update(
    value_: String,
    updatedAt: Instant,
    synchronized: Boolean,
    id: UserSettingId
  ): Unit {
    driver.execute(-266351547, """
    |UPDATE DbUserSetting
    |SET value = ?, updatedAt = ?, synchronized = ?
    |WHERE id = ?
    """.trimMargin(), 4) {
      bindString(1, value_)
      bindLong(2, database.DbUserSettingAdapter.updatedAtAdapter.encode(updatedAt))
      bindLong(3, if (synchronized) 1L else 0L)
      bindLong(4, database.DbUserSettingAdapter.idAdapter.encode(id))
    }
    notifyQueries(-266351547, {database.dbUserSettingQueries.findAll +
        database.dbUserSettingQueries.findById + database.dbUserSettingQueries.findByKey +
        database.dbUserSettingQueries.getAllUnsynchronized +
        database.dbUserSettingQueries.findByApiId})
  }

  public override fun deleteByKey(key: String): Unit {
    driver.execute(-1060586079, """
    |DELETE FROM DbUserSetting
    |WHERE key = ?
    """.trimMargin(), 1) {
      bindString(1, key)
    }
    notifyQueries(-1060586079, {database.dbUserSettingQueries.findAll +
        database.dbUserSettingQueries.findById + database.dbUserSettingQueries.findByKey +
        database.dbUserSettingQueries.getAllUnsynchronized +
        database.dbUserSettingQueries.findByApiId})
  }

  public override fun deleteAll(): Unit {
    driver.execute(-483785990, """DELETE FROM DbUserSetting""", 0)
    notifyQueries(-483785990, {database.dbUserSettingQueries.findAll +
        database.dbUserSettingQueries.findById + database.dbUserSettingQueries.findByKey +
        database.dbUserSettingQueries.getAllUnsynchronized +
        database.dbUserSettingQueries.findByApiId})
  }

  public override fun deleteByApiId(apiId: UserSettingApiId?): Unit {
    driver.execute(null, """
    |DELETE FROM DbUserSetting
    |WHERE apiId ${ if (apiId == null) "IS" else "=" } ?
    """.trimMargin(), 1) {
      bindLong(1, apiId?.let { database.DbUserSettingAdapter.apiIdAdapter.encode(it) })
    }
    notifyQueries(-1324893289, {database.dbUserSettingQueries.findAll +
        database.dbUserSettingQueries.findById + database.dbUserSettingQueries.findByKey +
        database.dbUserSettingQueries.getAllUnsynchronized +
        database.dbUserSettingQueries.findByApiId})
  }

  private inner class FindByIdQuery<out T : Any>(
    public val id: UserSettingId,
    mapper: (SqlCursor) -> T
  ) : Query<T>(findById, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-268114521, """
    |SELECT *
    |FROM DbUserSetting
    |WHERE id = ?
    """.trimMargin(), 1) {
      bindLong(1, database.DbUserSettingAdapter.idAdapter.encode(id))
    }

    public override fun toString(): String = "DbUserSetting.sq:findById"
  }

  private inner class FindByApiIdQuery<out T : Any>(
    public val apiId: UserSettingApiId?,
    mapper: (SqlCursor) -> T
  ) : Query<T>(findByApiId, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(null, """
    |SELECT *
    |FROM DbUserSetting
    |WHERE apiId ${ if (apiId == null) "IS" else "=" } ?
    """.trimMargin(), 1) {
      bindLong(1, apiId?.let { database.DbUserSettingAdapter.apiIdAdapter.encode(it) })
    }

    public override fun toString(): String = "DbUserSetting.sq:findByApiId"
  }

  private inner class FindByKeyQuery<out T : Any>(
    public val key: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(findByKey, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(278386515, """
    |SELECT *
    |FROM DbUserSetting
    |WHERE key = ?
    """.trimMargin(), 1) {
      bindString(1, key)
    }

    public override fun toString(): String = "DbUserSetting.sq:findByKey"
  }
}

private class DbUserQueriesImpl(
  private val database: GGDatabaseImpl,
  private val driver: SqlDriver
) : TransacterImpl(driver), DbUserQueries {
  internal val findAll: MutableList<Query<*>> = copyOnWriteList()

  internal val findById: MutableList<Query<*>> = copyOnWriteList()

  internal val findByIds: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> findAll(mapper: (
    id: UserId,
    name: String,
    lastActivity: Instant?,
    createdAt: Instant
  ) -> T): Query<T> = Query(-1297659304, findAll, driver, "DbUser.sq", "findAll", """
  |SELECT *
  |FROM DbUser
  """.trimMargin()) { cursor ->
    mapper(
      database.DbUserAdapter.idAdapter.decode(cursor.getLong(0)!!),
      cursor.getString(1)!!,
      cursor.getLong(2)?.let { database.DbUserAdapter.lastActivityAdapter.decode(it) },
      database.DbUserAdapter.createdAtAdapter.decode(cursor.getLong(3)!!)
    )
  }

  public override fun findAll(): Query<DbUser> = findAll { id, name, lastActivity, createdAt ->
    DbUser(
      id,
      name,
      lastActivity,
      createdAt
    )
  }

  public override fun <T : Any> findById(id: UserId, mapper: (
    id: UserId,
    name: String,
    lastActivity: Instant?,
    createdAt: Instant
  ) -> T): Query<T> = FindByIdQuery(id) { cursor ->
    mapper(
      database.DbUserAdapter.idAdapter.decode(cursor.getLong(0)!!),
      cursor.getString(1)!!,
      cursor.getLong(2)?.let { database.DbUserAdapter.lastActivityAdapter.decode(it) },
      database.DbUserAdapter.createdAtAdapter.decode(cursor.getLong(3)!!)
    )
  }

  public override fun findById(id: UserId): Query<DbUser> = findById(id) { id_, name, lastActivity,
      createdAt ->
    DbUser(
      id_,
      name,
      lastActivity,
      createdAt
    )
  }

  public override fun <T : Any> findByIds(id: Collection<UserId>, mapper: (
    id: UserId,
    name: String,
    lastActivity: Instant?,
    createdAt: Instant
  ) -> T): Query<T> = FindByIdsQuery(id) { cursor ->
    mapper(
      database.DbUserAdapter.idAdapter.decode(cursor.getLong(0)!!),
      cursor.getString(1)!!,
      cursor.getLong(2)?.let { database.DbUserAdapter.lastActivityAdapter.decode(it) },
      database.DbUserAdapter.createdAtAdapter.decode(cursor.getLong(3)!!)
    )
  }

  public override fun findByIds(id: Collection<UserId>): Query<DbUser> = findByIds(id) { id_, name,
      lastActivity, createdAt ->
    DbUser(
      id_,
      name,
      lastActivity,
      createdAt
    )
  }

  public override fun insert(DbUser: DbUser): Unit {
    driver.execute(1157175177, """
    |INSERT INTO DbUser(name, lastActivity, createdAt)
    |VALUES (?, ?, ?)
    """.trimMargin(), 3) {
      bindString(1, DbUser.name)
      bindLong(2, DbUser.lastActivity?.let { database.DbUserAdapter.lastActivityAdapter.encode(it)
          })
      bindLong(3, database.DbUserAdapter.createdAtAdapter.encode(DbUser.createdAt))
    }
    notifyQueries(1157175177, {database.dbUserQueries.findById + database.dbUserQueries.findByIds +
        database.dbUserQueries.findAll})
  }

  public override fun upsert(DbUser: DbUser): Unit {
    driver.execute(1502572031, """
    |INSERT OR REPLACE INTO DbUser (id, name, lastActivity, createdAt)
    |VALUES (?, ?, ?, ?)
    """.trimMargin(), 4) {
      bindLong(1, database.DbUserAdapter.idAdapter.encode(DbUser.id))
      bindString(2, DbUser.name)
      bindLong(3, DbUser.lastActivity?.let { database.DbUserAdapter.lastActivityAdapter.encode(it)
          })
      bindLong(4, database.DbUserAdapter.createdAtAdapter.encode(DbUser.createdAt))
    }
    notifyQueries(1502572031, {database.dbUserQueries.findById + database.dbUserQueries.findByIds +
        database.dbUserQueries.findAll})
  }

  public override fun delete(id: UserId): Unit {
    driver.execute(1005509243, """DELETE FROM DbUser WHERE id = ?""", 1) {
      bindLong(1, database.DbUserAdapter.idAdapter.encode(id))
    }
    notifyQueries(1005509243, {database.dbUserQueries.findById + database.dbUserQueries.findByIds +
        database.dbUserQueries.findAll})
  }

  public override fun deleteAll(): Unit {
    driver.execute(2024001830, """DELETE FROM DbUser""", 0)
    notifyQueries(2024001830, {database.dbUserQueries.findById + database.dbUserQueries.findByIds +
        database.dbUserQueries.findAll})
  }

  private inner class FindByIdQuery<out T : Any>(
    public val id: UserId,
    mapper: (SqlCursor) -> T
  ) : Query<T>(findById, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-1572691461, """
    |SELECT *
    |FROM DbUser
    |WHERE id = ?
    """.trimMargin(), 1) {
      bindLong(1, database.DbUserAdapter.idAdapter.encode(id))
    }

    public override fun toString(): String = "DbUser.sq:findById"
  }

  private inner class FindByIdsQuery<out T : Any>(
    public val id: Collection<UserId>,
    mapper: (SqlCursor) -> T
  ) : Query<T>(findByIds, mapper) {
    public override fun execute(): SqlCursor {
      val idIndexes = createArguments(count = id.size)
      return driver.executeQuery(null, """
      |SELECT *
      |FROM DbUser
      |WHERE id IN $idIndexes
      """.trimMargin(), id.size) {
        id.forEachIndexed { index, id_ ->
            bindLong(index + 1, database.DbUserAdapter.idAdapter.encode(id_))
            }
      }
    }

    public override fun toString(): String = "DbUser.sq:findByIds"
  }
}

private class DbSyncStatusQueriesImpl(
  private val database: GGDatabaseImpl,
  private val driver: SqlDriver
) : TransacterImpl(driver), DbSyncStatusQueries {
  internal val findAll: MutableList<Query<*>> = copyOnWriteList()

  internal val findById: MutableList<Query<*>> = copyOnWriteList()

  internal val lastInsertRowId: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> findAll(mapper: (
    id: SyncStatusId,
    syncType: String,
    lastSuccessfulFullSync: Instant,
    lastSyncedDown: Instant
  ) -> T): Query<T> = Query(368790454, findAll, driver, "DbSyncStatus.sq", "findAll", """
  |SELECT *
  |FROM DbSyncStatus
  """.trimMargin()) { cursor ->
    mapper(
      database.DbSyncStatusAdapter.idAdapter.decode(cursor.getLong(0)!!),
      cursor.getString(1)!!,
      database.DbSyncStatusAdapter.lastSuccessfulFullSyncAdapter.decode(cursor.getLong(2)!!),
      database.DbSyncStatusAdapter.lastSyncedDownAdapter.decode(cursor.getLong(3)!!)
    )
  }

  public override fun findAll(): Query<DbSyncStatus> = findAll { id, syncType,
      lastSuccessfulFullSync, lastSyncedDown ->
    DbSyncStatus(
      id,
      syncType,
      lastSuccessfulFullSync,
      lastSyncedDown
    )
  }

  public override fun <T : Any> findById(id: SyncStatusId, mapper: (
    id: SyncStatusId,
    syncType: String,
    lastSuccessfulFullSync: Instant,
    lastSyncedDown: Instant
  ) -> T): Query<T> = FindByIdQuery(id) { cursor ->
    mapper(
      database.DbSyncStatusAdapter.idAdapter.decode(cursor.getLong(0)!!),
      cursor.getString(1)!!,
      database.DbSyncStatusAdapter.lastSuccessfulFullSyncAdapter.decode(cursor.getLong(2)!!),
      database.DbSyncStatusAdapter.lastSyncedDownAdapter.decode(cursor.getLong(3)!!)
    )
  }

  public override fun findById(id: SyncStatusId): Query<DbSyncStatus> = findById(id) { id_,
      syncType, lastSuccessfulFullSync, lastSyncedDown ->
    DbSyncStatus(
      id_,
      syncType,
      lastSuccessfulFullSync,
      lastSyncedDown
    )
  }

  public override fun lastInsertRowId(): Query<Long> = Query(-2103090828, lastInsertRowId, driver,
      "DbSyncStatus.sq", "lastInsertRowId", "SELECT last_insert_rowid()") { cursor ->
    cursor.getLong(0)!!
  }

  public override fun insert(DbSyncStatus: DbSyncStatus): Unit {
    driver.execute(-728731029, """
    |INSERT INTO DbSyncStatus(syncType, lastSuccessfulFullSync, lastSyncedDown)
    |VALUES (?, ?, ?)
    """.trimMargin(), 3) {
      bindString(1, DbSyncStatus.syncType)
      bindLong(2,
          database.DbSyncStatusAdapter.lastSuccessfulFullSyncAdapter.encode(DbSyncStatus.lastSuccessfulFullSync))
      bindLong(3,
          database.DbSyncStatusAdapter.lastSyncedDownAdapter.encode(DbSyncStatus.lastSyncedDown))
    }
    notifyQueries(-728731029, {database.dbSyncStatusQueries.findById +
        database.dbSyncStatusQueries.findAll})
  }

  public override fun upsert(DbSyncStatus: DbSyncStatus): Unit {
    driver.execute(-383334175, """
    |INSERT OR REPLACE INTO DbSyncStatus(id, syncType, lastSuccessfulFullSync, lastSyncedDown)
    |VALUES (?, ?, ?, ?)
    """.trimMargin(), 4) {
      bindLong(1, database.DbSyncStatusAdapter.idAdapter.encode(DbSyncStatus.id))
      bindString(2, DbSyncStatus.syncType)
      bindLong(3,
          database.DbSyncStatusAdapter.lastSuccessfulFullSyncAdapter.encode(DbSyncStatus.lastSuccessfulFullSync))
      bindLong(4,
          database.DbSyncStatusAdapter.lastSyncedDownAdapter.encode(DbSyncStatus.lastSyncedDown))
    }
    notifyQueries(-383334175, {database.dbSyncStatusQueries.findById +
        database.dbSyncStatusQueries.findAll})
  }

  public override fun updateLastSyncedDown(lastSyncedDown: Instant, id: SyncStatusId): Unit {
    driver.execute(2003772621, """
    |UPDATE DbSyncStatus
    |SET lastSyncedDown = ?
    |WHERE id = ?
    """.trimMargin(), 2) {
      bindLong(1, database.DbSyncStatusAdapter.lastSyncedDownAdapter.encode(lastSyncedDown))
      bindLong(2, database.DbSyncStatusAdapter.idAdapter.encode(id))
    }
    notifyQueries(2003772621, {database.dbSyncStatusQueries.findById +
        database.dbSyncStatusQueries.findAll})
  }

  public override fun updateLastFullSync(lastSuccessfulFullSync: Instant, id: SyncStatusId): Unit {
    driver.execute(2081238683, """
    |UPDATE DbSyncStatus
    |SET lastSuccessfulFullSync = ?
    |WHERE id = ?
    """.trimMargin(), 2) {
      bindLong(1,
          database.DbSyncStatusAdapter.lastSuccessfulFullSyncAdapter.encode(lastSuccessfulFullSync))
      bindLong(2, database.DbSyncStatusAdapter.idAdapter.encode(id))
    }
    notifyQueries(2081238683, {database.dbSyncStatusQueries.findById +
        database.dbSyncStatusQueries.findAll})
  }

  public override fun deleteAll(): Unit {
    driver.execute(1459417860, """DELETE FROM DbSyncStatus""", 0)
    notifyQueries(1459417860, {database.dbSyncStatusQueries.findById +
        database.dbSyncStatusQueries.findAll})
  }

  private inner class FindByIdQuery<out T : Any>(
    public val id: SyncStatusId,
    mapper: (SqlCursor) -> T
  ) : Query<T>(findById, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-1452356515, """
    |SELECT *
    |FROM DbSyncStatus
    |WHERE id = ?
    """.trimMargin(), 1) {
      bindLong(1, database.DbSyncStatusAdapter.idAdapter.encode(id))
    }

    public override fun toString(): String = "DbSyncStatus.sq:findById"
  }
}

private class DbTrackQueriesImpl(
  private val database: GGDatabaseImpl,
  private val driver: SqlDriver
) : TransacterImpl(driver), DbTrackQueries {
  internal val findAll: MutableList<Query<*>> = copyOnWriteList()

  internal val findById: MutableList<Query<*>> = copyOnWriteList()

  internal val findByIds: MutableList<Query<*>> = copyOnWriteList()

  internal val findByApiId: MutableList<Query<*>> = copyOnWriteList()

  internal val findBy: MutableList<Query<*>> = copyOnWriteList()

  internal val findLocalIdForApiId: MutableList<Query<*>> = copyOnWriteList()

  internal val findApiIdForLocalId: MutableList<Query<*>> = copyOnWriteList()

  internal val getTracksNeedingCached: MutableList<Query<*>> = copyOnWriteList()

  internal val getCachedTrackSizeBytes: MutableList<Query<*>> = copyOnWriteList()

  internal val getTotalBytesRequiredForFullCache: MutableList<Query<*>> = copyOnWriteList()

  internal val getCachedTrackByOfflineTypeSortedByOldestStarted: MutableList<Query<*>> =
      copyOnWriteList()

  internal val getDistinctArtists: MutableList<Query<*>> = copyOnWriteList()

  internal val getDistinctAlbums: MutableList<Query<*>> = copyOnWriteList()

  internal val getTrackCount: MutableList<Query<*>> = copyOnWriteList()

  internal val getTracksNeedingReviewOnSource: MutableList<Query<*>> = copyOnWriteList()

  internal val lastInsertRowId: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> findAll(mapper: (
    id: TrackId,
    apiId: TrackApiId?,
    userId: UserId,
    reviewSourceId: ReviewSourceId?,
    name: String,
    artist: String,
    featuring: String,
    album: String,
    trackNumber: Int?,
    length: Int,
    releaseYear: Int?,
    genre: String,
    playCount: Int,
    isPrivate: Boolean,
    isHidden: Boolean,
    addedToLibrary: Instant?,
    lastPlayed: Instant?,
    inReview: Boolean,
    note: String,
    audioCachedAt: Instant?,
    artCachedAt: Instant?,
    thumbnailCachedAt: Instant?,
    offlineAvailability: RawOfflineAvailabilityType,
    filesizeAudioOgg: Int,
    filesizeAudioMp3: Int,
    filesizeArtPng: Int,
    filesizeThumbnailPng: Int,
    startedOnDevice: Instant?,
    lastReviewed: Instant?,
    recommendedBy: UserId?
  ) -> T): Query<T> = Query(-615205530, findAll, driver, "DbTrack.sq", "findAll", """
  |SELECT *
  |FROM DbTrack
  """.trimMargin()) { cursor ->
    mapper(
      database.DbTrackAdapter.idAdapter.decode(cursor.getLong(0)!!),
      cursor.getLong(1)?.let { database.DbTrackAdapter.apiIdAdapter.decode(it) },
      database.DbTrackAdapter.userIdAdapter.decode(cursor.getLong(2)!!),
      cursor.getLong(3)?.let { database.DbTrackAdapter.reviewSourceIdAdapter.decode(it) },
      cursor.getString(4)!!,
      cursor.getString(5)!!,
      cursor.getString(6)!!,
      cursor.getString(7)!!,
      cursor.getLong(8)?.toInt(),
      cursor.getLong(9)!!.toInt(),
      cursor.getLong(10)?.toInt(),
      cursor.getString(11)!!,
      cursor.getLong(12)!!.toInt(),
      cursor.getLong(13)!! == 1L,
      cursor.getLong(14)!! == 1L,
      cursor.getLong(15)?.let { database.DbTrackAdapter.addedToLibraryAdapter.decode(it) },
      cursor.getLong(16)?.let { database.DbTrackAdapter.lastPlayedAdapter.decode(it) },
      cursor.getLong(17)!! == 1L,
      cursor.getString(18)!!,
      cursor.getLong(19)?.let { database.DbTrackAdapter.audioCachedAtAdapter.decode(it) },
      cursor.getLong(20)?.let { database.DbTrackAdapter.artCachedAtAdapter.decode(it) },
      cursor.getLong(21)?.let { database.DbTrackAdapter.thumbnailCachedAtAdapter.decode(it) },
      database.DbTrackAdapter.offlineAvailabilityAdapter.decode(cursor.getString(22)!!),
      cursor.getLong(23)!!.toInt(),
      cursor.getLong(24)!!.toInt(),
      cursor.getLong(25)!!.toInt(),
      cursor.getLong(26)!!.toInt(),
      cursor.getLong(27)?.let { database.DbTrackAdapter.startedOnDeviceAdapter.decode(it) },
      cursor.getLong(28)?.let { database.DbTrackAdapter.lastReviewedAdapter.decode(it) },
      cursor.getLong(29)?.let { database.DbTrackAdapter.recommendedByAdapter.decode(it) }
    )
  }

  public override fun findAll(): Query<DbTrack> = findAll { id, apiId, userId, reviewSourceId, name,
      artist, featuring, album, trackNumber, length, releaseYear, genre, playCount, isPrivate,
      isHidden, addedToLibrary, lastPlayed, inReview, note, audioCachedAt, artCachedAt,
      thumbnailCachedAt, offlineAvailability, filesizeAudioOgg, filesizeAudioMp3, filesizeArtPng,
      filesizeThumbnailPng, startedOnDevice, lastReviewed, recommendedBy ->
    DbTrack(
      id,
      apiId,
      userId,
      reviewSourceId,
      name,
      artist,
      featuring,
      album,
      trackNumber,
      length,
      releaseYear,
      genre,
      playCount,
      isPrivate,
      isHidden,
      addedToLibrary,
      lastPlayed,
      inReview,
      note,
      audioCachedAt,
      artCachedAt,
      thumbnailCachedAt,
      offlineAvailability,
      filesizeAudioOgg,
      filesizeAudioMp3,
      filesizeArtPng,
      filesizeThumbnailPng,
      startedOnDevice,
      lastReviewed,
      recommendedBy
    )
  }

  public override fun <T : Any> findById(id: TrackId, mapper: (
    id: TrackId,
    apiId: TrackApiId?,
    userId: UserId,
    reviewSourceId: ReviewSourceId?,
    name: String,
    artist: String,
    featuring: String,
    album: String,
    trackNumber: Int?,
    length: Int,
    releaseYear: Int?,
    genre: String,
    playCount: Int,
    isPrivate: Boolean,
    isHidden: Boolean,
    addedToLibrary: Instant?,
    lastPlayed: Instant?,
    inReview: Boolean,
    note: String,
    audioCachedAt: Instant?,
    artCachedAt: Instant?,
    thumbnailCachedAt: Instant?,
    offlineAvailability: RawOfflineAvailabilityType,
    filesizeAudioOgg: Int,
    filesizeAudioMp3: Int,
    filesizeArtPng: Int,
    filesizeThumbnailPng: Int,
    startedOnDevice: Instant?,
    lastReviewed: Instant?,
    recommendedBy: UserId?
  ) -> T): Query<T> = FindByIdQuery(id) { cursor ->
    mapper(
      database.DbTrackAdapter.idAdapter.decode(cursor.getLong(0)!!),
      cursor.getLong(1)?.let { database.DbTrackAdapter.apiIdAdapter.decode(it) },
      database.DbTrackAdapter.userIdAdapter.decode(cursor.getLong(2)!!),
      cursor.getLong(3)?.let { database.DbTrackAdapter.reviewSourceIdAdapter.decode(it) },
      cursor.getString(4)!!,
      cursor.getString(5)!!,
      cursor.getString(6)!!,
      cursor.getString(7)!!,
      cursor.getLong(8)?.toInt(),
      cursor.getLong(9)!!.toInt(),
      cursor.getLong(10)?.toInt(),
      cursor.getString(11)!!,
      cursor.getLong(12)!!.toInt(),
      cursor.getLong(13)!! == 1L,
      cursor.getLong(14)!! == 1L,
      cursor.getLong(15)?.let { database.DbTrackAdapter.addedToLibraryAdapter.decode(it) },
      cursor.getLong(16)?.let { database.DbTrackAdapter.lastPlayedAdapter.decode(it) },
      cursor.getLong(17)!! == 1L,
      cursor.getString(18)!!,
      cursor.getLong(19)?.let { database.DbTrackAdapter.audioCachedAtAdapter.decode(it) },
      cursor.getLong(20)?.let { database.DbTrackAdapter.artCachedAtAdapter.decode(it) },
      cursor.getLong(21)?.let { database.DbTrackAdapter.thumbnailCachedAtAdapter.decode(it) },
      database.DbTrackAdapter.offlineAvailabilityAdapter.decode(cursor.getString(22)!!),
      cursor.getLong(23)!!.toInt(),
      cursor.getLong(24)!!.toInt(),
      cursor.getLong(25)!!.toInt(),
      cursor.getLong(26)!!.toInt(),
      cursor.getLong(27)?.let { database.DbTrackAdapter.startedOnDeviceAdapter.decode(it) },
      cursor.getLong(28)?.let { database.DbTrackAdapter.lastReviewedAdapter.decode(it) },
      cursor.getLong(29)?.let { database.DbTrackAdapter.recommendedByAdapter.decode(it) }
    )
  }

  public override fun findById(id: TrackId): Query<DbTrack> = findById(id) { id_, apiId, userId,
      reviewSourceId, name, artist, featuring, album, trackNumber, length, releaseYear, genre,
      playCount, isPrivate, isHidden, addedToLibrary, lastPlayed, inReview, note, audioCachedAt,
      artCachedAt, thumbnailCachedAt, offlineAvailability, filesizeAudioOgg, filesizeAudioMp3,
      filesizeArtPng, filesizeThumbnailPng, startedOnDevice, lastReviewed, recommendedBy ->
    DbTrack(
      id_,
      apiId,
      userId,
      reviewSourceId,
      name,
      artist,
      featuring,
      album,
      trackNumber,
      length,
      releaseYear,
      genre,
      playCount,
      isPrivate,
      isHidden,
      addedToLibrary,
      lastPlayed,
      inReview,
      note,
      audioCachedAt,
      artCachedAt,
      thumbnailCachedAt,
      offlineAvailability,
      filesizeAudioOgg,
      filesizeAudioMp3,
      filesizeArtPng,
      filesizeThumbnailPng,
      startedOnDevice,
      lastReviewed,
      recommendedBy
    )
  }

  public override fun <T : Any> findByIds(id: Collection<TrackId>, mapper: (
    id: TrackId,
    apiId: TrackApiId?,
    userId: UserId,
    reviewSourceId: ReviewSourceId?,
    name: String,
    artist: String,
    featuring: String,
    album: String,
    trackNumber: Int?,
    length: Int,
    releaseYear: Int?,
    genre: String,
    playCount: Int,
    isPrivate: Boolean,
    isHidden: Boolean,
    addedToLibrary: Instant?,
    lastPlayed: Instant?,
    inReview: Boolean,
    note: String,
    audioCachedAt: Instant?,
    artCachedAt: Instant?,
    thumbnailCachedAt: Instant?,
    offlineAvailability: RawOfflineAvailabilityType,
    filesizeAudioOgg: Int,
    filesizeAudioMp3: Int,
    filesizeArtPng: Int,
    filesizeThumbnailPng: Int,
    startedOnDevice: Instant?,
    lastReviewed: Instant?,
    recommendedBy: UserId?
  ) -> T): Query<T> = FindByIdsQuery(id) { cursor ->
    mapper(
      database.DbTrackAdapter.idAdapter.decode(cursor.getLong(0)!!),
      cursor.getLong(1)?.let { database.DbTrackAdapter.apiIdAdapter.decode(it) },
      database.DbTrackAdapter.userIdAdapter.decode(cursor.getLong(2)!!),
      cursor.getLong(3)?.let { database.DbTrackAdapter.reviewSourceIdAdapter.decode(it) },
      cursor.getString(4)!!,
      cursor.getString(5)!!,
      cursor.getString(6)!!,
      cursor.getString(7)!!,
      cursor.getLong(8)?.toInt(),
      cursor.getLong(9)!!.toInt(),
      cursor.getLong(10)?.toInt(),
      cursor.getString(11)!!,
      cursor.getLong(12)!!.toInt(),
      cursor.getLong(13)!! == 1L,
      cursor.getLong(14)!! == 1L,
      cursor.getLong(15)?.let { database.DbTrackAdapter.addedToLibraryAdapter.decode(it) },
      cursor.getLong(16)?.let { database.DbTrackAdapter.lastPlayedAdapter.decode(it) },
      cursor.getLong(17)!! == 1L,
      cursor.getString(18)!!,
      cursor.getLong(19)?.let { database.DbTrackAdapter.audioCachedAtAdapter.decode(it) },
      cursor.getLong(20)?.let { database.DbTrackAdapter.artCachedAtAdapter.decode(it) },
      cursor.getLong(21)?.let { database.DbTrackAdapter.thumbnailCachedAtAdapter.decode(it) },
      database.DbTrackAdapter.offlineAvailabilityAdapter.decode(cursor.getString(22)!!),
      cursor.getLong(23)!!.toInt(),
      cursor.getLong(24)!!.toInt(),
      cursor.getLong(25)!!.toInt(),
      cursor.getLong(26)!!.toInt(),
      cursor.getLong(27)?.let { database.DbTrackAdapter.startedOnDeviceAdapter.decode(it) },
      cursor.getLong(28)?.let { database.DbTrackAdapter.lastReviewedAdapter.decode(it) },
      cursor.getLong(29)?.let { database.DbTrackAdapter.recommendedByAdapter.decode(it) }
    )
  }

  public override fun findByIds(id: Collection<TrackId>): Query<DbTrack> = findByIds(id) { id_,
      apiId, userId, reviewSourceId, name, artist, featuring, album, trackNumber, length,
      releaseYear, genre, playCount, isPrivate, isHidden, addedToLibrary, lastPlayed, inReview,
      note, audioCachedAt, artCachedAt, thumbnailCachedAt, offlineAvailability, filesizeAudioOgg,
      filesizeAudioMp3, filesizeArtPng, filesizeThumbnailPng, startedOnDevice, lastReviewed,
      recommendedBy ->
    DbTrack(
      id_,
      apiId,
      userId,
      reviewSourceId,
      name,
      artist,
      featuring,
      album,
      trackNumber,
      length,
      releaseYear,
      genre,
      playCount,
      isPrivate,
      isHidden,
      addedToLibrary,
      lastPlayed,
      inReview,
      note,
      audioCachedAt,
      artCachedAt,
      thumbnailCachedAt,
      offlineAvailability,
      filesizeAudioOgg,
      filesizeAudioMp3,
      filesizeArtPng,
      filesizeThumbnailPng,
      startedOnDevice,
      lastReviewed,
      recommendedBy
    )
  }

  public override fun <T : Any> findByApiId(apiId: TrackApiId?, mapper: (
    id: TrackId,
    apiId: TrackApiId?,
    userId: UserId,
    reviewSourceId: ReviewSourceId?,
    name: String,
    artist: String,
    featuring: String,
    album: String,
    trackNumber: Int?,
    length: Int,
    releaseYear: Int?,
    genre: String,
    playCount: Int,
    isPrivate: Boolean,
    isHidden: Boolean,
    addedToLibrary: Instant?,
    lastPlayed: Instant?,
    inReview: Boolean,
    note: String,
    audioCachedAt: Instant?,
    artCachedAt: Instant?,
    thumbnailCachedAt: Instant?,
    offlineAvailability: RawOfflineAvailabilityType,
    filesizeAudioOgg: Int,
    filesizeAudioMp3: Int,
    filesizeArtPng: Int,
    filesizeThumbnailPng: Int,
    startedOnDevice: Instant?,
    lastReviewed: Instant?,
    recommendedBy: UserId?
  ) -> T): Query<T> = FindByApiIdQuery(apiId) { cursor ->
    mapper(
      database.DbTrackAdapter.idAdapter.decode(cursor.getLong(0)!!),
      cursor.getLong(1)?.let { database.DbTrackAdapter.apiIdAdapter.decode(it) },
      database.DbTrackAdapter.userIdAdapter.decode(cursor.getLong(2)!!),
      cursor.getLong(3)?.let { database.DbTrackAdapter.reviewSourceIdAdapter.decode(it) },
      cursor.getString(4)!!,
      cursor.getString(5)!!,
      cursor.getString(6)!!,
      cursor.getString(7)!!,
      cursor.getLong(8)?.toInt(),
      cursor.getLong(9)!!.toInt(),
      cursor.getLong(10)?.toInt(),
      cursor.getString(11)!!,
      cursor.getLong(12)!!.toInt(),
      cursor.getLong(13)!! == 1L,
      cursor.getLong(14)!! == 1L,
      cursor.getLong(15)?.let { database.DbTrackAdapter.addedToLibraryAdapter.decode(it) },
      cursor.getLong(16)?.let { database.DbTrackAdapter.lastPlayedAdapter.decode(it) },
      cursor.getLong(17)!! == 1L,
      cursor.getString(18)!!,
      cursor.getLong(19)?.let { database.DbTrackAdapter.audioCachedAtAdapter.decode(it) },
      cursor.getLong(20)?.let { database.DbTrackAdapter.artCachedAtAdapter.decode(it) },
      cursor.getLong(21)?.let { database.DbTrackAdapter.thumbnailCachedAtAdapter.decode(it) },
      database.DbTrackAdapter.offlineAvailabilityAdapter.decode(cursor.getString(22)!!),
      cursor.getLong(23)!!.toInt(),
      cursor.getLong(24)!!.toInt(),
      cursor.getLong(25)!!.toInt(),
      cursor.getLong(26)!!.toInt(),
      cursor.getLong(27)?.let { database.DbTrackAdapter.startedOnDeviceAdapter.decode(it) },
      cursor.getLong(28)?.let { database.DbTrackAdapter.lastReviewedAdapter.decode(it) },
      cursor.getLong(29)?.let { database.DbTrackAdapter.recommendedByAdapter.decode(it) }
    )
  }

  public override fun findByApiId(apiId: TrackApiId?): Query<DbTrack> = findByApiId(apiId) { id,
      apiId_, userId, reviewSourceId, name, artist, featuring, album, trackNumber, length,
      releaseYear, genre, playCount, isPrivate, isHidden, addedToLibrary, lastPlayed, inReview,
      note, audioCachedAt, artCachedAt, thumbnailCachedAt, offlineAvailability, filesizeAudioOgg,
      filesizeAudioMp3, filesizeArtPng, filesizeThumbnailPng, startedOnDevice, lastReviewed,
      recommendedBy ->
    DbTrack(
      id,
      apiId_,
      userId,
      reviewSourceId,
      name,
      artist,
      featuring,
      album,
      trackNumber,
      length,
      releaseYear,
      genre,
      playCount,
      isPrivate,
      isHidden,
      addedToLibrary,
      lastPlayed,
      inReview,
      note,
      audioCachedAt,
      artCachedAt,
      thumbnailCachedAt,
      offlineAvailability,
      filesizeAudioOgg,
      filesizeAudioMp3,
      filesizeArtPng,
      filesizeThumbnailPng,
      startedOnDevice,
      lastReviewed,
      recommendedBy
    )
  }

  public override fun <T : Any> findBy(
    inReview: Boolean,
    userId: UserId,
    isHidden: Boolean?,
    artist: String?,
    genericFilter: String?,
    album: String?,
    offlineOnly: Long,
    excludedTrackIds: Collection<TrackId>,
    mapper: (
      id: TrackId,
      apiId: TrackApiId?,
      userId: UserId,
      reviewSourceId: ReviewSourceId?,
      name: String,
      artist: String,
      featuring: String,
      album: String,
      trackNumber: Int?,
      length: Int,
      releaseYear: Int?,
      genre: String,
      playCount: Int,
      isPrivate: Boolean,
      isHidden: Boolean,
      addedToLibrary: Instant?,
      lastPlayed: Instant?,
      inReview: Boolean,
      note: String,
      audioCachedAt: Instant?,
      artCachedAt: Instant?,
      thumbnailCachedAt: Instant?,
      offlineAvailability: RawOfflineAvailabilityType,
      filesizeAudioOgg: Int,
      filesizeAudioMp3: Int,
      filesizeArtPng: Int,
      filesizeThumbnailPng: Int,
      startedOnDevice: Instant?,
      lastReviewed: Instant?,
      recommendedBy: UserId?
    ) -> T
  ): Query<T> = FindByQuery(inReview, userId, isHidden, artist, genericFilter, album, offlineOnly,
      excludedTrackIds) { cursor ->
    mapper(
      database.DbTrackAdapter.idAdapter.decode(cursor.getLong(0)!!),
      cursor.getLong(1)?.let { database.DbTrackAdapter.apiIdAdapter.decode(it) },
      database.DbTrackAdapter.userIdAdapter.decode(cursor.getLong(2)!!),
      cursor.getLong(3)?.let { database.DbTrackAdapter.reviewSourceIdAdapter.decode(it) },
      cursor.getString(4)!!,
      cursor.getString(5)!!,
      cursor.getString(6)!!,
      cursor.getString(7)!!,
      cursor.getLong(8)?.toInt(),
      cursor.getLong(9)!!.toInt(),
      cursor.getLong(10)?.toInt(),
      cursor.getString(11)!!,
      cursor.getLong(12)!!.toInt(),
      cursor.getLong(13)!! == 1L,
      cursor.getLong(14)!! == 1L,
      cursor.getLong(15)?.let { database.DbTrackAdapter.addedToLibraryAdapter.decode(it) },
      cursor.getLong(16)?.let { database.DbTrackAdapter.lastPlayedAdapter.decode(it) },
      cursor.getLong(17)!! == 1L,
      cursor.getString(18)!!,
      cursor.getLong(19)?.let { database.DbTrackAdapter.audioCachedAtAdapter.decode(it) },
      cursor.getLong(20)?.let { database.DbTrackAdapter.artCachedAtAdapter.decode(it) },
      cursor.getLong(21)?.let { database.DbTrackAdapter.thumbnailCachedAtAdapter.decode(it) },
      database.DbTrackAdapter.offlineAvailabilityAdapter.decode(cursor.getString(22)!!),
      cursor.getLong(23)!!.toInt(),
      cursor.getLong(24)!!.toInt(),
      cursor.getLong(25)!!.toInt(),
      cursor.getLong(26)!!.toInt(),
      cursor.getLong(27)?.let { database.DbTrackAdapter.startedOnDeviceAdapter.decode(it) },
      cursor.getLong(28)?.let { database.DbTrackAdapter.lastReviewedAdapter.decode(it) },
      cursor.getLong(29)?.let { database.DbTrackAdapter.recommendedByAdapter.decode(it) }
    )
  }

  public override fun findBy(
    inReview: Boolean,
    userId: UserId,
    isHidden: Boolean?,
    artist: String?,
    genericFilter: String?,
    album: String?,
    offlineOnly: Long,
    excludedTrackIds: Collection<TrackId>
  ): Query<DbTrack> = findBy(inReview, userId, isHidden, artist, genericFilter, album, offlineOnly,
      excludedTrackIds) { id, apiId, userId_, reviewSourceId, name, artist_, featuring, album_,
      trackNumber, length, releaseYear, genre, playCount, isPrivate, isHidden_, addedToLibrary,
      lastPlayed, inReview_, note, audioCachedAt, artCachedAt, thumbnailCachedAt,
      offlineAvailability, filesizeAudioOgg, filesizeAudioMp3, filesizeArtPng, filesizeThumbnailPng,
      startedOnDevice, lastReviewed, recommendedBy ->
    DbTrack(
      id,
      apiId,
      userId_,
      reviewSourceId,
      name,
      artist_,
      featuring,
      album_,
      trackNumber,
      length,
      releaseYear,
      genre,
      playCount,
      isPrivate,
      isHidden_,
      addedToLibrary,
      lastPlayed,
      inReview_,
      note,
      audioCachedAt,
      artCachedAt,
      thumbnailCachedAt,
      offlineAvailability,
      filesizeAudioOgg,
      filesizeAudioMp3,
      filesizeArtPng,
      filesizeThumbnailPng,
      startedOnDevice,
      lastReviewed,
      recommendedBy
    )
  }

  public override fun <T : Any> findLocalIdForApiId(ids: Collection<TrackApiId?>,
      mapper: (id: TrackId, apiId: TrackApiId?) -> T): Query<T> = FindLocalIdForApiIdQuery(ids) {
      cursor ->
    mapper(
      database.DbTrackAdapter.idAdapter.decode(cursor.getLong(0)!!),
      cursor.getLong(1)?.let { database.DbTrackAdapter.apiIdAdapter.decode(it) }
    )
  }

  public override fun findLocalIdForApiId(ids: Collection<TrackApiId?>): Query<FindLocalIdForApiId>
      = findLocalIdForApiId(ids) { id, apiId ->
    FindLocalIdForApiId(
      id,
      apiId
    )
  }

  public override fun <T : Any> findApiIdForLocalId(ids: Collection<TrackId>, mapper: (id: TrackId,
      apiId: TrackApiId?) -> T): Query<T> = FindApiIdForLocalIdQuery(ids) { cursor ->
    mapper(
      database.DbTrackAdapter.idAdapter.decode(cursor.getLong(0)!!),
      cursor.getLong(1)?.let { database.DbTrackAdapter.apiIdAdapter.decode(it) }
    )
  }

  public override fun findApiIdForLocalId(ids: Collection<TrackId>): Query<FindApiIdForLocalId> =
      findApiIdForLocalId(ids) { id, apiId ->
    FindApiIdForLocalId(
      id,
      apiId
    )
  }

  public override fun <T : Any> getTracksNeedingCached(mapper: (
    id: TrackId,
    apiId: TrackApiId?,
    userId: UserId,
    reviewSourceId: ReviewSourceId?,
    name: String,
    artist: String,
    featuring: String,
    album: String,
    trackNumber: Int?,
    length: Int,
    releaseYear: Int?,
    genre: String,
    playCount: Int,
    isPrivate: Boolean,
    isHidden: Boolean,
    addedToLibrary: Instant?,
    lastPlayed: Instant?,
    inReview: Boolean,
    note: String,
    audioCachedAt: Instant?,
    artCachedAt: Instant?,
    thumbnailCachedAt: Instant?,
    offlineAvailability: RawOfflineAvailabilityType,
    filesizeAudioOgg: Int,
    filesizeAudioMp3: Int,
    filesizeArtPng: Int,
    filesizeThumbnailPng: Int,
    startedOnDevice: Instant?,
    lastReviewed: Instant?,
    recommendedBy: UserId?
  ) -> T): Query<T> = Query(-830369550, getTracksNeedingCached, driver, "DbTrack.sq",
      "getTracksNeedingCached", """
  |SELECT *
  |FROM DbTrack
  |WHERE offlineAvailability = 'AVAILABLE_OFFLINE'
  |AND (
  |  audioCachedAt IS NULL
  |  OR (artCachedAt IS NULL AND filesizeArtPng > 0)
  |)
  """.trimMargin()) { cursor ->
    mapper(
      database.DbTrackAdapter.idAdapter.decode(cursor.getLong(0)!!),
      cursor.getLong(1)?.let { database.DbTrackAdapter.apiIdAdapter.decode(it) },
      database.DbTrackAdapter.userIdAdapter.decode(cursor.getLong(2)!!),
      cursor.getLong(3)?.let { database.DbTrackAdapter.reviewSourceIdAdapter.decode(it) },
      cursor.getString(4)!!,
      cursor.getString(5)!!,
      cursor.getString(6)!!,
      cursor.getString(7)!!,
      cursor.getLong(8)?.toInt(),
      cursor.getLong(9)!!.toInt(),
      cursor.getLong(10)?.toInt(),
      cursor.getString(11)!!,
      cursor.getLong(12)!!.toInt(),
      cursor.getLong(13)!! == 1L,
      cursor.getLong(14)!! == 1L,
      cursor.getLong(15)?.let { database.DbTrackAdapter.addedToLibraryAdapter.decode(it) },
      cursor.getLong(16)?.let { database.DbTrackAdapter.lastPlayedAdapter.decode(it) },
      cursor.getLong(17)!! == 1L,
      cursor.getString(18)!!,
      cursor.getLong(19)?.let { database.DbTrackAdapter.audioCachedAtAdapter.decode(it) },
      cursor.getLong(20)?.let { database.DbTrackAdapter.artCachedAtAdapter.decode(it) },
      cursor.getLong(21)?.let { database.DbTrackAdapter.thumbnailCachedAtAdapter.decode(it) },
      database.DbTrackAdapter.offlineAvailabilityAdapter.decode(cursor.getString(22)!!),
      cursor.getLong(23)!!.toInt(),
      cursor.getLong(24)!!.toInt(),
      cursor.getLong(25)!!.toInt(),
      cursor.getLong(26)!!.toInt(),
      cursor.getLong(27)?.let { database.DbTrackAdapter.startedOnDeviceAdapter.decode(it) },
      cursor.getLong(28)?.let { database.DbTrackAdapter.lastReviewedAdapter.decode(it) },
      cursor.getLong(29)?.let { database.DbTrackAdapter.recommendedByAdapter.decode(it) }
    )
  }

  public override fun getTracksNeedingCached(): Query<DbTrack> = getTracksNeedingCached { id, apiId,
      userId, reviewSourceId, name, artist, featuring, album, trackNumber, length, releaseYear,
      genre, playCount, isPrivate, isHidden, addedToLibrary, lastPlayed, inReview, note,
      audioCachedAt, artCachedAt, thumbnailCachedAt, offlineAvailability, filesizeAudioOgg,
      filesizeAudioMp3, filesizeArtPng, filesizeThumbnailPng, startedOnDevice, lastReviewed,
      recommendedBy ->
    DbTrack(
      id,
      apiId,
      userId,
      reviewSourceId,
      name,
      artist,
      featuring,
      album,
      trackNumber,
      length,
      releaseYear,
      genre,
      playCount,
      isPrivate,
      isHidden,
      addedToLibrary,
      lastPlayed,
      inReview,
      note,
      audioCachedAt,
      artCachedAt,
      thumbnailCachedAt,
      offlineAvailability,
      filesizeAudioOgg,
      filesizeAudioMp3,
      filesizeArtPng,
      filesizeThumbnailPng,
      startedOnDevice,
      lastReviewed,
      recommendedBy
    )
  }

  public override fun <T : Any> getCachedTrackSizeBytes(
    audioCacheFormat: String,
    offlineAvailabilityType: RawOfflineAvailabilityType?,
    mapper: (bytes: Long?) -> T
  ): Query<T> = GetCachedTrackSizeBytesQuery(audioCacheFormat, offlineAvailabilityType) { cursor ->
    mapper(
      cursor.getLong(0)
    )
  }

  public override fun getCachedTrackSizeBytes(audioCacheFormat: String,
      offlineAvailabilityType: RawOfflineAvailabilityType?): Query<GetCachedTrackSizeBytes> =
      getCachedTrackSizeBytes(audioCacheFormat, offlineAvailabilityType) { bytes ->
    GetCachedTrackSizeBytes(
      bytes
    )
  }

  public override fun <T : Any> getTotalBytesRequiredForFullCache(audioCacheFormat: String,
      mapper: (bytes: Long?) -> T): Query<T> =
      GetTotalBytesRequiredForFullCacheQuery(audioCacheFormat) { cursor ->
    mapper(
      cursor.getLong(0)
    )
  }

  public override fun getTotalBytesRequiredForFullCache(audioCacheFormat: String):
      Query<GetTotalBytesRequiredForFullCache> =
      getTotalBytesRequiredForFullCache(audioCacheFormat) { bytes ->
    GetTotalBytesRequiredForFullCache(
      bytes
    )
  }

  public override fun <T : Any>
      getCachedTrackByOfflineTypeSortedByOldestStarted(offlineAvailabilityType: RawOfflineAvailabilityType,
      mapper: (
    id: TrackId,
    apiId: TrackApiId?,
    userId: UserId,
    reviewSourceId: ReviewSourceId?,
    name: String,
    artist: String,
    featuring: String,
    album: String,
    trackNumber: Int?,
    length: Int,
    releaseYear: Int?,
    genre: String,
    playCount: Int,
    isPrivate: Boolean,
    isHidden: Boolean,
    addedToLibrary: Instant?,
    lastPlayed: Instant?,
    inReview: Boolean,
    note: String,
    audioCachedAt: Instant?,
    artCachedAt: Instant?,
    thumbnailCachedAt: Instant?,
    offlineAvailability: RawOfflineAvailabilityType,
    filesizeAudioOgg: Int,
    filesizeAudioMp3: Int,
    filesizeArtPng: Int,
    filesizeThumbnailPng: Int,
    startedOnDevice: Instant?,
    lastReviewed: Instant?,
    recommendedBy: UserId?
  ) -> T): Query<T> =
      GetCachedTrackByOfflineTypeSortedByOldestStartedQuery(offlineAvailabilityType) { cursor ->
    mapper(
      database.DbTrackAdapter.idAdapter.decode(cursor.getLong(0)!!),
      cursor.getLong(1)?.let { database.DbTrackAdapter.apiIdAdapter.decode(it) },
      database.DbTrackAdapter.userIdAdapter.decode(cursor.getLong(2)!!),
      cursor.getLong(3)?.let { database.DbTrackAdapter.reviewSourceIdAdapter.decode(it) },
      cursor.getString(4)!!,
      cursor.getString(5)!!,
      cursor.getString(6)!!,
      cursor.getString(7)!!,
      cursor.getLong(8)?.toInt(),
      cursor.getLong(9)!!.toInt(),
      cursor.getLong(10)?.toInt(),
      cursor.getString(11)!!,
      cursor.getLong(12)!!.toInt(),
      cursor.getLong(13)!! == 1L,
      cursor.getLong(14)!! == 1L,
      cursor.getLong(15)?.let { database.DbTrackAdapter.addedToLibraryAdapter.decode(it) },
      cursor.getLong(16)?.let { database.DbTrackAdapter.lastPlayedAdapter.decode(it) },
      cursor.getLong(17)!! == 1L,
      cursor.getString(18)!!,
      cursor.getLong(19)?.let { database.DbTrackAdapter.audioCachedAtAdapter.decode(it) },
      cursor.getLong(20)?.let { database.DbTrackAdapter.artCachedAtAdapter.decode(it) },
      cursor.getLong(21)?.let { database.DbTrackAdapter.thumbnailCachedAtAdapter.decode(it) },
      database.DbTrackAdapter.offlineAvailabilityAdapter.decode(cursor.getString(22)!!),
      cursor.getLong(23)!!.toInt(),
      cursor.getLong(24)!!.toInt(),
      cursor.getLong(25)!!.toInt(),
      cursor.getLong(26)!!.toInt(),
      cursor.getLong(27)?.let { database.DbTrackAdapter.startedOnDeviceAdapter.decode(it) },
      cursor.getLong(28)?.let { database.DbTrackAdapter.lastReviewedAdapter.decode(it) },
      cursor.getLong(29)?.let { database.DbTrackAdapter.recommendedByAdapter.decode(it) }
    )
  }

  public override
      fun getCachedTrackByOfflineTypeSortedByOldestStarted(offlineAvailabilityType: RawOfflineAvailabilityType):
      Query<DbTrack> = getCachedTrackByOfflineTypeSortedByOldestStarted(offlineAvailabilityType) {
      id, apiId, userId, reviewSourceId, name, artist, featuring, album, trackNumber, length,
      releaseYear, genre, playCount, isPrivate, isHidden, addedToLibrary, lastPlayed, inReview,
      note, audioCachedAt, artCachedAt, thumbnailCachedAt, offlineAvailability, filesizeAudioOgg,
      filesizeAudioMp3, filesizeArtPng, filesizeThumbnailPng, startedOnDevice, lastReviewed,
      recommendedBy ->
    DbTrack(
      id,
      apiId,
      userId,
      reviewSourceId,
      name,
      artist,
      featuring,
      album,
      trackNumber,
      length,
      releaseYear,
      genre,
      playCount,
      isPrivate,
      isHidden,
      addedToLibrary,
      lastPlayed,
      inReview,
      note,
      audioCachedAt,
      artCachedAt,
      thumbnailCachedAt,
      offlineAvailability,
      filesizeAudioOgg,
      filesizeAudioMp3,
      filesizeArtPng,
      filesizeThumbnailPng,
      startedOnDevice,
      lastReviewed,
      recommendedBy
    )
  }

  public override fun getDistinctArtists(
    userId: UserId,
    offlineOnly: Long,
    isHidden: Boolean?,
    searchFilter: String?
  ): Query<String> = GetDistinctArtistsQuery(userId, offlineOnly, isHidden, searchFilter) {
      cursor ->
    cursor.getString(0)!!
  }

  public override fun <T : Any> getDistinctAlbums(
    userId: UserId,
    isHidden: Boolean?,
    offlineOnly: Long,
    artistFilter: String?,
    searchFilter: String?,
    mapper: (album: String, min: Long?) -> T
  ): Query<T> = GetDistinctAlbumsQuery(userId, isHidden, offlineOnly, artistFilter, searchFilter) {
      cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)
    )
  }

  public override fun getDistinctAlbums(
    userId: UserId,
    isHidden: Boolean?,
    offlineOnly: Long,
    artistFilter: String?,
    searchFilter: String?
  ): Query<GetDistinctAlbums> = getDistinctAlbums(userId, isHidden, offlineOnly, artistFilter,
      searchFilter) { album, min ->
    GetDistinctAlbums(
      album,
      min
    )
  }

  public override fun getTrackCount(offlineAvailabilityType: RawOfflineAvailabilityType?,
      isCached: Long?): Query<Long> = GetTrackCountQuery(offlineAvailabilityType, isCached) {
      cursor ->
    cursor.getLong(0)!!
  }

  public override fun <T : Any> getTracksNeedingReviewOnSource(reviewSourceId: ReviewSourceId?,
      mapper: (
    id: TrackId,
    apiId: TrackApiId?,
    userId: UserId,
    reviewSourceId: ReviewSourceId?,
    name: String,
    artist: String,
    featuring: String,
    album: String,
    trackNumber: Int?,
    length: Int,
    releaseYear: Int?,
    genre: String,
    playCount: Int,
    isPrivate: Boolean,
    isHidden: Boolean,
    addedToLibrary: Instant?,
    lastPlayed: Instant?,
    inReview: Boolean,
    note: String,
    audioCachedAt: Instant?,
    artCachedAt: Instant?,
    thumbnailCachedAt: Instant?,
    offlineAvailability: RawOfflineAvailabilityType,
    filesizeAudioOgg: Int,
    filesizeAudioMp3: Int,
    filesizeArtPng: Int,
    filesizeThumbnailPng: Int,
    startedOnDevice: Instant?,
    lastReviewed: Instant?,
    recommendedBy: UserId?
  ) -> T): Query<T> = GetTracksNeedingReviewOnSourceQuery(reviewSourceId) { cursor ->
    mapper(
      database.DbTrackAdapter.idAdapter.decode(cursor.getLong(0)!!),
      cursor.getLong(1)?.let { database.DbTrackAdapter.apiIdAdapter.decode(it) },
      database.DbTrackAdapter.userIdAdapter.decode(cursor.getLong(2)!!),
      cursor.getLong(3)?.let { database.DbTrackAdapter.reviewSourceIdAdapter.decode(it) },
      cursor.getString(4)!!,
      cursor.getString(5)!!,
      cursor.getString(6)!!,
      cursor.getString(7)!!,
      cursor.getLong(8)?.toInt(),
      cursor.getLong(9)!!.toInt(),
      cursor.getLong(10)?.toInt(),
      cursor.getString(11)!!,
      cursor.getLong(12)!!.toInt(),
      cursor.getLong(13)!! == 1L,
      cursor.getLong(14)!! == 1L,
      cursor.getLong(15)?.let { database.DbTrackAdapter.addedToLibraryAdapter.decode(it) },
      cursor.getLong(16)?.let { database.DbTrackAdapter.lastPlayedAdapter.decode(it) },
      cursor.getLong(17)!! == 1L,
      cursor.getString(18)!!,
      cursor.getLong(19)?.let { database.DbTrackAdapter.audioCachedAtAdapter.decode(it) },
      cursor.getLong(20)?.let { database.DbTrackAdapter.artCachedAtAdapter.decode(it) },
      cursor.getLong(21)?.let { database.DbTrackAdapter.thumbnailCachedAtAdapter.decode(it) },
      database.DbTrackAdapter.offlineAvailabilityAdapter.decode(cursor.getString(22)!!),
      cursor.getLong(23)!!.toInt(),
      cursor.getLong(24)!!.toInt(),
      cursor.getLong(25)!!.toInt(),
      cursor.getLong(26)!!.toInt(),
      cursor.getLong(27)?.let { database.DbTrackAdapter.startedOnDeviceAdapter.decode(it) },
      cursor.getLong(28)?.let { database.DbTrackAdapter.lastReviewedAdapter.decode(it) },
      cursor.getLong(29)?.let { database.DbTrackAdapter.recommendedByAdapter.decode(it) }
    )
  }

  public override fun getTracksNeedingReviewOnSource(reviewSourceId: ReviewSourceId?):
      Query<DbTrack> = getTracksNeedingReviewOnSource(reviewSourceId) { id, apiId, userId,
      reviewSourceId_, name, artist, featuring, album, trackNumber, length, releaseYear, genre,
      playCount, isPrivate, isHidden, addedToLibrary, lastPlayed, inReview, note, audioCachedAt,
      artCachedAt, thumbnailCachedAt, offlineAvailability, filesizeAudioOgg, filesizeAudioMp3,
      filesizeArtPng, filesizeThumbnailPng, startedOnDevice, lastReviewed, recommendedBy ->
    DbTrack(
      id,
      apiId,
      userId,
      reviewSourceId_,
      name,
      artist,
      featuring,
      album,
      trackNumber,
      length,
      releaseYear,
      genre,
      playCount,
      isPrivate,
      isHidden,
      addedToLibrary,
      lastPlayed,
      inReview,
      note,
      audioCachedAt,
      artCachedAt,
      thumbnailCachedAt,
      offlineAvailability,
      filesizeAudioOgg,
      filesizeAudioMp3,
      filesizeArtPng,
      filesizeThumbnailPng,
      startedOnDevice,
      lastReviewed,
      recommendedBy
    )
  }

  public override fun lastInsertRowId(): Query<Long> = Query(-544072924, lastInsertRowId, driver,
      "DbTrack.sq", "lastInsertRowId", "SELECT last_insert_rowid()") { cursor ->
    cursor.getLong(0)!!
  }

  public override fun insert(DbTrack: DbTrack): Unit {
    driver.execute(1317737147, """
    |INSERT OR REPLACE INTO DbTrack (
    |    apiId,
    |	userId,
    |	name,
    |	artist,
    |	featuring,
    |	album,
    |	trackNumber,
    |	length,
    |	releaseYear,
    |	genre,
    |	playCount,
    |	isPrivate,
    |	isHidden,
    |	addedToLibrary,
    |	lastPlayed,
    |	inReview,
    |	note,
    |	audioCachedAt,
    |	artCachedAt,
    |	offlineAvailability,
    |	filesizeAudioOgg,
    |	filesizeAudioMp3,
    |	filesizeArtPng,
    |	filesizeThumbnailPng,
    |	thumbnailCachedAt,
    |	startedOnDevice,
    |	reviewSourceId,
    |	lastReviewed,
    |	recommendedBy
    |)
    |VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)
    """.trimMargin(), 29) {
      bindLong(1, DbTrack.apiId?.let { database.DbTrackAdapter.apiIdAdapter.encode(it) })
      bindLong(2, database.DbTrackAdapter.userIdAdapter.encode(DbTrack.userId))
      bindString(3, DbTrack.name)
      bindString(4, DbTrack.artist)
      bindString(5, DbTrack.featuring)
      bindString(6, DbTrack.album)
      bindLong(7, DbTrack.trackNumber?.let { it.toLong() })
      bindLong(8, DbTrack.length.toLong())
      bindLong(9, DbTrack.releaseYear?.let { it.toLong() })
      bindString(10, DbTrack.genre)
      bindLong(11, DbTrack.playCount.toLong())
      bindLong(12, if (DbTrack.isPrivate) 1L else 0L)
      bindLong(13, if (DbTrack.isHidden) 1L else 0L)
      bindLong(14, DbTrack.addedToLibrary?.let {
          database.DbTrackAdapter.addedToLibraryAdapter.encode(it) })
      bindLong(15, DbTrack.lastPlayed?.let { database.DbTrackAdapter.lastPlayedAdapter.encode(it) })
      bindLong(16, if (DbTrack.inReview) 1L else 0L)
      bindString(17, DbTrack.note)
      bindLong(18, DbTrack.audioCachedAt?.let {
          database.DbTrackAdapter.audioCachedAtAdapter.encode(it) })
      bindLong(19, DbTrack.artCachedAt?.let { database.DbTrackAdapter.artCachedAtAdapter.encode(it)
          })
      bindString(20,
          database.DbTrackAdapter.offlineAvailabilityAdapter.encode(DbTrack.offlineAvailability))
      bindLong(21, DbTrack.filesizeAudioOgg.toLong())
      bindLong(22, DbTrack.filesizeAudioMp3.toLong())
      bindLong(23, DbTrack.filesizeArtPng.toLong())
      bindLong(24, DbTrack.filesizeThumbnailPng.toLong())
      bindLong(25, DbTrack.thumbnailCachedAt?.let {
          database.DbTrackAdapter.thumbnailCachedAtAdapter.encode(it) })
      bindLong(26, DbTrack.startedOnDevice?.let {
          database.DbTrackAdapter.startedOnDeviceAdapter.encode(it) })
      bindLong(27, DbTrack.reviewSourceId?.let {
          database.DbTrackAdapter.reviewSourceIdAdapter.encode(it) })
      bindLong(28, DbTrack.lastReviewed?.let {
          database.DbTrackAdapter.lastReviewedAdapter.encode(it) })
      bindLong(29, DbTrack.recommendedBy?.let {
          database.DbTrackAdapter.recommendedByAdapter.encode(it) })
    }
    notifyQueries(1317737147, {database.dbPlaylistTrackQueries.getTracksForPlaylist +
        database.dbTrackQueries.findById +
        database.dbTrackQueries.getTotalBytesRequiredForFullCache +
        database.dbTrackQueries.getCachedTrackSizeBytes +
        database.dbTrackQueries.findLocalIdForApiId + database.dbTrackQueries.getTrackCount +
        database.dbTrackQueries.getTracksNeedingCached + database.dbTrackQueries.findAll +
        database.dbTrackQueries.getTracksNeedingReviewOnSource +
        database.dbTrackQueries.findApiIdForLocalId + database.dbTrackQueries.getDistinctAlbums +
        database.dbTrackQueries.getCachedTrackByOfflineTypeSortedByOldestStarted +
        database.dbTrackQueries.findBy +
        database.dbReviewSourceQueries.getNeedingReviewTrackCountByQueue +
        database.dbTrackQueries.findByApiId + database.dbTrackQueries.findByIds +
        database.dbTrackQueries.getDistinctArtists})
  }

  public override fun upsert(DbTrack: DbTrack): Unit {
    driver.execute(1663134001, """
    |INSERT OR REPLACE INTO DbTrack (
    |    id,
    |    apiId,
    |	userId,
    |	name,
    |	artist,
    |	featuring,
    |	album,
    |	trackNumber,
    |	length,
    |	releaseYear,
    |	genre,
    |	playCount,
    |	isPrivate,
    |	isHidden,
    |	addedToLibrary,
    |	lastPlayed,
    |	inReview,
    |	note,
    |	audioCachedAt,
    |	artCachedAt,
    |	offlineAvailability,
    |	filesizeAudioOgg,
    |	filesizeAudioMp3,
    |	filesizeArtPng,
    |	filesizeThumbnailPng,
    |	thumbnailCachedAt,
    |	startedOnDevice,
    |	reviewSourceId,
    |	lastReviewed,
    |	recommendedBy
    |)
    |VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)
    """.trimMargin(), 30) {
      bindLong(1, database.DbTrackAdapter.idAdapter.encode(DbTrack.id))
      bindLong(2, DbTrack.apiId?.let { database.DbTrackAdapter.apiIdAdapter.encode(it) })
      bindLong(3, database.DbTrackAdapter.userIdAdapter.encode(DbTrack.userId))
      bindString(4, DbTrack.name)
      bindString(5, DbTrack.artist)
      bindString(6, DbTrack.featuring)
      bindString(7, DbTrack.album)
      bindLong(8, DbTrack.trackNumber?.let { it.toLong() })
      bindLong(9, DbTrack.length.toLong())
      bindLong(10, DbTrack.releaseYear?.let { it.toLong() })
      bindString(11, DbTrack.genre)
      bindLong(12, DbTrack.playCount.toLong())
      bindLong(13, if (DbTrack.isPrivate) 1L else 0L)
      bindLong(14, if (DbTrack.isHidden) 1L else 0L)
      bindLong(15, DbTrack.addedToLibrary?.let {
          database.DbTrackAdapter.addedToLibraryAdapter.encode(it) })
      bindLong(16, DbTrack.lastPlayed?.let { database.DbTrackAdapter.lastPlayedAdapter.encode(it) })
      bindLong(17, if (DbTrack.inReview) 1L else 0L)
      bindString(18, DbTrack.note)
      bindLong(19, DbTrack.audioCachedAt?.let {
          database.DbTrackAdapter.audioCachedAtAdapter.encode(it) })
      bindLong(20, DbTrack.artCachedAt?.let { database.DbTrackAdapter.artCachedAtAdapter.encode(it)
          })
      bindString(21,
          database.DbTrackAdapter.offlineAvailabilityAdapter.encode(DbTrack.offlineAvailability))
      bindLong(22, DbTrack.filesizeAudioOgg.toLong())
      bindLong(23, DbTrack.filesizeAudioMp3.toLong())
      bindLong(24, DbTrack.filesizeArtPng.toLong())
      bindLong(25, DbTrack.filesizeThumbnailPng.toLong())
      bindLong(26, DbTrack.thumbnailCachedAt?.let {
          database.DbTrackAdapter.thumbnailCachedAtAdapter.encode(it) })
      bindLong(27, DbTrack.startedOnDevice?.let {
          database.DbTrackAdapter.startedOnDeviceAdapter.encode(it) })
      bindLong(28, DbTrack.reviewSourceId?.let {
          database.DbTrackAdapter.reviewSourceIdAdapter.encode(it) })
      bindLong(29, DbTrack.lastReviewed?.let {
          database.DbTrackAdapter.lastReviewedAdapter.encode(it) })
      bindLong(30, DbTrack.recommendedBy?.let {
          database.DbTrackAdapter.recommendedByAdapter.encode(it) })
    }
    notifyQueries(1663134001, {database.dbPlaylistTrackQueries.getTracksForPlaylist +
        database.dbTrackQueries.findById +
        database.dbTrackQueries.getTotalBytesRequiredForFullCache +
        database.dbTrackQueries.getCachedTrackSizeBytes +
        database.dbTrackQueries.findLocalIdForApiId + database.dbTrackQueries.getTrackCount +
        database.dbTrackQueries.getTracksNeedingCached + database.dbTrackQueries.findAll +
        database.dbTrackQueries.getTracksNeedingReviewOnSource +
        database.dbTrackQueries.findApiIdForLocalId + database.dbTrackQueries.getDistinctAlbums +
        database.dbTrackQueries.getCachedTrackByOfflineTypeSortedByOldestStarted +
        database.dbTrackQueries.findBy +
        database.dbReviewSourceQueries.getNeedingReviewTrackCountByQueue +
        database.dbTrackQueries.findByApiId + database.dbTrackQueries.findByIds +
        database.dbTrackQueries.getDistinctArtists})
  }

  public override fun delete(id: TrackId): Unit {
    driver.execute(1166071213, """DELETE FROM DbTrack WHERE id = ?""", 1) {
      bindLong(1, database.DbTrackAdapter.idAdapter.encode(id))
    }
    notifyQueries(1166071213, {database.dbPlaylistTrackQueries.getTracksForPlaylist +
        database.dbTrackQueries.findById +
        database.dbTrackQueries.getTotalBytesRequiredForFullCache +
        database.dbTrackQueries.getCachedTrackSizeBytes +
        database.dbTrackQueries.findLocalIdForApiId + database.dbTrackQueries.getTrackCount +
        database.dbTrackQueries.getTracksNeedingCached + database.dbTrackQueries.findAll +
        database.dbTrackQueries.getTracksNeedingReviewOnSource +
        database.dbTrackQueries.findApiIdForLocalId + database.dbTrackQueries.getDistinctAlbums +
        database.dbTrackQueries.getCachedTrackByOfflineTypeSortedByOldestStarted +
        database.dbTrackQueries.findBy +
        database.dbReviewSourceQueries.getNeedingReviewTrackCountByQueue +
        database.dbTrackQueries.findByApiId + database.dbTrackQueries.findByIds +
        database.dbTrackQueries.getDistinctArtists})
  }

  public override fun deleteAll(): Unit {
    driver.execute(732082356, """DELETE FROM DbTrack""", 0)
    notifyQueries(732082356, {database.dbPlaylistTrackQueries.getTracksForPlaylist +
        database.dbTrackQueries.findById +
        database.dbTrackQueries.getTotalBytesRequiredForFullCache +
        database.dbTrackQueries.getCachedTrackSizeBytes +
        database.dbTrackQueries.findLocalIdForApiId + database.dbTrackQueries.getTrackCount +
        database.dbTrackQueries.getTracksNeedingCached + database.dbTrackQueries.findAll +
        database.dbTrackQueries.getTracksNeedingReviewOnSource +
        database.dbTrackQueries.findApiIdForLocalId + database.dbTrackQueries.getDistinctAlbums +
        database.dbTrackQueries.getCachedTrackByOfflineTypeSortedByOldestStarted +
        database.dbTrackQueries.findBy +
        database.dbReviewSourceQueries.getNeedingReviewTrackCountByQueue +
        database.dbTrackQueries.findByApiId + database.dbTrackQueries.findByIds +
        database.dbTrackQueries.getDistinctArtists})
  }

  public override fun deleteByApiId(apiId: TrackApiId?): Unit {
    driver.execute(null,
        """DELETE FROM DbTrack WHERE apiId ${ if (apiId == null) "IS" else "=" } ?""", 1) {
      bindLong(1, apiId?.let { database.DbTrackAdapter.apiIdAdapter.encode(it) })
    }
    notifyQueries(-1918960559, {database.dbPlaylistTrackQueries.getTracksForPlaylist +
        database.dbTrackQueries.findById +
        database.dbTrackQueries.getTotalBytesRequiredForFullCache +
        database.dbTrackQueries.getCachedTrackSizeBytes +
        database.dbTrackQueries.findLocalIdForApiId + database.dbTrackQueries.getTrackCount +
        database.dbTrackQueries.getTracksNeedingCached + database.dbTrackQueries.findAll +
        database.dbTrackQueries.getTracksNeedingReviewOnSource +
        database.dbTrackQueries.findApiIdForLocalId + database.dbTrackQueries.getDistinctAlbums +
        database.dbTrackQueries.getCachedTrackByOfflineTypeSortedByOldestStarted +
        database.dbTrackQueries.findBy +
        database.dbReviewSourceQueries.getNeedingReviewTrackCountByQueue +
        database.dbTrackQueries.findByApiId + database.dbTrackQueries.findByIds +
        database.dbTrackQueries.getDistinctArtists})
  }

  public override fun deleteByIds(id: Collection<TrackId>): Unit {
    val idIndexes = createArguments(count = id.size)
    driver.execute(null, """DELETE FROM DbTrack WHERE id IN $idIndexes""", id.size) {
      id.forEachIndexed { index, id_ ->
          bindLong(index + 1, database.DbTrackAdapter.idAdapter.encode(id_))
          }
    }
    notifyQueries(-842212044, {database.dbPlaylistTrackQueries.getTracksForPlaylist +
        database.dbTrackQueries.findById +
        database.dbTrackQueries.getTotalBytesRequiredForFullCache +
        database.dbTrackQueries.getCachedTrackSizeBytes +
        database.dbTrackQueries.findLocalIdForApiId + database.dbTrackQueries.getTrackCount +
        database.dbTrackQueries.getTracksNeedingCached + database.dbTrackQueries.findAll +
        database.dbTrackQueries.getTracksNeedingReviewOnSource +
        database.dbTrackQueries.findApiIdForLocalId + database.dbTrackQueries.getDistinctAlbums +
        database.dbTrackQueries.getCachedTrackByOfflineTypeSortedByOldestStarted +
        database.dbTrackQueries.findBy +
        database.dbReviewSourceQueries.getNeedingReviewTrackCountByQueue +
        database.dbTrackQueries.findByApiId + database.dbTrackQueries.findByIds +
        database.dbTrackQueries.getDistinctArtists})
  }

  public override fun deleteInReviewTracksOnReviewSource(reviewSourceId: ReviewSourceId?): Unit {
    driver.execute(null, """
    |DELETE
    |FROM DbTrack
    |WHERE reviewSourceId ${ if (reviewSourceId == null) "IS" else "=" } ?
    |AND inReview = TRUE
    """.trimMargin(), 1) {
      bindLong(1, reviewSourceId?.let { database.DbTrackAdapter.reviewSourceIdAdapter.encode(it) })
    }
    notifyQueries(-658824060, {database.dbPlaylistTrackQueries.getTracksForPlaylist +
        database.dbTrackQueries.findById +
        database.dbTrackQueries.getTotalBytesRequiredForFullCache +
        database.dbTrackQueries.getCachedTrackSizeBytes +
        database.dbTrackQueries.findLocalIdForApiId + database.dbTrackQueries.getTrackCount +
        database.dbTrackQueries.getTracksNeedingCached + database.dbTrackQueries.findAll +
        database.dbTrackQueries.getTracksNeedingReviewOnSource +
        database.dbTrackQueries.findApiIdForLocalId + database.dbTrackQueries.getDistinctAlbums +
        database.dbTrackQueries.getCachedTrackByOfflineTypeSortedByOldestStarted +
        database.dbTrackQueries.findBy +
        database.dbReviewSourceQueries.getNeedingReviewTrackCountByQueue +
        database.dbTrackQueries.findByApiId + database.dbTrackQueries.findByIds +
        database.dbTrackQueries.getDistinctArtists})
  }

  public override fun updateLastStartedOnDevice(startedOnDevice: Instant?, id: TrackId): Unit {
    driver.execute(1947511925, """
    |UPDATE DbTrack
    |SET startedOnDevice = ?
    |WHERE id = ?
    """.trimMargin(), 2) {
      bindLong(1, startedOnDevice?.let { database.DbTrackAdapter.startedOnDeviceAdapter.encode(it)
          })
      bindLong(2, database.DbTrackAdapter.idAdapter.encode(id))
    }
    notifyQueries(1947511925, {database.dbPlaylistTrackQueries.getTracksForPlaylist +
        database.dbTrackQueries.findById +
        database.dbTrackQueries.getTotalBytesRequiredForFullCache +
        database.dbTrackQueries.getCachedTrackSizeBytes +
        database.dbTrackQueries.findLocalIdForApiId + database.dbTrackQueries.getTrackCount +
        database.dbTrackQueries.getTracksNeedingCached + database.dbTrackQueries.findAll +
        database.dbTrackQueries.getTracksNeedingReviewOnSource +
        database.dbTrackQueries.findApiIdForLocalId + database.dbTrackQueries.getDistinctAlbums +
        database.dbTrackQueries.getCachedTrackByOfflineTypeSortedByOldestStarted +
        database.dbTrackQueries.findBy +
        database.dbReviewSourceQueries.getNeedingReviewTrackCountByQueue +
        database.dbTrackQueries.findByApiId + database.dbTrackQueries.findByIds +
        database.dbTrackQueries.getDistinctArtists})
  }

  public override fun deleteOrphanedTracks(userId: UserId): Unit {
    driver.execute(862356892, """
    |DELETE FROM DbTrack WHERE id IN (
    |  SELECT t.id
    |  FROM DbTrack t
    |  LEFT JOIN DbPlaylistTrack pt
    |    ON t.id = pt.trackId
    |  WHERE userId <> ?
    |  AND pt.id IS NULL
    |)
    """.trimMargin(), 1) {
      bindLong(1, database.DbTrackAdapter.userIdAdapter.encode(userId))
    }
    notifyQueries(862356892, {database.dbPlaylistTrackQueries.getTracksForPlaylist +
        database.dbTrackQueries.findById +
        database.dbTrackQueries.getTotalBytesRequiredForFullCache +
        database.dbTrackQueries.getCachedTrackSizeBytes +
        database.dbTrackQueries.findLocalIdForApiId + database.dbTrackQueries.getTrackCount +
        database.dbTrackQueries.getTracksNeedingCached + database.dbTrackQueries.findAll +
        database.dbTrackQueries.getTracksNeedingReviewOnSource +
        database.dbTrackQueries.findApiIdForLocalId + database.dbTrackQueries.getDistinctAlbums +
        database.dbTrackQueries.getCachedTrackByOfflineTypeSortedByOldestStarted +
        database.dbTrackQueries.findBy +
        database.dbReviewSourceQueries.getNeedingReviewTrackCountByQueue +
        database.dbTrackQueries.findByApiId + database.dbTrackQueries.findByIds +
        database.dbTrackQueries.getDistinctArtists})
  }

  private inner class FindByIdQuery<out T : Any>(
    public val id: TrackId,
    mapper: (SqlCursor) -> T
  ) : Query<T>(findById, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-1891460947, """
    |SELECT *
    |FROM DbTrack
    |WHERE id = ?
    """.trimMargin(), 1) {
      bindLong(1, database.DbTrackAdapter.idAdapter.encode(id))
    }

    public override fun toString(): String = "DbTrack.sq:findById"
  }

  private inner class FindByIdsQuery<out T : Any>(
    public val id: Collection<TrackId>,
    mapper: (SqlCursor) -> T
  ) : Query<T>(findByIds, mapper) {
    public override fun execute(): SqlCursor {
      val idIndexes = createArguments(count = id.size)
      return driver.executeQuery(null, """
      |SELECT *
      |FROM DbTrack
      |WHERE id IN $idIndexes
      """.trimMargin(), id.size) {
        id.forEachIndexed { index, id_ ->
            bindLong(index + 1, database.DbTrackAdapter.idAdapter.encode(id_))
            }
      }
    }

    public override fun toString(): String = "DbTrack.sq:findByIds"
  }

  private inner class FindByApiIdQuery<out T : Any>(
    public val apiId: TrackApiId?,
    mapper: (SqlCursor) -> T
  ) : Query<T>(findByApiId, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(null, """
    |SELECT *
    |FROM DbTrack
    |WHERE apiId ${ if (apiId == null) "IS" else "=" } ?
    """.trimMargin(), 1) {
      bindLong(1, apiId?.let { database.DbTrackAdapter.apiIdAdapter.encode(it) })
    }

    public override fun toString(): String = "DbTrack.sq:findByApiId"
  }

  private inner class FindByQuery<out T : Any>(
    public val inReview: Boolean,
    public val userId: UserId,
    public val isHidden: Boolean?,
    public val artist: String?,
    public val genericFilter: String?,
    public val album: String?,
    public val offlineOnly: Long,
    public val excludedTrackIds: Collection<TrackId>,
    mapper: (SqlCursor) -> T
  ) : Query<T>(findBy, mapper) {
    public override fun execute(): SqlCursor {
      val excludedTrackIdsIndexes = createArguments(count = excludedTrackIds.size)
      return driver.executeQuery(null, """
      |SELECT *
      |FROM DbTrack
      |WHERE inReview = ?
      |  AND userId = ?
      |  AND (? IS NULL OR isHidden ${ if (isHidden == null) "IS" else "=" } ?)
      |  AND ((? IS NULL) OR
      |    (? ${ if (artist == null) "IS" else "=" } '' AND artist ${ if (artist == null) "IS" else "=" } ? AND featuring ${ if (artist == null) "IS" else "=" } ?) OR
      |    (? ${ if (artist == null) "IS NOT" else "<>" } '' AND (artist LIKE '%' || ? || '%' OR featuring LIKE '%' || ? || '%'))
      |  )
      |  AND (
      |    (? IS NULL) OR
      |    (name LIKE '%' || ? || '%') OR
      |    (artist LIKE '%' || ? || '%') OR
      |    (featuring LIKE '%' || ? || '%') OR
      |    (album LIKE '%' || ? || '%') OR
      |    (genre LIKE '%' || ? || '%') OR
      |    (note LIKE '%' || ? || '%')
      |  )
      |  AND (? IS NULL OR album LIKE ?)
      |  AND (? IS FALSE OR audioCachedAt IS NOT NULL)
      |  AND (id NOT IN $excludedTrackIdsIndexes)
      """.trimMargin(), 21 + excludedTrackIds.size) {
        bindLong(1, if (inReview) 1L else 0L)
        bindLong(2, database.DbTrackAdapter.userIdAdapter.encode(userId))
        bindLong(3, isHidden?.let { if (it) 1L else 0L })
        bindLong(4, isHidden?.let { if (it) 1L else 0L })
        bindString(5, artist)
        bindString(6, artist)
        bindString(7, artist)
        bindString(8, artist)
        bindString(9, artist)
        bindString(10, artist)
        bindString(11, artist)
        bindString(12, genericFilter)
        bindString(13, genericFilter)
        bindString(14, genericFilter)
        bindString(15, genericFilter)
        bindString(16, genericFilter)
        bindString(17, genericFilter)
        bindString(18, genericFilter)
        bindString(19, album)
        bindString(20, album)
        bindLong(21, offlineOnly)
        excludedTrackIds.forEachIndexed { index, excludedTrackIds_ ->
            bindLong(index + 22, database.DbTrackAdapter.idAdapter.encode(excludedTrackIds_))
            }
      }
    }

    public override fun toString(): String = "DbTrack.sq:findBy"
  }

  private inner class FindLocalIdForApiIdQuery<out T : Any>(
    public val ids: Collection<TrackApiId?>,
    mapper: (SqlCursor) -> T
  ) : Query<T>(findLocalIdForApiId, mapper) {
    public override fun execute(): SqlCursor {
      val idsIndexes = createArguments(count = ids.size)
      return driver.executeQuery(null, """
      |SELECT id, apiId
      |FROM DbTrack
      |WHERE apiId IN $idsIndexes
      """.trimMargin(), ids.size) {
        ids.forEachIndexed { index, ids_ ->
            bindLong(index + 1, ids_?.let { database.DbTrackAdapter.apiIdAdapter.encode(it) })
            }
      }
    }

    public override fun toString(): String = "DbTrack.sq:findLocalIdForApiId"
  }

  private inner class FindApiIdForLocalIdQuery<out T : Any>(
    public val ids: Collection<TrackId>,
    mapper: (SqlCursor) -> T
  ) : Query<T>(findApiIdForLocalId, mapper) {
    public override fun execute(): SqlCursor {
      val idsIndexes = createArguments(count = ids.size)
      return driver.executeQuery(null, """
      |SELECT id, apiId
      |FROM DbTrack
      |WHERE id IN $idsIndexes
      """.trimMargin(), ids.size) {
        ids.forEachIndexed { index, ids_ ->
            bindLong(index + 1, database.DbTrackAdapter.idAdapter.encode(ids_))
            }
      }
    }

    public override fun toString(): String = "DbTrack.sq:findApiIdForLocalId"
  }

  private inner class GetCachedTrackSizeBytesQuery<out T : Any>(
    public val audioCacheFormat: String,
    public val offlineAvailabilityType: RawOfflineAvailabilityType?,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getCachedTrackSizeBytes, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(null, """
    |SELECT sum(
    |  CASE audioCachedAt IS NULL
    |    WHEN 1 THEN 0
    |    ELSE CASE ?
    |      WHEN 'OGG' THEN filesizeAudioOgg
    |      WHEN 'MP3' THEN filesizeAudioMp3
    |      ELSE 0
    |    END
    |  END
    |) + sum(
    |  CASE artCachedAt IS NULL
    |    WHEN 1 THEN 0
    |    ELSE filesizeArtPng
    |  END
    |) + sum(
    |  CASE thumbnailCachedAt IS NULL
    |    WHEN 1 THEN 0
    |    ELSE filesizeThumbnailPng
    |  END
    |)
    |AS bytes
    |FROM DbTrack
    |WHERE (? IS NULL OR offlineAvailability ${ if (offlineAvailabilityType == null) "IS" else "=" } ?)
    """.trimMargin(), 3) {
      val offlineAvailabilityType_ = offlineAvailabilityType?.let {
          database.DbTrackAdapter.offlineAvailabilityAdapter.encode(it) }
      bindString(1, audioCacheFormat)
      bindString(2, offlineAvailabilityType_)
      bindString(3, offlineAvailabilityType_)
    }

    public override fun toString(): String = "DbTrack.sq:getCachedTrackSizeBytes"
  }

  private inner class GetTotalBytesRequiredForFullCacheQuery<out T : Any>(
    public val audioCacheFormat: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getTotalBytesRequiredForFullCache, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-1390594428, """
    |SELECT sum(filesizeArtPng) + sum(filesizeThumbnailPng) + sum(
    |  CASE ?
    |    WHEN 'OGG' THEN filesizeAudioOgg
    |    WHEN 'MP3' THEN filesizeAudioMp3
    |    ELSE 0
    |  END
    |) AS bytes
    |FROM DbTrack
    |WHERE offlineAvailability = 'AVAILABLE_OFFLINE'
    """.trimMargin(), 1) {
      bindString(1, audioCacheFormat)
    }

    public override fun toString(): String = "DbTrack.sq:getTotalBytesRequiredForFullCache"
  }

  private inner class GetCachedTrackByOfflineTypeSortedByOldestStartedQuery<out T : Any>(
    public val offlineAvailabilityType: RawOfflineAvailabilityType,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getCachedTrackByOfflineTypeSortedByOldestStarted, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(565430173, """
    |SELECT *
    |FROM DbTrack
    |WHERE offlineAvailability = ?
    |AND (audioCachedAt IS NOT NULL OR artCachedAt IS NOT NULL)
    |ORDER BY startedOnDevice ASC
    """.trimMargin(), 1) {
      bindString(1,
          database.DbTrackAdapter.offlineAvailabilityAdapter.encode(offlineAvailabilityType))
    }

    public override fun toString(): String =
        "DbTrack.sq:getCachedTrackByOfflineTypeSortedByOldestStarted"
  }

  private inner class GetDistinctArtistsQuery<out T : Any>(
    public val userId: UserId,
    public val offlineOnly: Long,
    public val isHidden: Boolean?,
    public val searchFilter: String?,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getDistinctArtists, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(null, """
    |SELECT artist
    |  FROM DbTrack
    |  WHERE inReview = FALSE
    |  AND userId = ?
    |  AND (? IS FALSE OR audioCachedAt IS NOT NULL)
    |  AND (? IS NULL OR isHidden ${ if (isHidden == null) "IS" else "=" } ?)
    |  AND ((? IS NULL) OR (artist LIKE '%' || ? || '%'))
    |UNION SELECT featuring
    |  FROM DbTrack
    |  WHERE inReview = FALSE
    |  AND userId = ?
    |  AND (? IS FALSE OR audioCachedAt IS NOT NULL)
    |  AND (? IS NULL OR isHidden ${ if (isHidden == null) "IS" else "=" } ?)
    |  AND length(featuring) > 0
    |  AND ((? IS NULL) OR (featuring LIKE '%' || ? || '%'))
    |ORDER BY artist COLLATE NOCASE ASC
    """.trimMargin(), 12) {
      val userId_ = database.DbTrackAdapter.userIdAdapter.encode(userId)
      bindLong(1, userId_)
      bindLong(2, offlineOnly)
      bindLong(3, isHidden?.let { if (it) 1L else 0L })
      bindLong(4, isHidden?.let { if (it) 1L else 0L })
      bindString(5, searchFilter)
      bindString(6, searchFilter)
      bindLong(7, userId_)
      bindLong(8, offlineOnly)
      bindLong(9, isHidden?.let { if (it) 1L else 0L })
      bindLong(10, isHidden?.let { if (it) 1L else 0L })
      bindString(11, searchFilter)
      bindString(12, searchFilter)
    }

    public override fun toString(): String = "DbTrack.sq:getDistinctArtists"
  }

  private inner class GetDistinctAlbumsQuery<out T : Any>(
    public val userId: UserId,
    public val isHidden: Boolean?,
    public val offlineOnly: Long,
    public val artistFilter: String?,
    public val searchFilter: String?,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getDistinctAlbums, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(null, """
    |SELECT album, min(id)
    |FROM DbTrack
    |WHERE inReview = FALSE
    |AND userId = ?
    |AND (? IS NULL OR isHidden ${ if (isHidden == null) "IS" else "=" } ?)
    |AND (? IS FALSE OR audioCachedAt IS NOT NULL)
    |AND (
    |  ? IS NULL OR
    |  (? ${ if (artistFilter == null) "IS" else "=" } '' AND artist ${ if (artistFilter == null) "IS" else "=" } ? AND featuring ${ if (artistFilter == null) "IS" else "=" } ?) OR
    |  (? ${ if (artistFilter == null) "IS NOT" else "<>" } '' AND (artist LIKE '%' || ? || '%' OR featuring LIKE '%' || ? || '%'))
    |)
    |AND ((? IS NULL) OR (album LIKE '%' || ? || '%'))
    |GROUP BY album
    |ORDER BY album COLLATE NOCASE ASC
    """.trimMargin(), 13) {
      bindLong(1, database.DbTrackAdapter.userIdAdapter.encode(userId))
      bindLong(2, isHidden?.let { if (it) 1L else 0L })
      bindLong(3, isHidden?.let { if (it) 1L else 0L })
      bindLong(4, offlineOnly)
      bindString(5, artistFilter)
      bindString(6, artistFilter)
      bindString(7, artistFilter)
      bindString(8, artistFilter)
      bindString(9, artistFilter)
      bindString(10, artistFilter)
      bindString(11, artistFilter)
      bindString(12, searchFilter)
      bindString(13, searchFilter)
    }

    public override fun toString(): String = "DbTrack.sq:getDistinctAlbums"
  }

  private inner class GetTrackCountQuery<out T : Any>(
    public val offlineAvailabilityType: RawOfflineAvailabilityType?,
    public val isCached: Long?,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getTrackCount, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(null, """
    |SELECT count(*)
    |FROM DbTrack
    |WHERE (? IS NULL OR offlineAvailability ${ if (offlineAvailabilityType == null) "IS" else "=" } ?)
    |AND (
    |  (? IS NULL OR (? ${ if (isCached == null) "IS" else "=" } TRUE AND audioCachedAt IS NOT NULL))
    |  OR (? IS NULL OR (? ${ if (isCached == null) "IS" else "=" } FALSE AND audioCachedAt IS NULL))
    |)
    """.trimMargin(), 6) {
      val offlineAvailabilityType_ = offlineAvailabilityType?.let {
          database.DbTrackAdapter.offlineAvailabilityAdapter.encode(it) }
      bindString(1, offlineAvailabilityType_)
      bindString(2, offlineAvailabilityType_)
      bindLong(3, isCached)
      bindLong(4, isCached)
      bindLong(5, isCached)
      bindLong(6, isCached)
    }

    public override fun toString(): String = "DbTrack.sq:getTrackCount"
  }

  private inner class GetTracksNeedingReviewOnSourceQuery<out T : Any>(
    public val reviewSourceId: ReviewSourceId?,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getTracksNeedingReviewOnSource, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(null, """
    |SELECT *
    |FROM DbTrack
    |WHERE reviewSourceId ${ if (reviewSourceId == null) "IS" else "=" } ?
    |AND inReview = TRUE
    """.trimMargin(), 1) {
      bindLong(1, reviewSourceId?.let { database.DbTrackAdapter.reviewSourceIdAdapter.encode(it) })
    }

    public override fun toString(): String = "DbTrack.sq:getTracksNeedingReviewOnSource"
  }
}

private class DbUserPermissionQueriesImpl(
  private val database: GGDatabaseImpl,
  private val driver: SqlDriver
) : TransacterImpl(driver), DbUserPermissionQueries {
  internal val findAll: MutableList<Query<*>> = copyOnWriteList()

  internal val findById: MutableList<Query<*>> = copyOnWriteList()

  internal val findByType: MutableList<Query<*>> = copyOnWriteList()

  internal val findByTypeIn: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> findAll(mapper: (
    id: UserPermissionId,
    type: RawUserPermissionType,
    createdAt: Instant
  ) -> T): Query<T> = Query(1570176905, findAll, driver, "DbUserPermission.sq", "findAll", """
  |SELECT *
  |FROM DbUserPermission
  """.trimMargin()) { cursor ->
    mapper(
      database.DbUserPermissionAdapter.idAdapter.decode(cursor.getLong(0)!!),
      database.DbUserPermissionAdapter.typeAdapter.decode(cursor.getString(1)!!),
      database.DbUserPermissionAdapter.createdAtAdapter.decode(cursor.getLong(2)!!)
    )
  }

  public override fun findAll(): Query<DbUserPermission> = findAll { id, type, createdAt ->
    DbUserPermission(
      id,
      type,
      createdAt
    )
  }

  public override fun <T : Any> findById(id: UserPermissionId, mapper: (
    id: UserPermissionId,
    type: RawUserPermissionType,
    createdAt: Instant
  ) -> T): Query<T> = FindByIdQuery(id) { cursor ->
    mapper(
      database.DbUserPermissionAdapter.idAdapter.decode(cursor.getLong(0)!!),
      database.DbUserPermissionAdapter.typeAdapter.decode(cursor.getString(1)!!),
      database.DbUserPermissionAdapter.createdAtAdapter.decode(cursor.getLong(2)!!)
    )
  }

  public override fun findById(id: UserPermissionId): Query<DbUserPermission> = findById(id) { id_,
      type, createdAt ->
    DbUserPermission(
      id_,
      type,
      createdAt
    )
  }

  public override fun <T : Any> findByType(type: RawUserPermissionType, mapper: (
    id: UserPermissionId,
    type: RawUserPermissionType,
    createdAt: Instant
  ) -> T): Query<T> = FindByTypeQuery(type) { cursor ->
    mapper(
      database.DbUserPermissionAdapter.idAdapter.decode(cursor.getLong(0)!!),
      database.DbUserPermissionAdapter.typeAdapter.decode(cursor.getString(1)!!),
      database.DbUserPermissionAdapter.createdAtAdapter.decode(cursor.getLong(2)!!)
    )
  }

  public override fun findByType(type: RawUserPermissionType): Query<DbUserPermission> =
      findByType(type) { id, type_, createdAt ->
    DbUserPermission(
      id,
      type_,
      createdAt
    )
  }

  public override fun <T : Any> findByTypeIn(type: Collection<RawUserPermissionType>, mapper: (
    id: UserPermissionId,
    type: RawUserPermissionType,
    createdAt: Instant
  ) -> T): Query<T> = FindByTypeInQuery(type) { cursor ->
    mapper(
      database.DbUserPermissionAdapter.idAdapter.decode(cursor.getLong(0)!!),
      database.DbUserPermissionAdapter.typeAdapter.decode(cursor.getString(1)!!),
      database.DbUserPermissionAdapter.createdAtAdapter.decode(cursor.getLong(2)!!)
    )
  }

  public override fun findByTypeIn(type: Collection<RawUserPermissionType>): Query<DbUserPermission>
      = findByTypeIn(type) { id, type_, createdAt ->
    DbUserPermission(
      id,
      type_,
      createdAt
    )
  }

  public override fun insert(DbUserPermission: DbUserPermission): Unit {
    driver.execute(-1382713288, """
    |INSERT INTO DbUserPermission(
    |	type,
    |	createdAt
    |)
    |VALUES (?, ?)
    """.trimMargin(), 2) {
      bindString(1, database.DbUserPermissionAdapter.typeAdapter.encode(DbUserPermission.type))
      bindLong(2,
          database.DbUserPermissionAdapter.createdAtAdapter.encode(DbUserPermission.createdAt))
    }
    notifyQueries(-1382713288, {database.dbUserPermissionQueries.findByTypeIn +
        database.dbUserPermissionQueries.findByType + database.dbUserPermissionQueries.findById +
        database.dbUserPermissionQueries.findAll})
  }

  public override fun upsert(DbUserPermission: DbUserPermission): Unit {
    driver.execute(-1037316434, """
    |INSERT OR REPLACE INTO DbUserPermission(id, type, createdAt)
    |VALUES (?, ?, ?)
    """.trimMargin(), 3) {
      bindLong(1, database.DbUserPermissionAdapter.idAdapter.encode(DbUserPermission.id))
      bindString(2, database.DbUserPermissionAdapter.typeAdapter.encode(DbUserPermission.type))
      bindLong(3,
          database.DbUserPermissionAdapter.createdAtAdapter.encode(DbUserPermission.createdAt))
    }
    notifyQueries(-1037316434, {database.dbUserPermissionQueries.findByTypeIn +
        database.dbUserPermissionQueries.findByType + database.dbUserPermissionQueries.findById +
        database.dbUserPermissionQueries.findAll})
  }

  public override fun delete(id: UserPermissionId): Unit {
    driver.execute(-1534379222, """DELETE FROM DbUserPermission WHERE id = ?""", 1) {
      bindLong(1, database.DbUserPermissionAdapter.idAdapter.encode(id))
    }
    notifyQueries(-1534379222, {database.dbUserPermissionQueries.findByTypeIn +
        database.dbUserPermissionQueries.findByType + database.dbUserPermissionQueries.findById +
        database.dbUserPermissionQueries.findAll})
  }

  public override fun deleteAll(): Unit {
    driver.execute(645594647, """DELETE FROM DbUserPermission""", 0)
    notifyQueries(645594647, {database.dbUserPermissionQueries.findByTypeIn +
        database.dbUserPermissionQueries.findByType + database.dbUserPermissionQueries.findById +
        database.dbUserPermissionQueries.findAll})
  }

  private inner class FindByIdQuery<out T : Any>(
    public val id: UserPermissionId,
    mapper: (SqlCursor) -> T
  ) : Query<T>(findById, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(1430885098, """
    |SELECT *
    |FROM DbUserPermission
    |WHERE id = ?
    """.trimMargin(), 1) {
      bindLong(1, database.DbUserPermissionAdapter.idAdapter.encode(id))
    }

    public override fun toString(): String = "DbUserPermission.sq:findById"
  }

  private inner class FindByTypeQuery<out T : Any>(
    public val type: RawUserPermissionType,
    mapper: (SqlCursor) -> T
  ) : Query<T>(findByType, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(691395913, """
    |SELECT *
    |FROM DbUserPermission
    |WHERE type = ?
    """.trimMargin(), 1) {
      bindString(1, database.DbUserPermissionAdapter.typeAdapter.encode(type))
    }

    public override fun toString(): String = "DbUserPermission.sq:findByType"
  }

  private inner class FindByTypeInQuery<out T : Any>(
    public val type: Collection<RawUserPermissionType>,
    mapper: (SqlCursor) -> T
  ) : Query<T>(findByTypeIn, mapper) {
    public override fun execute(): SqlCursor {
      val typeIndexes = createArguments(count = type.size)
      return driver.executeQuery(null, """
      |SELECT *
      |FROM DbUserPermission
      |WHERE type IN $typeIndexes
      """.trimMargin(), type.size) {
        type.forEachIndexed { index, type_ ->
            bindString(index + 1, database.DbUserPermissionAdapter.typeAdapter.encode(type_))
            }
      }
    }

    public override fun toString(): String = "DbUserPermission.sq:findByTypeIn"
  }
}

private class DbUserFavoriteQueriesImpl(
  private val database: GGDatabaseImpl,
  private val driver: SqlDriver
) : TransacterImpl(driver), DbUserFavoriteQueries {
  internal val findAll: MutableList<Query<*>> = copyOnWriteList()

  internal val findById: MutableList<Query<*>> = copyOnWriteList()

  internal val getByType: MutableList<Query<*>> = copyOnWriteList()

  internal val findByTypeAndValue: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> findAll(mapper: (
    id: UserFavoriteId,
    type: RawUserFavoriteType,
    value_: String,
    createdAt: Instant,
    updatedAt: Instant
  ) -> T): Query<T> = Query(1226043676, findAll, driver, "DbUserFavorite.sq", "findAll", """
  |SELECT *
  |FROM DbUserFavorite
  """.trimMargin()) { cursor ->
    mapper(
      database.DbUserFavoriteAdapter.idAdapter.decode(cursor.getLong(0)!!),
      database.DbUserFavoriteAdapter.typeAdapter.decode(cursor.getString(1)!!),
      cursor.getString(2)!!,
      database.DbUserFavoriteAdapter.createdAtAdapter.decode(cursor.getLong(3)!!),
      database.DbUserFavoriteAdapter.updatedAtAdapter.decode(cursor.getLong(4)!!)
    )
  }

  public override fun findAll(): Query<DbUserFavorite> = findAll { id, type, value_, createdAt,
      updatedAt ->
    DbUserFavorite(
      id,
      type,
      value_,
      createdAt,
      updatedAt
    )
  }

  public override fun <T : Any> findById(id: UserFavoriteId, mapper: (
    id: UserFavoriteId,
    type: RawUserFavoriteType,
    value_: String,
    createdAt: Instant,
    updatedAt: Instant
  ) -> T): Query<T> = FindByIdQuery(id) { cursor ->
    mapper(
      database.DbUserFavoriteAdapter.idAdapter.decode(cursor.getLong(0)!!),
      database.DbUserFavoriteAdapter.typeAdapter.decode(cursor.getString(1)!!),
      cursor.getString(2)!!,
      database.DbUserFavoriteAdapter.createdAtAdapter.decode(cursor.getLong(3)!!),
      database.DbUserFavoriteAdapter.updatedAtAdapter.decode(cursor.getLong(4)!!)
    )
  }

  public override fun findById(id: UserFavoriteId): Query<DbUserFavorite> = findById(id) { id_,
      type, value_, createdAt, updatedAt ->
    DbUserFavorite(
      id_,
      type,
      value_,
      createdAt,
      updatedAt
    )
  }

  public override fun <T : Any> getByType(type: RawUserFavoriteType, mapper: (
    id: UserFavoriteId,
    type: RawUserFavoriteType,
    value_: String,
    createdAt: Instant,
    updatedAt: Instant
  ) -> T): Query<T> = GetByTypeQuery(type) { cursor ->
    mapper(
      database.DbUserFavoriteAdapter.idAdapter.decode(cursor.getLong(0)!!),
      database.DbUserFavoriteAdapter.typeAdapter.decode(cursor.getString(1)!!),
      cursor.getString(2)!!,
      database.DbUserFavoriteAdapter.createdAtAdapter.decode(cursor.getLong(3)!!),
      database.DbUserFavoriteAdapter.updatedAtAdapter.decode(cursor.getLong(4)!!)
    )
  }

  public override fun getByType(type: RawUserFavoriteType): Query<DbUserFavorite> =
      getByType(type) { id, type_, value_, createdAt, updatedAt ->
    DbUserFavorite(
      id,
      type_,
      value_,
      createdAt,
      updatedAt
    )
  }

  public override fun <T : Any> findByTypeAndValue(
    type: RawUserFavoriteType,
    value_: String,
    mapper: (
      id: UserFavoriteId,
      type: RawUserFavoriteType,
      value_: String,
      createdAt: Instant,
      updatedAt: Instant
    ) -> T
  ): Query<T> = FindByTypeAndValueQuery(type, value_) { cursor ->
    mapper(
      database.DbUserFavoriteAdapter.idAdapter.decode(cursor.getLong(0)!!),
      database.DbUserFavoriteAdapter.typeAdapter.decode(cursor.getString(1)!!),
      cursor.getString(2)!!,
      database.DbUserFavoriteAdapter.createdAtAdapter.decode(cursor.getLong(3)!!),
      database.DbUserFavoriteAdapter.updatedAtAdapter.decode(cursor.getLong(4)!!)
    )
  }

  public override fun findByTypeAndValue(type: RawUserFavoriteType, value_: String):
      Query<DbUserFavorite> = findByTypeAndValue(type, value_) { id, type_, value__, createdAt,
      updatedAt ->
    DbUserFavorite(
      id,
      type_,
      value__,
      createdAt,
      updatedAt
    )
  }

  public override fun insert(DbUserFavorite: DbUserFavorite): Unit {
    driver.execute(-423983035, """
    |INSERT INTO DbUserFavorite(
    |	type,
    |	value,
    |	createdAt,
    |	updatedAt
    |)
    |VALUES (?, ?, ?, ?)
    """.trimMargin(), 4) {
      bindString(1, database.DbUserFavoriteAdapter.typeAdapter.encode(DbUserFavorite.type))
      bindString(2, DbUserFavorite.value_)
      bindLong(3, database.DbUserFavoriteAdapter.createdAtAdapter.encode(DbUserFavorite.createdAt))
      bindLong(4, database.DbUserFavoriteAdapter.updatedAtAdapter.encode(DbUserFavorite.updatedAt))
    }
    notifyQueries(-423983035, {database.dbUserFavoriteQueries.findById +
        database.dbUserFavoriteQueries.findByTypeAndValue + database.dbUserFavoriteQueries.findAll +
        database.dbUserFavoriteQueries.getByType})
  }

  public override fun upsert(DbUserFavorite: DbUserFavorite): Unit {
    driver.execute(-78586181, """
    |INSERT OR REPLACE INTO DbUserFavorite(id, type, value, createdAt, updatedAt)
    |VALUES (?, ?, ?, ?, ?)
    """.trimMargin(), 5) {
      bindLong(1, database.DbUserFavoriteAdapter.idAdapter.encode(DbUserFavorite.id))
      bindString(2, database.DbUserFavoriteAdapter.typeAdapter.encode(DbUserFavorite.type))
      bindString(3, DbUserFavorite.value_)
      bindLong(4, database.DbUserFavoriteAdapter.createdAtAdapter.encode(DbUserFavorite.createdAt))
      bindLong(5, database.DbUserFavoriteAdapter.updatedAtAdapter.encode(DbUserFavorite.updatedAt))
    }
    notifyQueries(-78586181, {database.dbUserFavoriteQueries.findById +
        database.dbUserFavoriteQueries.findByTypeAndValue + database.dbUserFavoriteQueries.findAll +
        database.dbUserFavoriteQueries.getByType})
  }

  public override fun delete(id: UserFavoriteId): Unit {
    driver.execute(-575648969, """DELETE FROM DbUserFavorite WHERE id = ?""", 1) {
      bindLong(1, database.DbUserFavoriteAdapter.idAdapter.encode(id))
    }
    notifyQueries(-575648969, {database.dbUserFavoriteQueries.findById +
        database.dbUserFavoriteQueries.findByTypeAndValue + database.dbUserFavoriteQueries.findAll +
        database.dbUserFavoriteQueries.getByType})
  }

  public override fun deleteAll(): Unit {
    driver.execute(646043370, """DELETE FROM DbUserFavorite""", 0)
    notifyQueries(646043370, {database.dbUserFavoriteQueries.findById +
        database.dbUserFavoriteQueries.findByTypeAndValue + database.dbUserFavoriteQueries.findAll +
        database.dbUserFavoriteQueries.getByType})
  }

  private inner class FindByIdQuery<out T : Any>(
    public val id: UserFavoriteId,
    mapper: (SqlCursor) -> T
  ) : Query<T>(findById, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-647310409, """
    |SELECT *
    |FROM DbUserFavorite
    |WHERE id = ?
    """.trimMargin(), 1) {
      bindLong(1, database.DbUserFavoriteAdapter.idAdapter.encode(id))
    }

    public override fun toString(): String = "DbUserFavorite.sq:findById"
  }

  private inner class GetByTypeQuery<out T : Any>(
    public val type: RawUserFavoriteType,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getByType, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(1325854235, """
    |SELECT *
    |FROM DbUserFavorite
    |WHERE type = ?
    """.trimMargin(), 1) {
      bindString(1, database.DbUserFavoriteAdapter.typeAdapter.encode(type))
    }

    public override fun toString(): String = "DbUserFavorite.sq:getByType"
  }

  private inner class FindByTypeAndValueQuery<out T : Any>(
    public val type: RawUserFavoriteType,
    public val value_: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(findByTypeAndValue, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(1089012432, """
    |SELECT *
    |FROM DbUserFavorite
    |WHERE type = ?
    |AND value = ?
    """.trimMargin(), 2) {
      bindString(1, database.DbUserFavoriteAdapter.typeAdapter.encode(type))
      bindString(2, value_)
    }

    public override fun toString(): String = "DbUserFavorite.sq:findByTypeAndValue"
  }
}

private class DbPlaylistTrackQueriesImpl(
  private val database: GGDatabaseImpl,
  private val driver: SqlDriver
) : TransacterImpl(driver), DbPlaylistTrackQueries {
  internal val findAll: MutableList<Query<*>> = copyOnWriteList()

  internal val findById: MutableList<Query<*>> = copyOnWriteList()

  internal val findByIds: MutableList<Query<*>> = copyOnWriteList()

  internal val findByTrackIds: MutableList<Query<*>> = copyOnWriteList()

  internal val getTracksForPlaylist: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> findAll(mapper: (
    id: PlaylistTrackId,
    playlistId: PlaylistId,
    trackId: TrackId,
    sortOrder: Int,
    createdAt: Instant
  ) -> T): Query<T> = Query(1205522520, findAll, driver, "DbPlaylistTrack.sq", "findAll", """
  |SELECT *
  |FROM DbPlaylistTrack
  """.trimMargin()) { cursor ->
    mapper(
      database.DbPlaylistTrackAdapter.idAdapter.decode(cursor.getLong(0)!!),
      database.DbPlaylistTrackAdapter.playlistIdAdapter.decode(cursor.getLong(1)!!),
      database.DbPlaylistTrackAdapter.trackIdAdapter.decode(cursor.getLong(2)!!),
      cursor.getLong(3)!!.toInt(),
      database.DbPlaylistTrackAdapter.createdAtAdapter.decode(cursor.getLong(4)!!)
    )
  }

  public override fun findAll(): Query<DbPlaylistTrack> = findAll { id, playlistId, trackId,
      sortOrder, createdAt ->
    DbPlaylistTrack(
      id,
      playlistId,
      trackId,
      sortOrder,
      createdAt
    )
  }

  public override fun <T : Any> findById(id: PlaylistTrackId, mapper: (
    id: PlaylistTrackId,
    playlistId: PlaylistId,
    trackId: TrackId,
    sortOrder: Int,
    createdAt: Instant
  ) -> T): Query<T> = FindByIdQuery(id) { cursor ->
    mapper(
      database.DbPlaylistTrackAdapter.idAdapter.decode(cursor.getLong(0)!!),
      database.DbPlaylistTrackAdapter.playlistIdAdapter.decode(cursor.getLong(1)!!),
      database.DbPlaylistTrackAdapter.trackIdAdapter.decode(cursor.getLong(2)!!),
      cursor.getLong(3)!!.toInt(),
      database.DbPlaylistTrackAdapter.createdAtAdapter.decode(cursor.getLong(4)!!)
    )
  }

  public override fun findById(id: PlaylistTrackId): Query<DbPlaylistTrack> = findById(id) { id_,
      playlistId, trackId, sortOrder, createdAt ->
    DbPlaylistTrack(
      id_,
      playlistId,
      trackId,
      sortOrder,
      createdAt
    )
  }

  public override fun <T : Any> findByIds(id: Collection<PlaylistTrackId>, mapper: (
    id: PlaylistTrackId,
    playlistId: PlaylistId,
    trackId: TrackId,
    sortOrder: Int,
    createdAt: Instant
  ) -> T): Query<T> = FindByIdsQuery(id) { cursor ->
    mapper(
      database.DbPlaylistTrackAdapter.idAdapter.decode(cursor.getLong(0)!!),
      database.DbPlaylistTrackAdapter.playlistIdAdapter.decode(cursor.getLong(1)!!),
      database.DbPlaylistTrackAdapter.trackIdAdapter.decode(cursor.getLong(2)!!),
      cursor.getLong(3)!!.toInt(),
      database.DbPlaylistTrackAdapter.createdAtAdapter.decode(cursor.getLong(4)!!)
    )
  }

  public override fun findByIds(id: Collection<PlaylistTrackId>): Query<DbPlaylistTrack> =
      findByIds(id) { id_, playlistId, trackId, sortOrder, createdAt ->
    DbPlaylistTrack(
      id_,
      playlistId,
      trackId,
      sortOrder,
      createdAt
    )
  }

  public override fun <T : Any> findByTrackIds(trackId: Collection<TrackId>, mapper: (
    id: PlaylistTrackId,
    playlistId: PlaylistId,
    trackId: TrackId,
    sortOrder: Int,
    createdAt: Instant
  ) -> T): Query<T> = FindByTrackIdsQuery(trackId) { cursor ->
    mapper(
      database.DbPlaylistTrackAdapter.idAdapter.decode(cursor.getLong(0)!!),
      database.DbPlaylistTrackAdapter.playlistIdAdapter.decode(cursor.getLong(1)!!),
      database.DbPlaylistTrackAdapter.trackIdAdapter.decode(cursor.getLong(2)!!),
      cursor.getLong(3)!!.toInt(),
      database.DbPlaylistTrackAdapter.createdAtAdapter.decode(cursor.getLong(4)!!)
    )
  }

  public override fun findByTrackIds(trackId: Collection<TrackId>): Query<DbPlaylistTrack> =
      findByTrackIds(trackId) { id, playlistId, trackId_, sortOrder, createdAt ->
    DbPlaylistTrack(
      id,
      playlistId,
      trackId_,
      sortOrder,
      createdAt
    )
  }

  public override fun <T : Any> getTracksForPlaylist(
    playlistId: PlaylistId,
    genericFilter: String?,
    mapper: (
      id: PlaylistTrackId,
      playlistId: PlaylistId,
      trackId: TrackId,
      sortOrder: Int,
      createdAt: Instant
    ) -> T
  ): Query<T> = GetTracksForPlaylistQuery(playlistId, genericFilter) { cursor ->
    mapper(
      database.DbPlaylistTrackAdapter.idAdapter.decode(cursor.getLong(0)!!),
      database.DbPlaylistTrackAdapter.playlistIdAdapter.decode(cursor.getLong(1)!!),
      database.DbPlaylistTrackAdapter.trackIdAdapter.decode(cursor.getLong(2)!!),
      cursor.getLong(3)!!.toInt(),
      database.DbPlaylistTrackAdapter.createdAtAdapter.decode(cursor.getLong(4)!!)
    )
  }

  public override fun getTracksForPlaylist(playlistId: PlaylistId, genericFilter: String?):
      Query<DbPlaylistTrack> = getTracksForPlaylist(playlistId, genericFilter) { id, playlistId_,
      trackId, sortOrder, createdAt ->
    DbPlaylistTrack(
      id,
      playlistId_,
      trackId,
      sortOrder,
      createdAt
    )
  }

  public override fun upsert(DbPlaylistTrack: DbPlaylistTrack): Unit {
    driver.execute(752035839, """
    |INSERT OR REPLACE INTO DbPlaylistTrack (id, playlistId, trackId, sortOrder, createdAt)
    |VALUES (?, ?, ?, ?, ?)
    """.trimMargin(), 5) {
      bindLong(1, database.DbPlaylistTrackAdapter.idAdapter.encode(DbPlaylistTrack.id))
      bindLong(2,
          database.DbPlaylistTrackAdapter.playlistIdAdapter.encode(DbPlaylistTrack.playlistId))
      bindLong(3, database.DbPlaylistTrackAdapter.trackIdAdapter.encode(DbPlaylistTrack.trackId))
      bindLong(4, DbPlaylistTrack.sortOrder.toLong())
      bindLong(5,
          database.DbPlaylistTrackAdapter.createdAtAdapter.encode(DbPlaylistTrack.createdAt))
    }
    notifyQueries(752035839, {database.dbPlaylistTrackQueries.getTracksForPlaylist +
        database.dbPlaylistTrackQueries.findByTrackIds + database.dbPlaylistTrackQueries.findById +
        database.dbPlaylistTrackQueries.findByIds + database.dbPlaylistTrackQueries.findAll})
  }

  public override fun delete(id: PlaylistTrackId): Unit {
    driver.execute(254973051, """DELETE FROM DbPlaylistTrack WHERE id = ?""", 1) {
      bindLong(1, database.DbPlaylistTrackAdapter.idAdapter.encode(id))
    }
    notifyQueries(254973051, {database.dbPlaylistTrackQueries.getTracksForPlaylist +
        database.dbPlaylistTrackQueries.findByTrackIds + database.dbPlaylistTrackQueries.findById +
        database.dbPlaylistTrackQueries.findByIds + database.dbPlaylistTrackQueries.findAll})
  }

  public override fun deleteAll(): Unit {
    driver.execute(-1894918362, """DELETE FROM DbPlaylistTrack""", 0)
    notifyQueries(-1894918362, {database.dbPlaylistTrackQueries.getTracksForPlaylist +
        database.dbPlaylistTrackQueries.findByTrackIds + database.dbPlaylistTrackQueries.findById +
        database.dbPlaylistTrackQueries.findByIds + database.dbPlaylistTrackQueries.findAll})
  }

  public override fun deleteFromPlaylist(playlistId: PlaylistId): Unit {
    driver.execute(372226999, """DELETE FROM DbPlaylistTrack WHERE playlistId = ?""", 1) {
      bindLong(1, database.DbPlaylistTrackAdapter.playlistIdAdapter.encode(playlistId))
    }
    notifyQueries(372226999, {database.dbPlaylistTrackQueries.getTracksForPlaylist +
        database.dbPlaylistTrackQueries.findByTrackIds + database.dbPlaylistTrackQueries.findById +
        database.dbPlaylistTrackQueries.findByIds + database.dbPlaylistTrackQueries.findAll})
  }

  public override fun deleteByTrack(trackId: TrackId): Unit {
    driver.execute(1650075193, """DELETE FROM DbPlaylistTrack WHERE trackId = ?""", 1) {
      bindLong(1, database.DbPlaylistTrackAdapter.trackIdAdapter.encode(trackId))
    }
    notifyQueries(1650075193, {database.dbPlaylistTrackQueries.getTracksForPlaylist +
        database.dbPlaylistTrackQueries.findByTrackIds + database.dbPlaylistTrackQueries.findById +
        database.dbPlaylistTrackQueries.findByIds + database.dbPlaylistTrackQueries.findAll})
  }

  public override fun deleteByTracks(trackId: Collection<TrackId>): Unit {
    val trackIdIndexes = createArguments(count = trackId.size)
    driver.execute(null, """DELETE FROM DbPlaylistTrack WHERE trackId IN $trackIdIndexes""",
        trackId.size) {
      trackId.forEachIndexed { index, trackId_ ->
          bindLong(index + 1, database.DbPlaylistTrackAdapter.trackIdAdapter.encode(trackId_))
          }
    }
    notifyQueries(-387276454, {database.dbPlaylistTrackQueries.getTracksForPlaylist +
        database.dbPlaylistTrackQueries.findByTrackIds + database.dbPlaylistTrackQueries.findById +
        database.dbPlaylistTrackQueries.findByIds + database.dbPlaylistTrackQueries.findAll})
  }

  private inner class FindByIdQuery<out T : Any>(
    public val id: PlaylistTrackId,
    mapper: (SqlCursor) -> T
  ) : Query<T>(findById, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-1283466245, """
    |SELECT *
    |FROM DbPlaylistTrack
    |WHERE id = ?
    """.trimMargin(), 1) {
      bindLong(1, database.DbPlaylistTrackAdapter.idAdapter.encode(id))
    }

    public override fun toString(): String = "DbPlaylistTrack.sq:findById"
  }

  private inner class FindByIdsQuery<out T : Any>(
    public val id: Collection<PlaylistTrackId>,
    mapper: (SqlCursor) -> T
  ) : Query<T>(findByIds, mapper) {
    public override fun execute(): SqlCursor {
      val idIndexes = createArguments(count = id.size)
      return driver.executeQuery(null, """
      |SELECT *
      |FROM DbPlaylistTrack
      |WHERE id IN $idIndexes
      """.trimMargin(), id.size) {
        id.forEachIndexed { index, id_ ->
            bindLong(index + 1, database.DbPlaylistTrackAdapter.idAdapter.encode(id_))
            }
      }
    }

    public override fun toString(): String = "DbPlaylistTrack.sq:findByIds"
  }

  private inner class FindByTrackIdsQuery<out T : Any>(
    public val trackId: Collection<TrackId>,
    mapper: (SqlCursor) -> T
  ) : Query<T>(findByTrackIds, mapper) {
    public override fun execute(): SqlCursor {
      val trackIdIndexes = createArguments(count = trackId.size)
      return driver.executeQuery(null, """
      |SELECT *
      |FROM DbPlaylistTrack
      |WHERE trackId IN $trackIdIndexes
      """.trimMargin(), trackId.size) {
        trackId.forEachIndexed { index, trackId_ ->
            bindLong(index + 1, database.DbPlaylistTrackAdapter.trackIdAdapter.encode(trackId_))
            }
      }
    }

    public override fun toString(): String = "DbPlaylistTrack.sq:findByTrackIds"
  }

  private inner class GetTracksForPlaylistQuery<out T : Any>(
    public val playlistId: PlaylistId,
    public val genericFilter: String?,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getTracksForPlaylist, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-2043130323, """
    |SELECT pt.*
    |FROM DbPlaylistTrack pt
    |LEFT JOIN DbTrack t
    |  ON pt.trackId = t.id
    |WHERE playlistId = ?
    |AND (
    |  (? IS NULL) OR
    |  (t.name LIKE '%' || ? || '%') OR
    |  (t.artist LIKE '%' || ? || '%') OR
    |  (t.featuring LIKE '%' || ? || '%') OR
    |  (t.album LIKE '%' || ? || '%') OR
    |  (t.genre LIKE '%' || ? || '%') OR
    |  (t.note LIKE '%' || ? || '%')
    |)
    |ORDER BY sortOrder ASC
    """.trimMargin(), 8) {
      bindLong(1, database.DbPlaylistTrackAdapter.playlistIdAdapter.encode(playlistId))
      bindString(2, genericFilter)
      bindString(3, genericFilter)
      bindString(4, genericFilter)
      bindString(5, genericFilter)
      bindString(6, genericFilter)
      bindString(7, genericFilter)
      bindString(8, genericFilter)
    }

    public override fun toString(): String = "DbPlaylistTrack.sq:getTracksForPlaylist"
  }
}
