@file:Suppress("FunctionName")

package components

import AuthUtil
import Dialog
import PageRouter
import kotlinx.browser.document
import kotlinx.coroutines.launch
import kotlinx.html.*
import kotlinx.html.dom.create
import kotlinx.html.js.div
import kotlinx.html.js.onClickFunction
import kotlinx.html.js.onSubmitFunction
import mainScope
import net.gorillagroove.api.BadRequestException
import net.gorillagroove.authentication.AuthService
import net.gorillagroove.authentication.PasswordResetService
import net.gorillagroove.util.GGLog
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.GGLog.logInfo
import onSubmitSuspend
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.events.Event
import queryId

private val emailInput: HTMLInputElement get() = document.queryId("email-input")

@Suppress("FunctionName")
fun Login(): HTMLDivElement {
    suspend fun attemptLogin(e: Event) {
        e.preventDefault()

        val passwordInput: HTMLInputElement = document.getElementById("password-input") as HTMLInputElement

        GGLog.logInfo("Attempting login with email: ${emailInput.value}")

        actionButtonChangeState("login-button", isUpdating = true)

        try {
            AuthService.login(email = emailInput.value, password = passwordInput.value)
        } catch (e: Exception) {
            if (e is BadRequestException && e.response.status == 403) {
                Toast.error("Invalid credentials")
            } else {
                Toast.error("Failed to log in")
            }

            GGLog.logError("Failed to log in!", e)
            actionButtonChangeState("login-button", isUpdating = false)

            return
        }

        PageRouter.removeQueryParam("email")
        PageRouter.navigateTo("/")
    }

    // Already logged in. Redirect
    if (AuthService.isAuthenticated()) {
        AuthUtil.setupAuthenticatedUser()
        PageRouter.navigateTo("/")
        return document.create.div {  }
    }

    return document.create.div {
        id = "login"

        div {
            id = "login-section"

            h1 {
                + "Gorilla Groove"
            }
            h2 {
                + "Ultimate"
            }

            form {
                onSubmitFunction = {
                    mainScope.launch {
                        attemptLogin(it)
                    }
                }

                label("space-between full-width") {
                    span {
                        + "Email"
                    }

                    input(type = InputType.email) {
                        id = "email-input"
                        autoFocus = true

                        // This is set if we came here from account creation
                        PageRouter.getQueryParam("email")?.let { email ->
                            value = email
                        }
                    }
                }

                div {
                    label("space-between full-width") {
                        span {
                            +"Password"
                        }

                        input(type = InputType.password) {
                            id = "password-input"
                        }
                    }
                }

                ActionButton("login-button", "Let's groove")
            }

            small("clickable") {
                + "Forgot your password?"

                onClickFunction = {
                    Dialog.show(ForgotPasswordModal())
                }
            }
        }
    }
}

private fun ForgotPasswordModal() = document.create.div {
    h3("mt-4") {
        + "Password Reset"
    }

    form {
        onSubmitSuspend = onSubmit@{
            val email = document.queryId<HTMLInputElement>("password-reset-email").value
            if (email.isBlank()) {
                Toast.info("Email must not be empty")
                return@onSubmit
            }

            try {
                actionButtonChangeState("password-reset-button", isUpdating = true)
                PasswordResetService.resetPassword(email)
            } catch (e: Exception) {
                Toast.error("Password reset failed")
                GGLog.logError("Password reset failed!", e)
                actionButtonChangeState("password-reset-button", isUpdating = false)
                return@onSubmit
            }

            Toast.success("Password reset successful. Check your email")

            Dialog.remove()
        }

        label {
            + "Email"

            input(InputType.email, classes = "ml-8") {
                id = "password-reset-email"
            }
        }

        div("mt-12") {
            ActionButton("password-reset-button", "Reset")
        }
    }
}
