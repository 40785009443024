package net.gorillagroove.review

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.datetime.Clock.System.now
import kotlinx.serialization.Serializable
import net.gorillagroove.GGCommonInternal.doAsyncIfProduction
import net.gorillagroove.api.*
import net.gorillagroove.db.Database
import net.gorillagroove.db.Database.reviewSourceDao
import net.gorillagroove.db.Database.trackDao
import net.gorillagroove.db.many
import net.gorillagroove.db.oneOrNull
import net.gorillagroove.sync.SyncCoordinator
import net.gorillagroove.sync.SyncableEntity
import net.gorillagroove.sync.strategies.ReviewSourceResponse
import net.gorillagroove.track.*
import net.gorillagroove.track.toTracks
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.GGLog.logInfo
import net.gorillagroove.util.Lock
import net.gorillagroove.util.use

typealias ReviewQueueChangeHandler = () -> Unit

@Suppress("VARIABLE_IN_SINGLETON_WITHOUT_THREAD_LOCAL")
object ReviewQueueService {

    fun findById(id: ReviewSourceId): ReviewSource? = reviewSourceDao.findById(id).oneOrNull()?.toReviewSource()

    fun findAll(): List<ReviewSource> = reviewSourceDao.findAll().many().map { it.toReviewSource() }

    /**
     * @param sourceInput Can be either a channel's title OR the URL to the channel
     */
    @Throws(Throwable::class)
    suspend fun subscribeToYoutubeChannel(sourceInput: String): ReviewSource {
        logInfo("Subscribing to Youtube Channel: $sourceInput")

        val request = if (sourceInput.startsWith("https:")) {
            AddYoutubeChannelRequest(channelUrl = sourceInput)
        } else {
            AddYoutubeChannelRequest(channelTitle = sourceInput)
        }

        val response: ReviewSourceResponse = Api.post("review-queue/subscribe/youtube-channel", request)
        val newSource = response.asReviewSource()
        reviewSourceDao.upsert(newSource)

        Database.forceSave()

        return newSource.toReviewSource()
    }

    @Throws(Throwable::class)
    suspend fun subscribeToSpotifyArtist(artistName: String): ReviewSource {
        logInfo("Subscribing to Spotify Artist: $artistName")

        val request = AddArtistSourceRequest(artistName)

        val response: ReviewSourceResponse = Api.post("review-queue/subscribe/artist", request)
        val newSource = response.asReviewSource()
        reviewSourceDao.upsert(newSource)

        Database.forceSave()

        return newSource.toReviewSource()
    }

    @Throws(Throwable::class)
    suspend fun rejectReviewTrack(track: Track) {
        return rejectReviewTracks(listOf(track))
    }
    
    @Throws(Throwable::class)
    suspend fun rejectReviewTracks(tracks: Collection<Track>) {
        val rejectIds = tracks.map { it.id }
        val logIds = rejectIds.map { it.value }.toString()

        logInfo("Rejecting tracks: $logIds")

        val wasPlaying = NowPlayingService.isPlaying

        // If someone rejects the song it probably means they really don't want it to keep playing
        if (rejectIds.contains(NowPlayingService.currentTrack?.id)) {
            NowPlayingService.pause()
        }

        try {
            val request = BulkTrackReviewRequest(rejectIds = tracks.map { it.apiId!! })
            Api.post<Unit>("review-queue/track/bulk-review", request)
        } catch (e: Throwable) {
            logError("Failed to reject review track $logIds!", e)
            throw e
        }

        trackDao.deleteByIds(rejectIds)

        Database.forceSave()

        startOrGetSession().playNextAfterReview(tracks, wasPlaying)

        TrackService.broadcastTrackChange(tracks, ChangeType.DELETED)
    }

    @Throws(Throwable::class)
    suspend fun approveReviewTrack(track: Track): Track {
        return approveReviewTracks(listOf(track)).first()
    }
    
    @Throws(Throwable::class)
    suspend fun approveReviewTracks(tracks: Collection<Track>): List<Track> {
        val logIds = tracks.map { it.id.value }.toString()
        logInfo("Approving tracks: $logIds")
        
        try {
            val request = BulkTrackReviewRequest(approveIds = tracks.map { it.apiId!! })
            Api.post<Unit>("review-queue/track/bulk-review", request)
        } catch (e: Throwable) {
            logError("Failed to approve review tracks $logIds!", e)
            throw e
        }

        // Network requests can be slow. Make sure we have the latest track info (background syncs are a thing)
        val savedTracks = tracks.map { track ->
            val refreshedTrack = TrackService.findDbById(track.id) ?: run {
                logError("Failed to find refreshed track with ID ${track.id}!")
                throw IllegalStateException("Track no longer exists to be approved!")
            }

            // This stuff will get synced later with API values.
            // But may as well update it ahead of time since we know roughly what it'll be
            val saveTrack = refreshedTrack.copy(
                inReview = false,
                addedToLibrary = now()
            )

            trackDao.upsert(saveTrack)
            saveTrack
        }

        Database.forceSave()

        startOrGetSession().playNextAfterReview(tracks, NowPlayingService.isPlaying)

        return savedTracks.toTracks()
    }

    @Throws(Throwable::class)
    suspend fun deleteReviewSource(id: ReviewSourceId) {
        logInfo("Deleting Review Source ${id.value}")

        try {
            Api.delete<Unit>("review-queue/${id.value}")
        } catch (e: Throwable) {
            logError("Failed to delete Review Source ${id.value}!", e)
            throw e
        }

        trackDao.deleteInReviewTracksOnReviewSource(id)
        reviewSourceDao.delete(id)

        Database.forceSave()
    }

    private var activeSession: ReviewSession? = null
    internal var sessionInvalidated: Boolean = false

    private val lock = Lock()
    private val scope = CoroutineScope(Dispatchers.Default)

    fun startOrGetSession(): ReviewSession = lock.use {
        activeSession?.let { activeSession ->
            // If we synced review stuff since the session was created, then we should make a new session.
            if (!sessionInvalidated) {
                return activeSession
            }
        }

        return createSession().also { session ->
            activeSession = session
            sessionInvalidated = false
        }
    }

    internal fun invalidateSession() {
        sessionInvalidated = true

        doAsyncIfProduction {
            broadcastReviewTrackChange()
        }
    }

    private fun createSession(activeSourceOverride: ReviewSourceId? = null): ReviewSession {
        logInfo("Starting new ReviewSession")

        val allReviewSources = findAll().associateBy { it.id }

        val reviewSourceToTrackCount = getTrackCountPerSource().toMutableMap()
        allReviewSources.forEach { source ->
            if (!reviewSourceToTrackCount.containsKey(source.key)) {
                reviewSourceToTrackCount[source.key] = 0
            }
        }

        val sourcesNeedingReview = allReviewSources.values.filter { (reviewSourceToTrackCount[it.id] ?: 0) > 0 }.toMutableList()

        val session = ReviewSession(
            allReviewSources = allReviewSources,
            reviewSourceToTrackCount = reviewSourceToTrackCount,
            sourcesNeedingReview = sourcesNeedingReview,
        )

        if (activeSourceOverride != null) {
            session.setActiveSource(activeSourceOverride)
        } else {
            session.setNextActiveSource()
        }

        return session
    }

    fun refreshSession(): ReviewSession {
        // Provide the ID of the last active source so it doesn't change on users.
        val existingActiveSource = activeSession?.activeSource?.id

        return createSession(existingActiveSource).also { activeSession = it }
    }

    // This probably doesn't need to be called by clients of this library, but they could if they
    // desired to free up memory and knew it was no longer used.
    fun stopSession() {
        activeSession = null
    }

    @Throws(Throwable::class)
    suspend fun recommendTracks(
        trackIds: Collection<TrackApiId>,
        userIds: Collection<UserId>,
        note: String?,
    ) {
        val request = RecommendTrackRequest(
            trackIds = trackIds.toList(),
            targetUserIds = userIds.toList(),
            note = note,
        )

        logInfo("Recommending tracks to users: $request")

        try {
            Api.post<Unit>("review-queue/recommend", request)
        } catch (e: Exception) {
            logError("Failed to recommend track(s)!", e)
            throw e
        }
    }

    /**
     * @return A map of the review source ID to the count of tracks needing review. 0 is not returned.
     */
    fun getTrackCountPerSource(): Map<ReviewSourceId, Int> {
        val count = reviewSourceDao.getNeedingReviewTrackCountByQueue().many()
        return count.associate { it.id to it.count.toInt() }
    }

    /**
     * @return A list of non-USER_RECOMMEND sources
     */
    fun getEditableSources(): List<ReviewSource> {
        return reviewSourceDao.getEditableSources().many().map { it.toReviewSource() }
    }

    fun getTracksNeedingReviewOnSource(sourceId: ReviewSourceId): List<Track> {
        return trackDao.getTracksNeedingReviewOnSource(sourceId).many().toMutableList().toTracks()
    }

    @Throws(Throwable::class)
    suspend fun runReviewQueues() {
        return Api.post<Unit>("review-queue/check-new-songs")
    }

    private val handlers = mutableMapOf<Int, ReviewQueueChangeHandler>()
    private var handlerId: Int = 0

    fun registerEventHandler(handler: ReviewQueueChangeHandler): Int {
        // Friendly reminder that ++ is not atomic. We don't want to give out 2 of the same ID
        val id = lock.use { ++handlerId }
        handlers[handlerId] = handler
        return id
    }

    fun unregisterEventHandler(handlerId: Int) {
        handlers.remove(handlerId)
    }

    internal fun processSocketMessage() {
        // The track sync watches for us to sync tracks that are "inReview" so nothing more is needed from us here.
        // Once they are done being synced, we will notify.
        SyncCoordinator.syncAsync(types = setOf(SyncableEntity.TRACK))
    }

    private fun broadcastReviewTrackChange() {
        handlers.values.forEach { it() }
    }
}

@Serializable
internal data class AddYoutubeChannelRequest(
    val channelUrl: String? = null,
    val channelTitle: String? = null
)

@Serializable
internal data class AddArtistSourceRequest(val artistName: String)

@Serializable
internal data class RecommendTrackRequest(
    val trackIds: List<TrackApiId>,
    val targetUserIds: List<UserId>,
    val note: String?,
)

@Serializable
internal class BulkTrackReviewRequest(
    val approveIds: Collection<TrackApiId> = emptySet(),
    val rejectIds: Collection<TrackApiId> = emptySet(),
)

