package net.gorillagroove.sync.strategies

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import net.gorillagroove.api.PlaylistId
import net.gorillagroove.api.PlaylistUserId
import net.gorillagroove.api.UserId
import net.gorillagroove.db.*
import net.gorillagroove.db.Database.playlistDao
import net.gorillagroove.db.Database.playlistTrackDao
import net.gorillagroove.db.Database.playlistUserDao
import net.gorillagroove.localstorage.CurrentUserStore
import net.gorillagroove.playlist.PlaylistOwnershipType
import net.gorillagroove.playlist.PlaylistService
import net.gorillagroove.playlist.RawPlaylistOwnershipType
import net.gorillagroove.sync.isFirstSync
import net.gorillagroove.sync.*
import net.gorillagroove.track.TrackService
import net.gorillagroove.util.GGLog.logDebug

object PlaylistUserSyncStrategy : SyncDownStrategy {
    override val syncType: SyncableEntity = SyncableEntity.PLAYLIST_USER

    override suspend fun syncDown(syncStatus: DbSyncStatus, onPageSyncedHandler: PageSyncHandler) {
        val currentUserId = CurrentUserStore.getInfo()?.id
            ?: throw IllegalStateException("Current user not found while syncing PlaylistTracks!")

        fetchSyncEntities<PlaylistUserResponse>(syncType, syncStatus, onPageSyncedHandler) { changeSet ->
            val existingPlaylistUsers = playlistUserDao.findByUser(currentUserId).many()
                .filter { it.ownershipType.asEnumeratedType() != PlaylistOwnershipType.NONE }
                .map { it.id }
                .toSet()

            // This, unsurprisingly, means that we were added to a new playlist. The majority of the time,
            // this just means that we created a playlist and nothing special needs to happen.
            // However, if we were added to a playlist that we are NOT the owner of, then it means
            // that there could be pre-existing tracks on the playlist that need to be fetched.
            // Unfortunately, we can't rely on the updatedAt timestamp on a PlaylistTrack to sync it,
            // in the PlaylistTrackSync, because the PlaylistTracks may have not been updated in a very long time.
            val playlistsNeedingSyncing = changeSet.new.filter { playlistUserResponse ->
                // If this is a first sync, then we will be fetching all PlaylistTracks in the PlaylistTrack sync anyway.
                // Doing any fetching here would be entirely redundant.
                !syncStatus.isFirstSync
                        // If this user isn't our own user, then there's no need to fetch any additional tracks
                        && playlistUserResponse.userId == currentUserId
                        // Only fetch additional Playlist stuff if we did not have a PlaylistUser for this playlist previously.
                        // Otherwise, we'd end up re-syncing a bunch of stuff if our permission on the playlist changed.
                        && !existingPlaylistUsers.contains(playlistUserResponse.id)
            }

            if (playlistsNeedingSyncing.isNotEmpty()) {
                val playlistIds = playlistsNeedingSyncing.map { it.playlistId }
                syncPlaylists(playlistIds)
                syncTracksForPlaylists(playlistIds, currentUserId)
                syncUsersForPlaylists(playlistIds, currentUserId)
            }

            Database.db.transaction {
                changeSet.newAndModified.forEach {
                    logDebug("Saving PlaylistUser with ID ${it.id.value} that references User: ${it.userId.value} and Playlist: ${it.playlistId.value}")
                    playlistUserDao.upsert(it.asPlaylistUser())
                }
                changeSet.removed.forEach {
                    playlistUserDao.delete(PlaylistUserId(it))
                }
            }
        }
    }

    private suspend fun syncTracksForPlaylists(playlistIds: List<PlaylistId>, currentUserId: UserId) {
        fetchPageable<EntityFetchResponse<PlaylistTrackResponse>>(
            url = "playlist/track/bulk",
            pageSize = SYNC_PAGE_SIZE,
            mapOf("playlistIds" to playlistIds.map { it.value })
        ) { response ->
            Database.db.transaction {
                val apiIds = response.content.map { it.track.id }
                val trackApiIdToLocalId = TrackService.findLocalIdForApiId(apiIds).toMutableMap()
                response.content.forEach { responseItem ->
                    if (responseItem.track.userId != currentUserId) {
                        val track = responseItem.track.asTrack(trackApiIdToLocalId)
                        TrackService.save(track).also { localId ->
                            trackApiIdToLocalId[responseItem.track.id] = localId
                        }
                    }

                    val pt = responseItem.asPlaylistTrack(trackApiIdToLocalId)
                    playlistTrackDao.upsert(pt)
                }
            }
        }
    }

    // If we are on a playlist, then we are allowed to see what OTHER users are also on the playlist.
    // As with the Track sync, because the mTimes of these users weren't updated, we won't sync them down
    // if they were already on the playlist when we got added to it. So we must explicitly sync them.
    private suspend fun syncUsersForPlaylists(playlistIds: List<PlaylistId>, currentUserId: UserId) {
        playlistIds.forEach { playlistId ->
            val playlistUsers = PlaylistService.getUsersOnPlaylistFromApi(playlistId)
            playlistUsers.forEach { playlistUser ->
                if (playlistUser.userId != currentUserId) {
                    playlistUserDao.upsert(playlistUser)
                }
            }
        }
    }

    private suspend fun syncPlaylists(playlistIds: Collection<PlaylistId>) {
        val response = PlaylistService.getPlaylistsFromApi(playlistIds)
        response.forEach { playlist ->
            playlistDao.upsert(playlist.asDbPlaylist())
        }
    }
}

@Serializable
data class PlaylistUserResponse(
    val id: PlaylistUserId,
    val userId: UserId,
    val deleted: Boolean,
    val playlistId: PlaylistId,
    val ownershipType: RawPlaylistOwnershipType,
    val createdAt: Instant,
    val updatedAt: Instant,
) {
    fun asPlaylistUser() = DbPlaylistUser(
        id = id,
        userId = userId,
        playlistId = playlistId,
        ownershipType = ownershipType,
        createdAt = createdAt,
        updatedAt = updatedAt,
    )
}
