package net.gorillagroove.util

import kotlinx.datetime.Clock.System.now
import net.gorillagroove.api.UserSettingId
import net.gorillagroove.db.Database
import net.gorillagroove.db.Database.userSettingDao
import net.gorillagroove.db.DbUserSetting
import net.gorillagroove.db.many
import net.gorillagroove.db.oneOrNull
import net.gorillagroove.util.GGLog.logInfo

@Suppress("VARIABLE_IN_SINGLETON_WITHOUT_THREAD_LOCAL")
object Settings {
    private var cacheInitialized = false
    private val cache = mutableMapOf<String, String>()

    private val lock = Lock()

    // Some misc settings that I may move in the future if there's more stuff to put them together with

    val locationGatheringConfigured: Boolean get() = getBoolean(SettingType.LOCATION_GATHERING_ENABLED) != null

    var locationGatheringEnabled: Boolean
        get() = getBoolean(SettingType.LOCATION_GATHERING_ENABLED, true)
        set(value) = setBoolean(SettingType.LOCATION_GATHERING_ENABLED, value)

    // Double as a percentage, e.g. 0.2 is 20%
    var locationMinimumRequiredBattery: Double
        get() = getDouble(SettingType.LOCATION_GATHERING_BATTERY_PERCENT, .2)
        set(value) = setDouble(SettingType.LOCATION_GATHERING_BATTERY_PERCENT, value)

    // This is just some generic String that clients can use to save their launch configurations.
    // It could be the screen, it could be the view (album vs artist), or a sort direction. Or all of the above.
    var launchOptions: String
        get() = getString(SettingType.LAUNCH_OPTIONS, "")
        set(value) = setString(SettingType.LAUNCH_OPTIONS, value)

    var lastLibraryView: String
        get() = getString(SettingType.LAST_LIBRARY_VIEW, "")
        set(value) = setString(SettingType.LAST_LIBRARY_VIEW, value)

    // This is also just something clients can use to store whatever they want in whatever manner they want.
    // The idea is that they can change what happens when you tap on a track row so instead of playing
    // everything from that track, maybe they, by default, add it to the end of their playing list.
    var defaultTapBehavior: String
        get() = getString(SettingType.DEFAULT_TRACK_TAP_BEHAVIOR, "")
        set(value) = setString(SettingType.DEFAULT_TRACK_TAP_BEHAVIOR, value)

    // These two are for the frontend only. IDK where better to put them.
    var usersCollapsed: Boolean
        get() = getBoolean(SettingType.USERS_COLLAPSED, false)
        set(value) = setBoolean(SettingType.USERS_COLLAPSED, value)

    var playlistsCollapsed: Boolean
        get() = getBoolean(SettingType.PLAYLISTS_COLLAPSED, false)
        set(value) = setBoolean(SettingType.PLAYLISTS_COLLAPSED, value)

    var textSpeechVoiceIdentifier: String
        get() = getString(SettingType.TEXT_SPEECH_VOICE_IDENTIFIER, "")
        set(value) = setString(SettingType.TEXT_SPEECH_VOICE_IDENTIFIER, value)

    var textSpeechVoiceEnabled: Boolean
        get() = getBoolean(SettingType.TEXT_SPEECH_VOICE_ENABLED, true)
        set(value) = setBoolean(SettingType.TEXT_SPEECH_VOICE_ENABLED, value)

    private fun initializeIfNeeded() {
        if (cacheInitialized) {
            return
        }

        userSettingDao.findAll().many().forEach { userSetting ->
            cache[userSetting.key] = userSetting.value_
        }
        cacheInitialized = true
    }

    internal fun clearCache() = lock.use {
        if (cacheInitialized) {
            cache.clear()
            cacheInitialized = false
        }
    }

    internal fun getString(key: SettingType): String? = lock.use {
        initializeIfNeeded()
        return cache[key.dbKey]
    }
    internal fun getString(key: SettingType, default: String): String = getString(key) ?: default

    internal fun getBoolean(key: SettingType): Boolean? = lock.use {
        initializeIfNeeded()
        return cache[key.dbKey]?.toBoolean()
    }
    internal fun getBoolean(key: SettingType, default: Boolean) = getBoolean(key) ?: default

    internal fun getInt(key: SettingType): Int? = lock.use {
        initializeIfNeeded()
        return cache[key.dbKey]?.toInt()
    }
    internal fun getInt(key: SettingType, default: Int): Int = getInt(key) ?: default

    internal fun getLong(key: SettingType): Long? = lock.use {
        initializeIfNeeded()
        return cache[key.dbKey]?.toLong()
    }
    internal fun getLong(key: SettingType, default: Long): Long = getLong(key) ?: default

    internal fun getDouble(key: SettingType): Double? = lock.use {
        initializeIfNeeded()
        return cache[key.dbKey]?.toDouble()
    }
    internal fun getDouble(key: SettingType, default: Double): Double = getDouble(key) ?: default

    internal fun setString(key: SettingType, value: String) {
        var previousValue: String? = null

        lock.use {
            val dbSetting = userSettingDao.findByKey(key.dbKey).oneOrNull()
            if (dbSetting != null) {
                if (dbSetting.value_ == value) {
                    return
                }
                previousValue = dbSetting.value_
                userSettingDao.update(
                    value_ = value,
                    updatedAt = now(),
                    synchronized = !key.serverSideSetting,
                    id = dbSetting.id,
                )
            } else {
                val settingToSave = DbUserSetting(
                    id = UserSettingId(0),
                    apiId = dbSetting?.apiId,
                    key = key.dbKey,
                    value_ = value,

                    // If it's a server side setting, we need to say it's unsynchronized so that we
                    // sync up the new value to the API later. When something is synced down from
                    // the API, this function is not used. It is saved directly to the DB and the cache
                    // is updated via "updateCacheValue". So this is fine to do.
                    synchronized = !key.serverSideSetting,
                    updatedAt = now(),
                )
                userSettingDao.insert(settingToSave)
            }

            if (cacheInitialized) {
                cache[key.dbKey] = value
            }
        }

        // Logging can take a bit of time because we write to the DB. Do it outside the lock.
        if (previousValue != value) {
            logInfo("Setting ${key.name} was updated from '$previousValue' to '$value'")

            Database.forceSave()
        }
    }

    internal fun deleteKey(key: SettingType) {
        userSettingDao.deleteByKey(key.dbKey)
        removeCacheValue(key)
    }

    internal fun updateCacheValue(key: SettingType, value: String) = lock.use {
        if (cacheInitialized) {
            cache[key.dbKey] = value
        }
    }
    internal fun removeCacheValue(key: SettingType) = lock.use {
        if (cacheInitialized) {
            cache.remove(key.dbKey)
        }
    }

    internal fun setBoolean(key: SettingType, value: Boolean) = setString(key, value.toString())
    internal fun setInt(key: SettingType, value: Int) = setString(key, value.toString())
    internal fun setLong(key: SettingType, value: Long) = setString(key, value.toString())
    internal fun setDouble(key: SettingType, value: Double) = setString(key, value.toString())
}

@Suppress("KotlinRedundantDiagnosticSuppress")
internal enum class SettingType(internal val dbKey: String, val serverSideSetting: Boolean = false) {
    DATE_FORMAT("date_format", serverSideSetting = true),
    TRACK_NAME_DISPLAY_FORMAT("track_name_display_format", serverSideSetting = true),
    TRACK_ARTIST_DISPLAY_FORMAT("track_artist_display_format", serverSideSetting = true),
    TRACK_DISPLAY_FORMAT("track_display_format", serverSideSetting = true),
    TRACK_DISPLAY_FEATURING_DELIMITER("track_display_featuring_delimiter", serverSideSetting = true),

    RESTART_TRACK_ON_PLAY_PREVIOUS("restart_track_on_play_previous", serverSideSetting = true),

    PRIVATE_LISTENING("private_listening_enabled", serverSideSetting = true),

    OFFLINE_STORAGE_ENABLED("offline_storage_enabled"),
    OFFLINE_STORAGE_MODE("offline_storage_mode"),
    OFFLINE_MODE_ENABLED("offline_mode_enabled"),
    AUTOMATIC_OFFLINE_MODE("automatic_offline_mode"),
    MAXIMUM_OFFLINE_STORAGE_BYTES("maximum_offline_storage_bytes"),

    AUTOMATIC_ERROR_REPORTING("automatic_error_reporting"),
    SHOW_CRITICAL_ERRORS("show_critical_errors"),

    OVERRIDDEN_HOST("overridden_host"),
    OVERRIDDEN_HOST_USE_HTTPS("overridden_host_use_https"),

    LOCATION_GATHERING_ENABLED("location_gathering_enabled"),
    LOCATION_GATHERING_BATTERY_PERCENT("location_gathering_battery_percent"),

    LAUNCH_OPTIONS("launch_options"),
    LAST_LIBRARY_VIEW("last_library_view"),
    LAST_SORT("last_sort"),
    TRACK_COLUMN_OPTIONS("track_column_options"),
    DEFAULT_TRACK_TAP_BEHAVIOR("default_track_tap_behavior"),
    USERS_COLLAPSED("users_collapsed"),
    PLAYLISTS_COLLAPSED("playlists_collapsed"),

    USE_TIME_SKIP_CONTROLS("use_time_skip_controls"),
    SKIP_FORWARD_TRACK_DURATION("skip_forward_track_duration"),
    SKIP_FORWARD_AMOUNT("skip_forward_amount"),
    SKIP_BACKWARD_AMOUNT("skip_backward_amount"),

    THEME_MODE("theme_mode"),

    VOLUME("volume"),
    REPEAT("repeat"),
    SHUFFLE("shuffle"),
    MUTED("muted"),

    SHUFFLE_LEAN("shuffle_lean"),
    SHUFFLE_MINIMUM_PLAY_COUNT("shuffle_minimum_play_count"),
    SHUFFLE_MAXIMUM_PLAY_COUNT("shuffle_maximum_play_count"),

    HAS_FIRST_SYNCED("has_first_synced"),

    TEXT_SPEECH_VOICE_IDENTIFIER("text_speech_voice_identifier"),
    TEXT_SPEECH_VOICE_ENABLED("text_speech_voice_enabled"),
    ;

    @Suppress("NOTHING_TO_INLINE")
    companion object {
        // A lot of the time I will cache these so that it isn't an 'N' lookup every time we use this.
        // But this is only really called when a setting is synced, and that is rare. I don't think it's
        // worth the memory usage to cache the lookups even if it's pretty small.
        internal inline fun findByDbKey(key: String) = entries.find { it.dbKey == key }
    }
}
