package components

import PageRouter
import ViewMode
import components.contextmenu.TrackContextMenu
import hide
import isHidden
import kotlinx.browser.document
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock.System.now
import kotlinx.html.*
import kotlinx.html.dom.append
import kotlinx.html.js.onClickFunction
import mainScope
import net.gorillagroove.api.UserId
import net.gorillagroove.authentication.AuthService
import net.gorillagroove.playlist.PlaylistService
import net.gorillagroove.review.ReviewQueueService
import net.gorillagroove.track.NowListeningService
import net.gorillagroove.track.TrackService
import net.gorillagroove.user.UserService
import net.gorillagroove.user.permission.PermissionService
import net.gorillagroove.user.permission.UserPermissionType
import net.gorillagroove.util.Formatter
import net.gorillagroove.util.GGLog
import net.gorillagroove.util.GGLog.logInfo
import net.gorillagroove.util.Settings
import onClickSuspend
import org.w3c.dom.*
import org.w3c.dom.events.MouseEvent
import queryId
import setHidden
import show
import kotlin.reflect.KMutableProperty0


@Suppress("FunctionName")
fun DIV.LeftNav() {
    fun attemptLogout() {
        GGLog.logInfo("Attempting logout")

        try {
            AuthService.logout()
        } finally {
            PageRouter.navigateTo("/login")
        }
    }

    div {
        id = "left-nav"

        h1 { + "Gorilla Groove" }
        div("p-relative") {
            h2 { +"Ultimate" }

            button(classes = "text-button") {
                id = "logout-button"

                +"Log out"

                onClickSuspend = {
                    attemptLogout()
                }
            }
        }

        ul {
            id = "left-nav-navigation-items"

            li(classes = "navigable-item") {
                onClickFunction = {
                    PageRouter.setViewMode(ViewMode.MY_LIBRARY)
                }

                a {
                    id = "my-library-nav-item"

                    +"My Library"
                }
            }
            li(classes = "navigable-item") {
                onClickFunction = {
                    PageRouter.setViewMode(ViewMode.NOW_PLAYING)
                }

                a {
                    id = "now-playing-nav-item"
                    +"Now Playing"
                }
            }

            li(classes = "navigable-item") {
                onClickFunction = {
                    PageRouter.setViewMode(ViewMode.REVIEW_QUEUE)
                }

                a {
                    id = "review-queue-nav-item"
                }
            }

            li(classes = "navigable-item") {
                onClickFunction = {
                    PageRouter.setViewMode(ViewMode.SPOTIFY_SEARCH)
                }

                a {
                    id = "spotify-search-nav-item"
                    + "Spotify Search"
                }
            }
        }

        h4("section-header") {
            span {
                val caretClass = if (Settings.usersCollapsed) "fa-caret-right" else "fa-caret-down"
                i("fa-solid $caretClass nav-caret") {
                    id = "users-nav-caret"
                }
                +"Users"
            }

            onClickFunction = {
                toggleSectionVisibility("users-nav-list", "users-nav-caret", Settings::usersCollapsed)
            }

            button(classes = "icon slim") {
                i("fa-solid fa-plus")

                onClickFunction = { event ->
                    event.stopPropagation()
                    Dialog.show(UserInviteModal())
                }
            }
        }

        ul(classes = "section-list ${if (Settings.usersCollapsed) "d-none" else ""}") {
            id = "users-nav-list"

            val now = now()
            UserService.findOtherUsers()
                .filter { it.hasRecentActivity(now) }
                .forEach { user ->
                    li {
                        attributes["userId"] = user.id.value.toString()

                        div(classes = "navigable-item") {
                            a {
                                id = "user-row-${user.id.value}"

                                +user.name

                                onClickFunction = {
                                    PageRouter.setViewMode(ViewMode.USERS, mapOf("USER_ID" to user.id.value))
                                }
                            }

                            i("user-music d-none fa-solid fa-music") {
                                attributes["tooltip-delay"] = "300"

                                onClickSuspend = { handleMusicNoteClick(it as MouseEvent, user.id) }
                            }
                        }
                    }
                }
        }

        h4("section-header") {
            span {
                val caretClass = if (Settings.playlistsCollapsed) "fa-caret-right" else "fa-caret-down"
                i("fa-solid $caretClass nav-caret") {
                    id = "playlist-nav-caret"
                }
                +"Playlists"
            }

            onClickFunction = {
                toggleSectionVisibility("playlist-list", "playlist-nav-caret", Settings::playlistsCollapsed)
            }

            button(classes = "icon slim") {
                i("fa-solid fa-plus")

                onClickFunction = { event ->
                    event.stopPropagation()
                    Dialog.show(AddPlaylist())
                }
            }
        }

        ul(classes = "section-list ${if (Settings.playlistsCollapsed) "d-none" else ""}") {
            id = "playlist-list"
        }

        ul {
            li(classes = "navigable-item") {
                onClickFunction = {
                    PageRouter.setViewMode(ViewMode.TRACK_HISTORY)
                }

                a {
                    id = "history-nav-item"
                    +"History"
                }
            }

            li(classes = "navigable-item") {
                onClickFunction = {
                    PageRouter.setViewMode(ViewMode.DEVICE_MANAGEMENT)
                }

                a {
                    id = "device-nav-item"
                    +"Device Management"
                }
            }

            if (PermissionService.hasAnyPermission(UserPermissionType.EDIT_YT_DL_SERVERS, UserPermissionType.ADMIN_YT_DL_SERVERS)) {
                li(classes = "navigable-item") {
                    onClickFunction = {
                        PageRouter.setViewMode(ViewMode.DOWNLOAD_SERVER)
                    }

                    a {
                        id = "download-server-nav-item"
                        +"Download Servers"
                    }
                }
            }

            li(classes = "navigable-item") {
                onClickFunction = {
                    PageRouter.setViewMode(ViewMode.SETTINGS)
                }

                a {
                    id = "settings-nav-item"
                    +"Settings"
                }
            }
        }
    }

    mainScope.launch {
        LeftNav.updateViewMode()
        LeftNav.onFirstRender()
    }
}

private fun toggleSectionVisibility(sectionId: String, caretId: String, setting: KMutableProperty0<Boolean>) {
    val navList = document.queryId<HTMLElement>(sectionId)
    val sectionCaret = document.queryId<HTMLElement>(caretId)

    val isListHidden = !navList.isHidden()

    navList.setHidden(isListHidden)

    if (isListHidden) {
        sectionCaret.classList.remove("fa-caret-down")
        sectionCaret.classList.add("fa-caret-right")
    } else {
        sectionCaret.classList.remove("fa-caret-right")
        sectionCaret.classList.add("fa-caret-down")
    }

    setting.set(isListHidden)
}

private fun getReviewQueueSuffix(): String {
    val count = ReviewQueueService.startOrGetSession().reviewSourceToTrackCount.values.sum()
    return if (count == 0) "" else " ($count)"
}

object LeftNav {
    private var previousHighlightedElement: Element? = null

    init {
        ReviewQueueService.registerEventHandler {
            updateReviewCount()
        }
    }

    fun updateViewMode() {
        previousHighlightedElement?.classList?.remove("current")

        val newElement = getElementForViewMode(PageRouter.currentViewMode)
        newElement?.classList?.add("current")

        previousHighlightedElement = newElement
    }

    fun onFirstRender() {
        updateReviewCount()
        updatePlaylists()

        document.querySelectorAll("#left-nav .user-music").asList().forEach {
            Tooltip.registerElement(it as HTMLElement)
        }
    }

    private fun getElementForViewMode(mode: ViewMode): Element? {
        val id = when (mode) {
            ViewMode.MY_LIBRARY -> "my-library-nav-item"
            ViewMode.NOW_PLAYING -> "now-playing-nav-item"
            ViewMode.REVIEW_QUEUE -> "review-queue-nav-item"
            ViewMode.SPOTIFY_SEARCH -> "spotify-search-nav-item"
            ViewMode.USERS -> {
                val userId = PageRouter.getQueryParam("USER_ID")?.toLong()
                "user-row-${userId}"
            }
            ViewMode.PLAYLISTS -> {
                val playlistId = PageRouter.getQueryParam("PLAYLIST_ID")?.toLong()
                "playlist-row-${playlistId}"
            }
            ViewMode.SETTINGS -> "settings-nav-item"
            ViewMode.TRACK_HISTORY -> "history-nav-item"
            ViewMode.DEVICE_MANAGEMENT -> "device-nav-item"
            ViewMode.DOWNLOAD_SERVER -> "download-server-nav-item"
        }

        return document.getElementById(id)
    }

    fun updateUserListenState() {
        document.querySelectorAll("#users-nav-list li").asList().forEach { row ->
            val userRow = row as HTMLLIElement

            val musicIcon = userRow.querySelector(".user-music")!!

            val userId = UserId(userRow.getAttribute("userId")!!.toLong())

            val playingDevice = NowListeningService.getListeningStateForUser(userId)

            val trackData = playingDevice?.trackData
            if (trackData == null) {
                musicIcon.hide()
            } else {
                val trackDisplay = if (trackData.isPrivate) {
                    "This track is private"
                } else {
                    Formatter.getPlayingTrackDisplayString(
                        name = trackData.name ?: "",
                        artist = trackData.artist ?: "",
                        featuring = trackData.featuring ?: ""
                    )
                }

                val deviceDisplay = "Device: ${playingDevice.deviceName}"

                val inReview = if (playingDevice.trackData?.inReview == true) "In review" else null

                if (playingDevice.deviceType.asEnumeratedType().isMobile) {
                    musicIcon.classList.remove("fa-music")
                    musicIcon.classList.add("fa-mobile")
                } else {
                    musicIcon.classList.remove("fa-mobile")
                    musicIcon.classList.add("fa-music")
                }

                val tooltip = listOfNotNull(trackDisplay, deviceDisplay, inReview).joinToString("\n")
                (musicIcon as HTMLElement).tooltip = tooltip
                musicIcon.show()
            }
        }
    }

    fun updateReviewCount() {
        val element = document.getElementById("review-queue-nav-item") as HTMLElement?
        element?.innerText = ("In Review" + getReviewQueueSuffix())
    }

    fun updatePlaylists() {
        val playlistEl = document.getElementById("playlist-list") as HTMLUListElement? ?: return

        playlistEl.innerHTML = ""

        PlaylistService.findAll().forEach { playlist ->
            playlistEl.append {
                li {
                    div(classes = "navigable-item") {
                        onClickFunction = {
                            PageRouter.setViewMode(ViewMode.PLAYLISTS, mapOf("PLAYLIST_ID" to playlist.id.value))
                        }

                        a {
                            id = "playlist-row-${playlist.id.value}"

                            +playlist.name
                        }

                        button(classes = "icon slim") {
                            i("fa-solid fa-pen-to-square")

                            onClickFunction = { event ->
                                event.stopPropagation()

                                Dialog.show(EditPlaylist(playlist))
                            }
                        }
                    }
                }
            }
        }

        if (PageRouter.currentViewMode == ViewMode.PLAYLISTS) {
            updateViewMode()
        }
    }
}

private suspend fun handleMusicNoteClick(e: MouseEvent, userId: UserId) {
    e.stopPropagation()

    val playingTrackId = NowListeningService.getListeningStateForUser(userId)?.trackData?.id ?: return

    val track = TrackService.findByApiId(playingTrackId) ?: return

    TrackContextMenu.open(e, fromLeftNav = true, trackOverride = listOf(track))
}
