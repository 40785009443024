@file:Suppress("FunctionName")

package components

import Dialog
import kotlinx.browser.document
import kotlinx.coroutines.launch
import kotlinx.html.*
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onClickFunction
import kotlinx.html.js.onSubmitFunction
import mainScope
import net.gorillagroove.discovery.BonusFileDownloads
import net.gorillagroove.localstorage.SiteCookies
import net.gorillagroove.reporting.ProblemReportService
import net.gorillagroove.sync.OfflineAvailabilityType
import net.gorillagroove.sync.SyncCoordinator
import net.gorillagroove.track.*
import net.gorillagroove.user.permission.PermissionService
import net.gorillagroove.user.permission.UserPermissionType
import net.gorillagroove.util.Formatter
import net.gorillagroove.util.Formatter.toReadableByteString
import net.gorillagroove.util.GGLog
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.Theme
import net.gorillagroove.util.ThemeMode
import onCheckedFunction
import onClickSuspend
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLSelectElement
import queryId
import util.ByteUtil.download

fun TagConsumer<*>.SettingsPage() = div {
    id = "settings-screen"

    Section("General") {
        div("horizontal-entry") {
            input(InputType.checkBox) {
                checked = OfflineModeService.offlineModeEnabled

                onChangeFunction = { event ->
                    OfflineModeService.offlineModeEnabled = (event.currentTarget as HTMLInputElement).checked
                }
            }

            + "Offline Mode"
        }

        div("horizontal-entry") {
            + "Theme"

            span("ml-6") {
                select {
                    ThemeOption(ThemeMode.SYSTEM)
                    ThemeOption(ThemeMode.LIGHT)
                    ThemeOption(ThemeMode.DARK)
                    ThemeOption(ThemeMode.CLASSIC)
                    // ThemeOption(ThemeMode.CUSTOM)

                    onChangeFunction = { event ->
                        val select = event.currentTarget as HTMLSelectElement
                        val themeMode = ThemeMode.valueOf(select.value)

                        Theme.current = themeMode
                        AppColor.assignColors()

                        SiteCookies.addCookie("Theme", themeMode.name)
                    }
                }
            }
        }
    }

    Section("Offline Storage") {
        div("space-between") {
            id = "offline-storage-divider"

            div {
                div("horizontal-entry") {
                    input(InputType.checkBox) {
                        checked = OfflineModeService.offlineStorageEnabled

                        onChangeFunction = { event ->
                            val checked = (event.currentTarget as HTMLInputElement).checked
                            OfflineModeService.offlineStorageEnabled = checked

                            if (checked) {
                                OfflineModeService.downloadAlwaysOfflineTracks()
                            } else {
                                // Unchecking this will delete data in the SDK. Refresh the UI to reflect that
                                SettingsPage.refreshCachedTrackData()
                            }
                        }
                    }

                    +"Enabled"
                }

                div("horizontal-entry") {
                    // This is a little different than on mobile. Mobile gets a "WiFi only" option, but that doesn't really
                    // make sense here as how many laptops have LTE... and how reliably could I even detect that you're on
                    // LTE vs WiFi from inside of a browser? So just don't use the WiFi setting at all.
                    // I am still checking the WiFi setting because I didn't used to default this to ALWAYS for web clients.
                    input(InputType.checkBox) {
                        checked = OfflineModeService.offlineStorageMode == OfflineStorageMode.ALWAYS
                                || OfflineModeService.offlineStorageMode == OfflineStorageMode.WIFI

                        onCheckedFunction = { checked ->
                            OfflineModeService.offlineStorageMode =
                                if (checked) OfflineStorageMode.ALWAYS else OfflineStorageMode.NEVER

                            if (checked) {
                                OfflineModeService.downloadAlwaysOfflineTracks()
                            }
                        }
                    }

                    +"Download always offline music"
                }

                div("horizontal-entry") {
                    div {
                        +"Maximum offline storage (in GB)"
                    }
                    div {
                        form {
                            onSubmitFunction = { event ->
                                event.preventDefault()

                                val amount = document.queryId<HTMLInputElement>("offline-storage-amount").value.toDouble()
                                OfflineModeService.allowedStorageSetting = (amount * 1_000_000_000L).toLong()
                            }

                            input(InputType.number) {
                                id = "offline-storage-amount"
                                value = (OfflineModeService.allowedStorageSetting.toDouble() / 1_000_000_000).toString()
                                step = "0.01"
                            }

                            button(classes = "flat slim ml-8", type = ButtonType.submit) {
                                +"Save"
                            }
                        }
                    }
                }
            }

            // These are set async by SettingsPage.refreshCachedTrackData()
            div {
                div("horizontal-entry") {
                    span { id = "offline-storage-used" }
                    +"Offline storage used"
                }

                div("horizontal-entry") {
                    span { id = "always-offline-tracks-cached" }
                    +"Always offline tracks cached"
                }

                div("horizontal-entry") {
                    span { id = "tracks-temporarily-cached" }
                    +"Tracks temporarily cached"

                    i("fa-solid fa-circle-info") {
                        tooltip = "This is not currently enabled, but will be at a future date"
                        tooltipDelay = 100
                    }
                }
            }
        }
    }

    Section("Date Formatting") {
        div {
            form {
                onSubmitFunction = { event ->
                    event.preventDefault()
                    Formatter.userDateFormat = document.queryId<HTMLInputElement>("user-date-format-input").value
                }

                div {
                    input(InputType.text) {
                        id = "user-date-format-input"
                        value = Formatter.userDateFormat
                    }

                    i("fa-solid fa-circle-info") {
                        tooltip = "Valid date options are 'D', 'DD', 'M', 'MM', 'YY', and 'YYYY"
                        tooltipDelay = 100
                    }
                }
                button(classes = "flat slim mt-6", type = ButtonType.submit) {
                    + "Save"
                }
            }
        }
    }

    Section("Track Formatting") {
        id = "track-formatting"

        div("mb-12") {
            id = "track-format-preview"
            + Formatter.generateExampleDisplayString()
        }

        form {
            onSubmitFunction = { event ->
                event.preventDefault()

                Formatter.featuringDelimiterFormat = document.queryId<HTMLInputElement>("track-featuring-separator").value
                Formatter.trackArtistDisplayFormat = document.queryId<HTMLInputElement>("track-artist-format").value
                Formatter.trackNameDisplayFormat = document.queryId<HTMLInputElement>("track-name-format").value
                Formatter.trackDisplayFormat = document.queryId<HTMLInputElement>("track-overall-format").value

                document.queryId<HTMLElement>("track-format-preview").innerText = Formatter.generateExampleDisplayString()
            }

            div("horizontal-entry") {
                span { +"Overall format" }

                input(InputType.text) {
                    id = "track-overall-format"
                    value = Formatter.trackDisplayFormat
                }
            }

            div("horizontal-entry") {
                span { +"Name format" }

                input(InputType.text) {
                    id = "track-name-format"
                    value = Formatter.trackNameDisplayFormat
                }
            }

            div("horizontal-entry") {
                span { +"Artist format" }

                input(InputType.text) {
                    id = "track-artist-format"
                    value = Formatter.trackArtistDisplayFormat
                }
            }

            div("horizontal-entry") {
                span { +"Featuring separator" }

                input(InputType.text) {
                    id = "track-featuring-separator"
                    value = Formatter.featuringDelimiterFormat
                }
            }

            div {
                button(classes = "flat slim", type = ButtonType.submit) {
                    +"Save"
                }

                // I didn't put this code in the library because I really don't think it should stick around. I'd rather this
                // worked like the Android app does where it gives you dropdowns of reasonable things to pick from. This basically
                // negates the need to even have a "reset" option. But I'm too lazy to do the dropdowns atm.
                button(classes = "flat slim ml-12", type = ButtonType.button) {
                    +"Reset"

                    onClickFunction = {
                        Formatter.featuringDelimiterFormat = "ft."
                        Formatter.trackArtistDisplayFormat = "{artist} {feat} {featuring}"
                        Formatter.trackNameDisplayFormat = "{name}"
                        Formatter.trackDisplayFormat = "{name} - {artist}"

                        document.queryId<HTMLInputElement>("track-featuring-separator").value = Formatter.featuringDelimiterFormat
                        document.queryId<HTMLInputElement>("track-artist-format").value = Formatter.trackArtistDisplayFormat
                        document.queryId<HTMLInputElement>("track-name-format").value = Formatter.trackNameDisplayFormat
                        document.queryId<HTMLInputElement>("track-overall-format").value = Formatter.trackDisplayFormat

                        document.queryId<HTMLElement>("track-format-preview").innerText = Formatter.generateExampleDisplayString()
                    }
                }
            }
        }
    }

    Section("Playback") {
        div {
            input(InputType.checkBox) {
                checked = NowPlayingService.restartOnPlayPrevious

                onCheckedFunction = { value ->
                    NowPlayingService.restartOnPlayPrevious = value
                }
            }

            + "Restart on play previous"

            i("fa-solid fa-circle-info") {
                tooltip = "When enabled, the 'play previous' action will restart the\ncurrent track if it has been playing for longer than 3 seconds"
                tooltipDelay = 100
            }
        }

        div {
            input(InputType.checkBox) {
                checked = NowListeningService.privateListeningEnabled

                onCheckedFunction = { value ->
                    NowListeningService.privateListeningEnabled = value
                }
            }

            + "Private listening mode"

            i("fa-solid fa-circle-info mt-6") {
                tooltip = "When enabled, you will not broadcast your music listening to other people, nor will you see what they are listening to"
                tooltipDelay = 100
            }
        }

        div {
            button(classes = "flat mt-6") {
                + "Shuffle Options"

                onClickFunction = {
                    Dialog.show(ShuffleLeanEdit())
                }
            }

            i("fa-solid fa-circle-info") {
                tooltip = "Shuffle options can be accessed at any time by right-clicking the shuffle icon \uD83D\uDD00 in the music controls"
                tooltipDelay = 100
            }
        }
    }

    Section("Syncing") {
        div {
            + "Last Sync: "
            span {
                id = "last-sync-time"
                + SyncCoordinator.getLastSyncString()
            }

            div {
                ActionButton(id = "last-sync-button", text = "Sync Now", classes = "slim mt-6", actionType = ActionButtonType.BENIGN) {
                    actionButtonChangeState("last-sync-button", isUpdating = true)

                    mainScope.launch {
                        val result = SyncCoordinator.sync()
                        if (result.wasSuccessful) {
                            document.getElementById("last-sync-time")?.textContent = SyncCoordinator.getLastSyncString()
                            Toast.success("Sync succeeded")
                        } else {
                            Toast.error("Sync failed")
                        }
                        actionButtonChangeState("last-sync-button", isUpdating = false)
                    }
                }
            }
        }
    }

    Section("Misc") {
        div {
            button(classes = "flat slim") {
                + "Reset track columns to default"

                onClickFunction = {
                    TrackSort.resetColumnOptions()
                }
            }
        }

        div {
            button(classes = "flat slim mt-6") {
                + "Download FireFox extension"

                onClickSuspend = onClick@{
                    val response = try {
                        BonusFileDownloads.downloadFireFoxExtension()
                    } catch (e: Throwable) {
                        Toast.error("Failed to download extension")
                        GGLog.logError("Failed to download FireFox extension!", e)
                        return@onClick
                    }

                    response.data.download(response.filename)
                }
            }

            i("fa-solid fa-circle-info") {
                tooltip = "The FireFox extension allows you to download music from YouTube without having to leave YouTube"
                tooltipDelay = 100
            }
        }
    }

    Section("Error Reporting") {
        div("horizontal-entry") {
            input(InputType.checkBox) {
                checked = ProblemReportService.automaticErrorReporting
            }

            + "Automatic Error Reporting"
        }

        div("horizontal-entry") {
            input(InputType.checkBox) {
                checked = ProblemReportService.showCriticalErrors
            }

            + "Show Critical Errors"
        }

        div {
            span {
                + ProblemReportService.getLastProblemReportMessage()
            }
            div {
                button(classes = "flat slim mt-6") {
                    +"Report a problem"

                    onClickSuspend = {
                        try {
                            ProblemReportService.sendProblemReport()

                            Toast.success("Problem report sent")
                        } catch (e: Exception) {
                            Toast.error("Failed to send problem report")
                            logError("Failed to send problem report!", e)
                        }
                    }
                }
            }
        }

        if (PermissionService.hasPermission(UserPermissionType.VIEW_CRASH_REPORTS)) {
            div {
                button(classes = "flat slim mt-6") {
                    +"View Crash Reports"

                    onClickFunction = {
                        Dialog.show(CrashReportModal())
                    }
                }
            }
        }
    }

    mainScope.launch {
        SettingsPage.init()
        Tooltip.registerAll(document.queryId("settings-screen"))
    }
}

private fun DIV.Section(name: String, body: DIV.() -> Unit) = div {
    h4("section-header") {
        + name
    }

    div("section-content") {
        body()
    }
}

private fun SELECT.ThemeOption(themeMode: ThemeMode) {
    option {
        value = themeMode.name
        selected = Theme.current == themeMode
        + themeMode.displayName
    }
}

internal object SettingsPage {
    private var handlerId: Int? = null

    fun init() {
        refreshCachedTrackData()

        if (handlerId != null) {
            return
        }

        handlerId = OfflineModeService.registerCacheEventHandler {
            refreshCachedTrackData()
        }
    }

    fun refreshCachedTrackData() {
        document.getElementById("offline-storage-used")?.textContent = OfflineModeService.getTotalCachedBytes().toReadableByteString()
        document.getElementById("always-offline-tracks-cached")?.textContent = "${TrackService.getTrackCount(offlineAvailabilityType = OfflineAvailabilityType.AVAILABLE_OFFLINE, isCached = true)} / ${TrackService.getTrackCount(offlineAvailabilityType = OfflineAvailabilityType.AVAILABLE_OFFLINE, isCached = null)}"
        document.getElementById("tracks-temporarily-cached")?.textContent = TrackService.getTrackCount(offlineAvailabilityType = OfflineAvailabilityType.NORMAL, isCached = true).toString()
    }
}
